import * as Sentry from '@sentry/react';
import moment from 'moment';
import _t from 'counterpart';
import React from 'react';
// @ts-ignore
import mjml2html from 'mjml-browser';

export function getPagesFromCount(count: any, limit: number) {
	const recordsCount = count ?? 0;
	const pages = count === 0 ? 0 : Math.ceil(recordsCount / Number(limit));
	return pages;
}

export function isCustomerNameEmpty(firstname: any, lastname: any) {
	return !firstname && !lastname;
}

export const getInitials = (input: string): string => {
	const names = input.split(' ');

	if (!names[0]) {
		return '';
	}

	let initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1]?.substring(0, 1).toUpperCase();
	}
	return initials;
};

export function truncate(text: string, max: number) {
	return text.substr(0, max - 1) + (text.length > max ? '&hellip;' : '');
}

export function getImageForInstrument(symbol: string)  {
	return `${process.env.REACT_APP_API_ROOT}/instrument_icons/${encodeURIComponent(symbol)}`
}

export function formatCurrency(amount: number | string, symbol = 'USD', maximumFractionDigits = 2) {
	try {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: symbol,
			currencyDisplay: 'symbol',
			maximumFractionDigits,
		});
		return formatter.format(Number(amount));
	} catch (e) {
		Sentry.captureException(e);
		return `${Number(amount).toFixed(2)} ${symbol}`;
	}
}

export function capitalize(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function firstToLowerCase(text: string) {
	return text.charAt(0).toLocaleLowerCase() + text.slice(1);
}

export function formatDate(date: Date): string {
	return new Intl.DateTimeFormat('en', {
		year: 'numeric',
		month: 'long',
		day: '2-digit',
	}).format(date);
}

export function formatDateTime(date: string | number | Date, formatString = 'lll'): string {
	return moment(date).format(formatString);
}

export function extractErrorMessage(error: any) {
	if (!error) {
		return _t('errors.unknown');
	}

	if (error.response?.data?.error) {
		if (error.response.data.error.message) {
			if (typeof error.response.data.error.message !== 'string') {
				return _t('errors.unknown');
			}
		}
		return error.response.data.error.message || error.response.data.error;
	}

	if (error.response?.data?.errors && Array.isArray(error.response.data.errors)) {
		return error.response.data.errors[0].msg;
	}

	return error.message;
}

export function extractValidationErrorMessage(error: any) {
	if (!error?.response?.data?.errors) {
		return null;
	}

	return error.response.data.errors;
}

export function errorWithCode(error: any) {
	if (!error) {
		return { message: null, code: 500 };
	}

	if (error.response?.data?.error) {
		const message = error.response.data.error.message || error.response.data.error;
		const code = error.response.status;
		return { message, code };
	}

	if (error.response?.status) {
		return { message: 'Unknown error', code: error.response.status };
	}

	return { message: 'Unknown error', code: 500 };
}

export function extractQueryParams(search: any) {
	const queryParams = new URLSearchParams(search);
	const entries = queryParams.entries();
	const params = { ...Object.fromEntries(entries) };
	return params;
}

export function findErrorFromValidation(validationErrors: any, paramName: string) {
	const errors = extractValidationErrorMessage(validationErrors);
	if (errors && errors.length > 0) {
		const error = errors.find((e: any) => e.param === paramName);
		if (error) {
			return error.msg;
		}
	}
	return null;
}

export function doesIconWithNameExist(iconName: string) {
	// CoreUI's weird sharing of icons
	// @ts-ignore
	return Boolean(React.icons[iconName]);
}

export function getIconNameForCountry(country: string) {
	const countryToLower = country.toLowerCase();
	const iconName = `cif${capitalize(countryToLower)}`;
	if (doesIconWithNameExist(iconName)) {
		return iconName;
	}
	return null;
}

const countryCodesForLanguages: { [key: string]: string } = {
	en: 'gb',
	ur: 'pk',
	zh: 'cn',
	ja: 'jp',
	hi: 'in',
	ar: 'ae',
	km: 'kh',
	vi: 'vn',
	ms: 'my',
};

export function getIconNameForLanguage(language: string) {
	const countryCode = capitalize(countryCodesForLanguages[language] ?? language);
	return getIconNameForCountry(countryCode);
}

export function parseOrderBy(column: string, asc: boolean) {
	return `${column}|${asc ? 'ASC' : 'DESC'}`;
}

export const isSet = <T>(input: T): input is NonNullable<T> => {
	return input !== null && input !== undefined;
};

export function getFiltersCount(selector: any) {
	const objectKeys = Object.keys(selector);
	let count = Object.values(selector).reduce<number>((count, filterValue, currentIndex) => {
		const filterKey = objectKeys[currentIndex];
		const notRange = filterKey === 'amountFrom' || filterKey === 'amountTo';
		if (
			((filterValue && !Array.isArray(filterValue)) || (Array.isArray(filterValue) && filterValue.length > 0)) &&
			!notRange
		) {
			return count + 1;
		}
		return count;
	}, 0);

	const { amountFrom, amountTo } = selector;
	if (amountFrom || amountTo) {
		count += 1;
	}
	return count;
}

export function convertMjmlToHtml(mjml: any) {
	try {
		const mjmlObject = mjml2html(mjml);
		const warnings = mjmlObject.errors.length > 0 ? mjmlObject.errors : null;
		return { error: false, warnings, html: mjml2html(mjml).html };
	} catch (e) {
		return { error: true };
	}
}

export function customStringAsNumberSorter(
	sortState: { asc: boolean; column: null },
	data: Array<any>,
	columnsToConvert: Array<any>
) {
	const sorted = [...data];
	const flip = sortState.asc ? 1 : -1;

	if (sortState.column === null) {
		return sorted;
	}
	if (columnsToConvert.includes(sortState.column!)) {
		return sorted.sort((a, b) => {
			const numberA = parseFloat(a[sortState.column!]);
			const numberB = parseFloat(b[sortState.column!]);
			return sortState.asc ? numberA - numberB : numberB - numberA;
		});
	}
	// CDataTable default sorter
	return sorted.sort((item, item2) => {
		const value = item[sortState.column!];
		const value2 = item2[sortState.column!];
		const a = typeof value === 'number' ? value : String(value).toLowerCase();
		const b = typeof value2 === 'number' ? value2 : String(value2).toLowerCase();
		// eslint-disable-next-line no-nested-ternary
		return a > b ? Number(flip) : b > a ? -1 * flip : 0;
	});
}

export function formatTimeHMS(seconds: number) {
	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.round(seconds % 60);
	const t = h ? `0${m}` : m || '0';
	return [h, m > 9 ? m : t, s > 9 ? s : `0${s}`].filter(Boolean).join(':');
}

export const getFilenameFromHeader = (dispositionHeader: string, defaultFilename: string) => {
	let filename = defaultFilename;
	if (dispositionHeader && dispositionHeader.startsWith('attachment')) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(dispositionHeader);
		if (matches !== null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
};

export const blobUrlToDataArray = async (file: any) => {
	return new Promise((resolve, reject) => {
		try {
			let blob;
			const xhr = new XMLHttpRequest();
			xhr.responseType = 'blob';

			xhr.onload = () => {
				const recoveredBlob = xhr.response;
				const reader = new FileReader();
				reader.addEventListener('loadend', (e) => {
					// @ts-ignore
					blob = new Uint8Array(e.target.result);
					resolve(blob);
				});
				reader.readAsArrayBuffer(recoveredBlob);
			};

			xhr.open('GET', file);
			xhr.send();
		} catch (e) {
			reject(e);
		}
	});
};

export const onlyPositiveInteger = (value: string): boolean => {
	const rx = new RegExp(/^(0|[1-9]\d*)?$/);
	return rx.test(value);
};
