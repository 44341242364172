import { fork } from 'redux-saga/effects';
import userSaga from './userSaga';
import notificationsSaga from './notificationsSaga';
import coreSaga from './coreSaga';

function* rootSaga() {
	yield fork(userSaga);
	yield fork(notificationsSaga);
	yield fork(coreSaga);
}

export default rootSaga;
