import { ICustomersFilters } from '../reducers/filtersReducer';

export function customerFiltersToDTO(filters: ICustomersFilters) {
	return {
		country: filters.country?.value,
		group: filters.group?.value,
		lastActivityStart: filters.lastActivityRange?.startDate?.toISOString(),
		lastActivityEnd: filters.lastActivityRange?.endDate?.toISOString(),
		registrationStart: filters.registrationRange?.startDate?.toISOString(),
		registrationEnd: filters.registrationRange?.endDate?.toISOString(),
		selectedStatuses: filters.selectedStatuses.map((status) => status.value),
		selectedKycStatuses: filters.selectedKycStatuses.map((status) => status.value),
		sources: filters.sources.filter((s) => s.enabled).map((s) => s.value),
		depositCount: filters.depositCount?.value,
		salesStatus: filters.salesStatus?.value,
		positions: filters.positions?.value,
		activity: filters.activity?.value,
		riskStatus: filters.riskStatus?.value,
	};
}
