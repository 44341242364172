import { ITransactionFilters } from '../reducers/filtersReducer';
import { stringify } from 'qs';

export const transactionFiltersToDTO = (filters: ITransactionFilters) => {
	return {
		createdAtRange: {
			startDate: filters.createdAtRange?.startDate?.toISOString(),
			endDate: filters.createdAtRange?.endDate?.toISOString(),
		},
		amountFrom: filters.amountFrom,
		amountTo: filters.amountTo,
		providers: filters.selectedProviders.map((status) => status.value),
		statuses: filters.selectedStatuses.map((status) => status.value),
	};
};

export const getExportLink = (
	search: string | null,
	transactionFilters: ITransactionFilters,
	token: string,
	type: 'DEPOSIT' | 'WITHDRAWAL'
) => {
	const filters = transactionFiltersToDTO(transactionFilters);
	const params: any = { filters, filter: search, token, type };

	return `${process.env.REACT_APP_API_ROOT}/api/admin/transactions/export/xlsx?${stringify(params)}`;
};
