import { useMutation, useQuery } from 'react-query';
import { showErrorToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import { getSetting, setSetting, updateRecaptchaSettings } from '../../services/BackendService';

export const useGetSetting = (key: string, queryKey: string, options?: any) => {
	return useQuery([queryKey], () => getSetting(key), options);
};

export const useSetSetting = () => {
	const dispatch = useAppDispatch();

	return useMutation(({ key, value }: { key: string; value: string | boolean | null }) => setSetting(key, value), {
		onError: (error) => {
			const msg = extractErrorMessage(error);
			dispatch(showErrorToast(msg));
		},
	});
};

interface IRecaptchaParams {
	siteKey: string;
	secret: string;
	score: string;
}

export const useSetRecatpchaV3 = () => {
	return useMutation(({ siteKey, secret, score }: IRecaptchaParams) => updateRecaptchaSettings(siteKey, secret, score));
};
