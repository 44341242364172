import React, { useState } from 'react';
import {
	CModal,
	CModalBody,
	CModalHeader,
	CModalTitle,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CModalFooter,
} from '@coreui/react';
import _t from 'counterpart';
import { useMutation } from 'react-query';
import { showErrorToast } from '../../actions';
import { IContent } from './types';
import { extractErrorMessage } from '../../helpers';
import FileUploader from '../../components/FileUploader';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { updateEducationContent, createEducationContent } from '../../services/BackendService';
import { useAppDispatch } from '../../helpers/customHooks';

interface IProps {
	content: IContent | null;
	show: boolean;
	categoryId: number;
	onClose: (refetch: boolean) => void;
}

interface IState {
	contentTitle: string;
	contentType: 'radioLink' | 'radioPDF';
	contentLink: string;
	currentEditContent: IContent | null;
	contentTitleError: string | null;
	contentLinkError: string | null;
	contentThumbnailError: string | null;
	contentFileError: string | null;
}

const CreateUpdateContent = ({ content, onClose, show, categoryId }: IProps) => {
	const [thumbnail, setThumbnail] = useState<any | null>(null);
	const [contentFile, setContentFile] = useState<any | null>(null);
	const [state, setState] = useState<IState>({
		contentTitle: content?.title || '',
		contentType: content?.contentLink ? 'radioLink' : 'radioPDF',
		contentLink: content?.contentLink || '',
		currentEditContent: content,
		contentTitleError: null,
		contentLinkError: null,
		contentThumbnailError: null,
		contentFileError: null,
	});

	const {
		contentTitle,
		contentThumbnailError,
		contentLink,
		contentTitleError,
		contentType,
		currentEditContent,
		contentFileError,
		contentLinkError,
	} = state;

	const getFileLink = (token: string | null) => {
		if (!token) {
			return null;
		}
		return `${process.env.REACT_APP_API_ROOT}/api/education/contentFile/${token}`;
	};

	const dispatch = useAppDispatch();

	const updateContentMutation = useMutation(
		['update-content'],
		() =>
			updateEducationContent(
				currentEditContent?.contentId || '',
				contentTitle,
				thumbnail,
				contentFile,
				contentLink || ''
			),
		{
			onSuccess: () => {
				onClose(true);
			},
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const createContentMutation = useMutation(
		['create-content'],
		() => createEducationContent(categoryId.toString(), contentTitle, thumbnail, contentFile, contentLink || ''),
		{
			onSuccess: () => {
				onClose(true);
			},
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');

		if (name) {
			setState({ ...state, [name]: target.value });
		}
	};

	const tryAddThumbnail = (files: any) => {
		setState({ ...state, contentThumbnailError: null });
		setThumbnail(files[0]);
	};

	const tryAddContent = (files: any) => {
		setState({ ...state, contentFileError: null });
		setContentFile(files[0]);
	};

	const handleThumbnailError = (contentThumbnailError: string) => {
		setState({ ...state, contentThumbnailError });
	};

	const handleContentError = (contentFileError: string) => {
		setState({ ...state, contentFileError });
	};

	const removeFile = () => {
		setThumbnail(null);
	};

	const resetErrors = () => {
		setState({
			...state,
			contentTitleError: null,
			contentThumbnailError: null,
			contentFileError: null,
		});
	};

	const handleRadioChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');

		if (name === 'radioLink') {
			setState({ ...state, contentType: 'radioLink' });
			setContentFile(null);
		}
		if (name === 'radioPdf') {
			setState({ ...state, contentType: 'radioPDF', contentLink: '' });
		}
	};

	const createOrUpdateContent = (e: React.FormEvent) => {
		e.preventDefault();
		const { currentEditContent, contentTitle, contentLink, contentType } = state;

		let error = false;
		if (!contentTitle) {
			setState({ ...state, contentTitleError: _t('education.error-empty-title') });
			error = true;
		}

		if (contentType === 'radioLink' && !contentLink?.startsWith('http')) {
			setState({ ...state, contentLinkError: _t('education.error-link-http') });
			error = true;
		}

		if (!thumbnail) {
			setState({ ...state, contentThumbnailError: _t('education.error-empty-thumbnail') });
			error = true;
		}

		if (!error) {
			if (currentEditContent) {
				updateContentMutation.mutate();
			} else {
				createContentMutation.mutate();
			}
		}
	};

	const close = () => {
		resetErrors();
		onClose(false);
	};

	const isMutatingContent = updateContentMutation.isLoading || createContentMutation.isLoading;
	const editThumbnailToken = getFileLink(currentEditContent ? currentEditContent.thumbnailToken : null);
	const editThumbnailName = currentEditContent ? currentEditContent.thumbnailName : null;
	const editContentLink = getFileLink(currentEditContent ? currentEditContent.contentFileToken : null);
	const editContentName = currentEditContent ? currentEditContent.contentFileName : null;

	return (
		<CModal show={show} onClose={close} closeOnBackdrop={false}>
			<CModalHeader closeButton>
				<CModalTitle>{currentEditContent ? _t('education.edit-content') : _t('education.add-content')}</CModalTitle>
			</CModalHeader>
			<CForm onSubmit={createOrUpdateContent}>
				<CModalBody>
					<CFormGroup>
						<CLabel htmlFor="contentTitle">{_t('global.title')}</CLabel>
						<CInput
							type="text"
							id="contentTitle"
							name="contentTitle"
							value={contentTitle}
							onChange={handleInputChange}
						/>
						{contentTitleError && <div className="text-danger">{contentTitleError}</div>}
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('education.add-thumbnail')}</CLabel>
						<FileUploader
							allowMultipleFiles={false}
							addFiles={tryAddThumbnail}
							removeFile={removeFile}
							maxFilesSizeInBytes={150000}
							validFileTypes={['png', 'jpg', 'jpeg', 'gif']}
							onError={handleThumbnailError}
							externalFile={editThumbnailToken}
							externalFileName={editThumbnailName}
							generateFileName
						/>
						{contentThumbnailError && <div className="text-danger">{contentThumbnailError}</div>}
					</CFormGroup>
					{!content && (
						<CFormGroup>
							<CLabel>{_t('education.content-type')}</CLabel>
							<div className="form-check">
								<input
									id="radio-link"
									onChange={handleRadioChange}
									className="form-check-input"
									type="radio"
									name="radioLink"
									checked={contentType === 'radioLink'}
								/>
								<CLabel htmlFor="radio-link">{_t('education.link')}</CLabel>
							</div>
							<div className="form-check">
								<input
									id="radio-pdf"
									onClick={handleRadioChange}
									className="form-check-input"
									type="radio"
									name="radioPdf"
									checked={contentType === 'radioPDF'}
								/>
								<CLabel htmlFor="radio-pdf">PDF</CLabel>
							</div>
						</CFormGroup>
					)}
					{contentType === 'radioLink' && (
						<CFormGroup>
							<CLabel htmlFor="contentLink">{_t('education.link')}</CLabel>
							<CInput
								type="text"
								id="contentLink"
								name="contentLink"
								value={contentLink}
								onChange={handleInputChange}
							/>
							{contentLinkError && <div className="text-danger">{contentLinkError}</div>}
						</CFormGroup>
					)}
					{contentType === 'radioPDF' && (
						<CFormGroup>
							<CLabel htmlFor="categoryName">{_t('education.add-ebook')}</CLabel>
							<FileUploader
								allowMultipleFiles={false}
								addFiles={tryAddContent}
								removeFile={() => setContentFile(null)}
								validFileTypes={['pdf']}
								onError={handleContentError}
								externalFile={editContentLink}
								externalFileName={editContentName}
								generateFileName
								maxFilesSizeInBytes={50000000}
							/>
							{contentFileError && <div className="text-danger">{contentFileError}</div>}
						</CFormGroup>
					)}
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={isMutatingContent}
						type="submit"
						buttonColor="primary"
						spinnerColor="secondary"
						title={currentEditContent ? _t.translate('action.update') : _t.translate('action.create')}
					/>
				</CModalFooter>
			</CForm>
		</CModal>
	);
};

export default CreateUpdateContent;
