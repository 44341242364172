import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useQuery, useMutation } from 'react-query';
import {
	CRow,
	CCol,
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CBadge,
	CDataTable,
	CLink,
	CCardTitle,
} from '@coreui/react';
import _t from 'counterpart';
import { loadEducationCategory, deleteEducationContent } from '../../services/BackendService';
import { formatDateTime, extractErrorMessage } from '../../helpers';
import { showErrorToast } from '../../actions';
import { IContent } from './types';
import Loading from '../../components/Loading';
import CIcon from '@coreui/icons-react';
import CreateUpdateContent from './CreateUpdateContent';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

const EducationPage = () => {
	const [editContent, setEditContent] = useState<IContent | null>(null);
	const [renderAddContent, setRenderAddContent] = useState<boolean>(false);

	const params = useParams();
	const dispatch = useDispatch();

	const { id } = params as any;

	const hideAddEducation = () => {
		setRenderAddContent(false);
	};

	const showAddEducation = () => {
		setRenderAddContent(true);
	};

	const educationCategoryQuery = useQuery(['education-category', id], () => loadEducationCategory(id), {
		onError: (error: any) => {
			dispatch(showErrorToast(extractErrorMessage(error)));
		},
	});

	const deleteContentMutation = useMutation(['delete-content'], (id: string) => deleteEducationContent(id), {
		onSuccess: () => {
			hideAddEducation();
			educationCategoryQuery.refetch();
		},
		onError: (error: any) => {
			dispatch(showErrorToast(extractErrorMessage(error)));
		},
	});

	const setCreateContentMode = () => {
		showAddEducation();
	};

	const setUpdateContentMode = (content: IContent) => {
		setEditContent(content);
		showAddEducation();
	};

	const deleteContent = (id: string) => {
		deleteContentMutation.mutate(id);
	};

	const onErrorRetry = () => {
		educationCategoryQuery.refetch();
	};

	if (educationCategoryQuery.isLoading || educationCategoryQuery.isIdle) {
		return <Loading />;
	}

	if (educationCategoryQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const onClose = (refetch: boolean) => {
		if (refetch) {
			educationCategoryQuery.refetch();
		}
		setEditContent(null);
		hideAddEducation();
	};

	const { title, token, createdAt, languages, contents, categoryId } = educationCategoryQuery.data;

	return (
		<PageLayout title={title}>
			<CRow>
				<CCol md={4}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('global.details')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol>
									<dl>
										<dt>{_t('education.thumbnail')}</dt>
										<dd>
											<img
												src={`${process.env.REACT_APP_API_ROOT}/api/education/file/${token}`}
												className="rounded"
												style={{ objectFit: 'contain', width: 30, height: 30 }}
												alt="Thumbnail"
											/>
										</dd>
										<dt>{_t('education.created-at')}</dt>
										<dd>{formatDateTime(createdAt)}</dd>
										<dt>{_t('education.languages')}</dt>
										<dd>
											{languages.map((lang: any) => (
												<CBadge key={`lang-${lang}`} color="info">
													{_t(`languages.${lang}`)}
												</CBadge>
											))}
											{languages && languages.length === 0 && <CBadge color="danger">{_t('global.all')}</CBadge>}
										</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CRow>
						<CCol className="c-main__table-heading">
							<CButton color="primary" onClick={setCreateContentMode} className="mt-2 float-right">
								{_t.translate('education.add-content')}
							</CButton>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<CCard className="c-main__table c_main__table--custom_action">
								<CCardBody className="simulated-overflow-y">
									<CDataTable
										sorter
										items={contents || []}
										fields={[
											{ key: 'thumbnail', label: _t('education.thumbnail') },
											{ key: 'title', label: _t('education.title') },
											{ key: 'createdAt', label: _t('education.created-at') },
											{ key: 'link', label: _t('education.link') },
											{ key: 'actions', label: _t('global.actions') },
										]}
										scopedSlots={{
											thumbnail: (content: IContent) => (
												<td>
													<img
														src={`${process.env.REACT_APP_API_ROOT}/api/education/contentFile/${content.thumbnailToken}`}
														className="rounded"
														style={{ objectFit: 'contain', width: 30, height: 30 }}
														alt="Thumbnail"
													/>
												</td>
											),
											title: (content: IContent) => <td className="text-nowrap">{content.title}</td>,
											createdAt: (content: IContent) => (
												<td className="text-nowrap">{formatDateTime(content.createdAt)}</td>
											),
											link: (content: IContent) => (
												<td>
													{content.contentLink && (
														<CLink href={content.contentLink} target="_blank">
															{content.contentLink}
														</CLink>
													)}
													{!content.contentLink && _t('education.no-content-link')}
												</td>
											),
											actions: (content: IContent) => (
												<td>
													<CButton
														className="mr-2"
														color="primary"
														type="button"
														onClick={() => setUpdateContentMode(content)}
														title="Edit content"
													>
														<CIcon name="cil-pencil" size="sm" />
													</CButton>
													<CButton
														className="mr-2"
														color="danger"
														type="button"
														onClick={() => deleteContent(content.contentId)}
														title="Delete content"
													>
														<CIcon name="cil-trash" size="sm" />
													</CButton>
												</td>
											),
										}}
									/>
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
			{renderAddContent && (
				<CreateUpdateContent content={editContent} onClose={onClose} show={renderAddContent} categoryId={categoryId} />
			)}
		</PageLayout>
	);
};

export default EducationPage;
