import React, { useCallback, useMemo } from 'react';
import { CButton, CDataTable, CModalBody, CModal, CModalHeader, CModalTitle } from '@coreui/react';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import { assignSalesStatusToCustomer, getSalesStatuses } from '../../../services/BackendService';
import { ISalesStatus } from '../../salesStatus/types';
import { useAppDispatch } from '../../../helpers/customHooks';
import { showSuccessToast, showErrorToast } from '../../../actions';
import { extractErrorMessage } from '../../../helpers';
import Error from '../../../components/Error';

interface IProps {
	show: boolean;
	onClose: (refetch?: boolean) => void;
	customerId: string;
}

interface IMutationsParams {
	customerId: string;
	salesStatusId: number;
}

const AssignSalesStatusModal = ({ show, onClose, customerId }: IProps) => {
	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses());

	const dispatch = useAppDispatch();

	const assignSalesStatusMutation = useMutation(
		['assign-sales-status'],
		({ customerId, salesStatusId }: IMutationsParams) => assignSalesStatusToCustomer(customerId, salesStatusId),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t.translate('sales-status.sales-status-assigned')));
				onClose(true);
			},
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				dispatch(showErrorToast(msg));
			},
		}
	);

	const fields = useMemo(
		() => [
			{ key: 'status', label: _t('global.status'), sorter: true, _style: { width: '90%' } },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[]
	);

	const invokeMutation = useCallback(
		(salesStatusId: number) => {
			assignSalesStatusMutation.mutate({
				customerId,
				salesStatusId,
			});
		},
		[assignSalesStatusMutation, customerId]
	);

	const slots = useMemo(
		() => ({
			actions: (status: ISalesStatus) => (
				<td>
					<CButton color="primary" size="sm" onClick={() => invokeMutation(status.id)}>
						{_t('action.set')}
					</CButton>
				</td>
			),
		}),
		[invokeMutation]
	);

	return (
		<CModal show={show} onClose={onClose} size="xl">
			<CModalHeader>
				<CModalTitle>{_t('sales-status.assign-sales-status')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				{salesStatusesQuery.isError && <Error onRetry={salesStatusesQuery.refetch} />}
				{!salesStatusesQuery.isError && (
					<CDataTable
						fields={fields}
						scopedSlots={slots}
						items={salesStatusesQuery.data || []}
						loading={salesStatusesQuery.isLoading}
					/>
				)}
			</CModalBody>
		</CModal>
	);
};

export default AssignSalesStatusModal;
