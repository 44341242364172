import React from 'react';
import _t from 'counterpart';
import PageLayout from '../../components/PageLayout';
import FieldConfiguration from './FieldConfiguration';
import StepConfiguration from './StepConfiguration';
import WalletConfiguration from './WalletConfiguration';

const OnboardingPage = () => {
	return (
		<PageLayout title={_t.translate('onboarding.title')}>
			<WalletConfiguration />
			<FieldConfiguration />
			<StepConfiguration />
		</PageLayout>
	);
};

export default OnboardingPage;
