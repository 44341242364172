import React, { useEffect, useState } from 'react';
import { CButton, CInput, CLabel, CModal, CModalBody, CModalTitle, CModalFooter, CModalHeader } from '@coreui/react';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useAppDispatch } from '../../helpers/customHooks';
import { useMutation } from 'react-query';
import { showSuccessToast, showErrorToast } from '../../actions';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { createSalesStatus, updateSalesStatus } from '../../services/BackendService';
import { ISalesStatus } from './types';
import { SliderPicker } from 'react-color';

interface IProps {
	show: boolean;
	onClose: (refetch?: boolean) => void;
	currentStatus: ISalesStatus | null;
}

const CreateEditStatusModal = ({ show, onClose, currentStatus }: IProps) => {
	const [status, setStatus] = useState<string>('');
	const defaultColor = '#bf40be';
	const [statusColor, setStatusColor] = useState<string>(defaultColor);

	useEffect(() => {
		if (currentStatus) {
			setStatus(currentStatus.status);
			if (currentStatus.color) {
				setStatusColor(currentStatus.color);
			}
		}
	}, [currentStatus]);

	const dispatch = useAppDispatch();
	const onSuccess = (successStatusMsg: string) => {
		dispatch(showSuccessToast(successStatusMsg));
		setStatus('');
		onClose(true);
	};

	const createStatusMutation = useMutation(
		['create-sales-status'],
		({ status, color }: { status: string; color: string }) => createSalesStatus(status, color),
		{
			onSuccess: () => onSuccess(_t('sales-status.created-successfully')),
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const updateStatusMutation = useMutation(
		['update-sales-status'],
		({ id, status, color }: { id: number; status: string; color: string }) => updateSalesStatus(id, status, color),
		{
			onSuccess: () => onSuccess(_t('sales-status.updated-successfully')),
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const handleColorChangeComplete = (color: any) => {
		setStatusColor(color.hex);
	};

	const closeHandler = () => {
		setStatus('');
		createStatusMutation.reset();
		updateStatusMutation.reset();
		onClose();
	};

	const createOrUpdateStatus = () => {
		if (currentStatus !== null) {
			updateStatusMutation.mutate({ id: currentStatus.id, status, color: statusColor });
		} else {
			createStatusMutation.mutate({ status, color: statusColor });
		}
	};

	const isLoading = createStatusMutation.isLoading;
	const errors = createStatusMutation.error || updateStatusMutation.error;
	const findError = findErrorFromValidation;

	return (
		<CModal show={show} onClose={closeHandler} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>{_t('global.edit-status')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CLabel htmlFor="status">{_t('global.status')}</CLabel>
				<CInput
					type="text"
					id="status"
					placeholder="status"
					value={status}
					onChange={(e: any) => setStatus(e.target.value)}
				/>
				<CLabel htmlFor="status_color">{_t('global.status-color')}</CLabel>
				<SliderPicker color={statusColor} onChangeComplete={handleColorChangeComplete} />
				{findError(errors, 'status') && <CLabel className="text-danger">{findError(errors, 'status')}</CLabel>}
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={isLoading}
					onClick={createOrUpdateStatus}
					buttonColor="primary"
					spinnerColor="secondary"
					title={currentStatus ? _t('action.update') : _t('action.save')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={closeHandler} disabled={isLoading}>
					{_t('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(CreateEditStatusModal);
