import React, { useState } from 'react';
import _t from 'counterpart';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { loadOrderQuery } from '../../services/BackendService';
import { extractErrorMessage, formatCurrency } from '../../helpers';
import { showErrorToast } from '../../actions';
import { useDispatch } from 'react-redux';
import { CRow, CCol, CCard, CCardBody, CLink, CButton, CBadge } from '@coreui/react';
import Loading from '../../components/Loading';
import OrderStatusBadge from './OrderStatusBadge';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import { Permission } from '../../reducers/userReducer';
import { useAppSelector } from '../../helpers/customHooks';
import { IOrder } from './types';
import OrderUpdateModal from './OrderUpdateModal';
import TradingSideBadge from '../../components/TradingSideBadge';

const OrderPage = () => {
	const [editOrderShow, setEditOrderShow] = useState<IOrder | null>(null);

	const { id } = useParams() as any;
	const dispatch = useDispatch();

	const permissions = useAppSelector((state) => state.user.permissions);

	const orderQuery = useQuery(['order', id], () => loadOrderQuery(id || ''), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
		retry: false,
	});

	const onErrorRetry = () => {
		orderQuery.refetch();
	};

	if (orderQuery.isLoading || orderQuery.isIdle) {
		return <Loading />;
	}

	if (orderQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const order = orderQuery.data;

	const showActions =
		['new', 'accepted', 'pending'].includes(orderQuery.data.status) && permissions.includes(Permission.MANAGE_ORDERS);

	const onEditClicked = () => {
		setEditOrderShow(orderQuery.data);
	};

	const onEditClosed = (refetch?: boolean) => {
		setEditOrderShow(null);
		if (refetch) {
			orderQuery.refetch();
		}
	};

	return (
		<PageLayout
			title={_t.translate('orders.order.title') + ` #${order.id}`}
			titleAppend={
				<div>
					{showActions && (
						<div className="d-flex justify-content-between float-right mb-2">
							<div className="d-flex flex-nowrap flex-row-reverse">
								<CButton color="primary" onClick={onEditClicked}>
									{_t('action.edit')}
								</CButton>
							</div>
						</div>
					)}
				</div>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('orders.position-id')}:</dt>
										<dd>
											<CLink href={`/positions/${order.positionId}`}>{order.positionId}</CLink>
										</dd>
									</dl>

									<dl>
										<dt>{_t('orders.placedAt')}:</dt>
										<dd>{order.placedAt}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.request-price')}:</dt>
										<dd>{order.requestPrice || '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.quantity')}:</dt>
										<dd>
											{order.quantity} / {order.filledQuantity}
										</dd>
									</dl>

									<dl>
										<dt>{_t('orders.notional-value')}:</dt>
										<dd>{order.notionalValue || '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('global.status')}:</dt>
										<dd>
											<OrderStatusBadge status={order.status} />
										</dd>
									</dl>

									<dl>
										<dt>{_t('orders.side')}:</dt>
										<dd>
											<TradingSideBadge side={order.side} />
										</dd>
									</dl>

									<dl>
										<dt>{_t('orders.stop-loss')}</dt>
										<dd>{order.stopLoss ? order.stopLoss : '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.take-profit')}</dt>
										<dd>{order.takeProfit ? order.takeProfit : '-'}</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('orders.external-id')}:</dt>
										<dd>{order.externalId}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.executed-at')}:</dt>
										<dd>{order.executedAt}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.average-price')}:</dt>
										<dd>{order.averagePrice || '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.notional-value-in-usd')}:</dt>
										<dd>{order.notionalValueInUSD ? formatCurrency(order.notionalValueInUSD) : '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.reject-reason')}:</dt>
										<dd>{order.rejectReason || '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('global.type')}:</dt>
										<dd>
											<CBadge color={order.type === 'market' ? 'success' : 'primary'}>{order.type}</CBadge>
										</dd>
									</dl>

									<dl>
										<dt>{_t('orders.stop-price')}</dt>
										<dd>{order.stopPrice ? order.stopPrice : '-'}</dd>
									</dl>

									<dl>
										<dt>{_t('orders.limit-price')}</dt>
										<dd>{order.limitPrice ? order.limitPrice : '-'}</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<OrderUpdateModal order={editOrderShow} onClose={onEditClosed} />
		</PageLayout>
	);
};

export default OrderPage;
