import { Action } from 'redux';
import { HIDE_ERROR_TOAST, SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST, HIDE_SUCCESS_TOAST } from '../actions';

export interface NotificationsState {
	errorToast: string | null;
	successToast: string | null;
}

const initialState: NotificationsState = {
	errorToast: null,
	successToast: null,
};

function notificationsReducer(state: NotificationsState | undefined, action: Action<string>): NotificationsState {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
		case SHOW_ERROR_TOAST:
			return {
				...state,
				// @ts-ignore
				errorToast: action.message,
				successToast: null,
			};

		case HIDE_ERROR_TOAST:
			return {
				...state,
				errorToast: null,
			};
		case SHOW_SUCCESS_TOAST:
			return {
				...state,
				// @ts-ignore
				successToast: action.message,
				errorToast: null,
			};
		case HIDE_SUCCESS_TOAST:
			return {
				...state,
				// @ts-ignore
				successToast: null,
			};
		default:
			return state;
	}
}

export default notificationsReducer;
