import React from 'react';
import { useMutation } from 'react-query';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { CSpinner, CButton } from '@coreui/react';
import { bookmarkCustomer, removeBookmark } from '../../../services/BackendService';
import { extractErrorMessage } from '../../../helpers';
import { showErrorToast } from '../../../actions';
import { useAppDispatch } from '../../../helpers/customHooks';

interface IProps {
	id: string;
	bookmarked: boolean;
	onChanged: (bookmarked: boolean) => void;
}

const BookmarkCustomer = ({ id, bookmarked, onChanged: onMutation }: IProps) => {
	const dispatch = useAppDispatch();

	const showError = (errorMessage: string) => {
		const error = extractErrorMessage(errorMessage);
		dispatch(showErrorToast(error));
	};

	const bookmarkCustomerMutation = useMutation((id: string) => bookmarkCustomer(id), {
		onSuccess: () => {
			onMutation(true);
		},
		onError: (e: any) => {
			showError(e);
		},
	});

	const removeBookmarkMutation = useMutation((id: string) => removeBookmark(id), {
		onSuccess: () => {
			onMutation(false);
		},
		onError: (e: any) => {
			showError(e);
		},
	});

	const addOrRemoveBookmark = () => {
		if (bookmarked) {
			removeBookmarkMutation.mutate(id);
		} else {
			bookmarkCustomerMutation.mutate(id);
		}
	};

	const bookmarkOperationLoading = bookmarkCustomerMutation.isLoading || removeBookmarkMutation.isLoading;

	return (
		<div>
			{bookmarkOperationLoading && <CSpinner color="primary" size="sm" className="ml-3" />}
			{!bookmarkOperationLoading && (
				<CButton
					onClick={addOrRemoveBookmark}
					className="p-0 ml-2 float-left"
					title={bookmarked ? _t('customer.remove-bookmark') : _t('customer.bookmark')}
				>
					<CIcon name={bookmarked ? 'cis-bookmark' : 'cil-bookmark'} size="xl" className="bookmark" />
				</CButton>
			)}
		</div>
	);
};

export default BookmarkCustomer;
