import React, { useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CCardHeader } from '@coreui/react';
import _t from 'counterpart';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import RiskReportsConfigurator from './RiskReportsConfigurator';
import ReportResultsProfitLoss from './ProfitLossResultsTable';
import ReportResultsBalance from './BalanceResultsTable';
import ReportCoinsBalanace from './ReportCoinsBalanace';
import AffiliationPerformance from './AffiliationPerformance';
import PageLayout from '../../components/PageLayout';

const RiskReportsPage = () => {
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [orderBy, setOrderBy] = useQueryParam('orderBy', StringParam);
	const [filters, setFilters] = useState<any>(null);
	const [fetchAmount, setFetchAmount] = useState<number>(0);

	const onSetFilters = (filtersValues: any) => {
		setPage(1, 'replaceIn');
		setOrderBy('accountId|DESC', 'replaceIn');
		setFilters(filtersValues);
	};

	const onProfitLossFetch = (amount: number) => {
		setFetchAmount(amount);
	};

	const type = filters?.type?.value || undefined;

	return (
		<PageLayout title={_t.translate('reports.title')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader>
							<div>
								<RiskReportsConfigurator onRequest={onSetFilters} amountOfRecords={fetchAmount} />
							</div>
						</CCardHeader>
						<CCardBody>
							{type === 'profitloss' && <ReportResultsProfitLoss filters={filters} onFetch={onProfitLossFetch} />}
							{type === 'balance' && <ReportResultsBalance filters={filters} onFetch={onProfitLossFetch} />}
							{type === 'coins' && <ReportCoinsBalanace filters={filters} onFetch={onProfitLossFetch} />}
							{type === 'affiliation' && <AffiliationPerformance filters={filters} onFetch={onProfitLossFetch} />}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default RiskReportsPage;
