import React, { useCallback, useMemo, useState } from 'react';
import _t from 'counterpart';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import { useQuery, useQueryClient } from 'react-query';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { loadPositions } from '../../services/BackendService';
import { IPosition } from './position';
import { useHistory } from 'react-router-dom';
import { formatCurrency, getFiltersCount, isSet } from '../../helpers';
import { useAppSelector } from '../../helpers/customHooks';
import PositionStatusBadge from '../../components/PositionStatusBadge';
import SearchFilter from '../../components/SearchFilter';
import { IOpenPositionsFilters, IPositionsFilters } from '../../reducers/filtersReducer';
import PositionsFilter from './PositionsFilter';
import TradingSideBadge from '../../components/TradingSideBadge';
import { stringify } from 'qs';
import ExportXLSXButton from '../../components/ExportXLSXButton';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

interface IProps {
	titleKey: string;
	filters: IPositionsFilters | IOpenPositionsFilters;
	clearFilters: () => void;
	filtersDTO: any;
	onFiltersUpdated: (filters: Partial<IPositionsFilters | IOpenPositionsFilters>) => void;
	showExportButton: boolean;
	disablePagination?: boolean;
	queryKey?: string;
}

const BasePositionsPage = ({
	titleKey,
	filters,
	clearFilters,
	filtersDTO,
	onFiltersUpdated,
	showExportButton,
	disablePagination = false,
	queryKey = 'positions',
}: IProps) => {
	const token = useAppSelector((state: any) => state.user.token);

	const [showFilters, setShowFilters] = useState<boolean>(false);

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'openedAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const history = useHistory();
	const queryClient = useQueryClient();

	const positionsItemsPerPageFromLocalStorage = Number(localStorage.getItem(`items_per_page_${queryKey}_table`) ?? 10);
	const [limit, setLimit] = useState<number>(positionsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const { data, isLoading, refetch, isError } = useQuery<any>([queryKey, filter, page, filtersDTO, orderBy], () =>
		loadPositions(
			disablePagination ? null : limit,
			disablePagination ? null : offset,
			filter || '',
			filtersDTO,
			orderBy!
		)
	);

	const onPageChanged = useCallback(
		(page: number) => {
			setPage(page, 'replaceIn');
		},
		[setPage]
	);

	const setTableFilterValue = (search: string) => {
		if (!disablePagination) {
			setPage(1, 'replaceIn');
		}
		setFilter(search, 'replaceIn');
	};

	const rowClicked = useCallback(
		(position: IPosition) => {
			const { id } = position;
			history.push(`/positions/${id}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[history]
	);

	const onFiltersChange = useCallback(
		(filters: Partial<IPositionsFilters | IOpenPositionsFilters>) => {
			onFiltersUpdated(filters);
			if (!disablePagination) {
				setPage(1, 'replaceIn');
			}
		},
		[onFiltersUpdated, setPage, disablePagination]
	);

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem(`items_per_page_${queryKey}_table`, limit.toString());
		await queryClient.invalidateQueries(`${queryKey}`);
		refetch();
	};

	const filtersCount = getFiltersCount(filters);

	const downloadLink = `${process.env.REACT_APP_API_ROOT}/api/admin/positions/export/xlsx?${stringify({
		filters: filtersDTO,
		token,
	})}`;

	const tableFields = useMemo(
		() => [
			{
				key: 'id',
				label: 'ID',
				sorter: true,
			},
			{
				key: 'side',
				label: _t('positions.type'),
				sorter: false,
			},
			{
				key: 'symbol',
				label: _t('positions.symbol'),
				sorter: false,
			},
			{
				key: 'accountId',
				label: _t('positions.account-id'),
				sorter: false,
			},
			{
				key: 'closedAt',
				label: _t('positions.closed-at'),
				sorter: true,
			},
			{
				key: 'openedAt',
				label: _t('positions.opened-at'),
				sorter: false,
			},
			{
				key: 'openingOrderExternalId',
				label: _t('positions.external-id'),
				sorter: false,
			},
			{
				key: 'lots',
				label: _t('positions.lots'),
				sorter: false,
			},
			{
				key: 'profit',
				label: _t('positions.profit'),
				sorter: false,
			},
			{
				key: 'swap',
				label: _t('positions.swap'),
				sorter: false,
			},
			{
				key: 'status',
				label: _t('positions.status'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			accountId: (position: IPosition) => <td>{position.accountId}</td>,
			side: ({ side }: IPosition) => (
				<td>
					<TradingSideBadge side={side} />
				</td>
			),
			status: (position: IPosition) => (
				<td>
					<PositionStatusBadge position={position} />
				</td>
			),
			profit: (position: IPosition) => {
				const { closedPLInAccountCurrency, profitLoss } = position;
				const pL = profitLoss ?? closedPLInAccountCurrency;
				return <td className="text-nowrap">{isSet(pL) ? formatCurrency(pL) : '-'}</td>;
			},
			swap: (position: IPosition) => <td>{formatCurrency(position?.swap === null ? 0 : position?.swap)}</td>,
			lots: (position: IPosition) => <td>{position.size}</td>,
			openedAt: (position: IPosition) => {
				return <td className="text-nowrap">{new Date(position.openedAt).toLocaleString()}</td>;
			},
			closedAt: (position: IPosition) => {
				return (
					<td className="text-nowrap">{position.closedAt ? new Date(position.closedAt).toLocaleString() : '-'}</td>
				);
			},
		};
	}, []);

	const onErrorRetry = () => {
		refetch();
	};

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate(titleKey)}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={setTableFilterValue} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={clearFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
										{showExportButton && (
											<div className="float-right">
												<ExportXLSXButton
													downloadLink={downloadLink}
													disabled={isLoading || !data || data.positions.length < 1}
													defaultFilename="positions.xlsx"
												/>
											</div>
										)}
									</div>
								</div>
								<PositionsFilter show={showFilters} onFiltersChange={onFiltersChange} filters={filters} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={data?.positions ?? []}
								loading={isLoading}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								pages={data?.pages || 0}
								pagination={!disablePagination}
								onRowClicked={rowClicked}
								clickableRows
								onPageChanged={onPageChanged}
								activePage={page || 1}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

BasePositionsPage.defaultProps = {
	disablePagination: null,
	queryKey: '',
};

export default React.memo(BasePositionsPage);
