export const oAuthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
export const oAuthClientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;
export const oAuthLoginUrl = process.env.REACT_APP_OAUTH_LOGIN_URL;

const buildOAuthLoginUrl = (language: string, state: string) => {
	if (!oAuthClientId || !oAuthClientSecret || !oAuthLoginUrl) {
		throw Error(
			'REACT_APP_OAUTH_CLIENT_ID, REACT_APP_OAUTH_CLIENT_SECRET and REACT_APP_OAUTH_LOGIN_URL ENV vars need to be defined for OAuth to work'
		);
	}
	const redirectUri = `${window.location.origin}/login`;

	return `${oAuthLoginUrl}?client_id=${encodeURIComponent(oAuthClientId)}&redirect_uri=${encodeURIComponent(
		redirectUri
	)}&response_type=code&state=${encodeURIComponent(state)}&scope=admin-portal&lang=${encodeURIComponent(language)}`;
};

const generateRandomString = () => {
	return Array(10)
		.fill('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz')
		.map((x) => {
			return x[Math.floor(Math.random() * x.length)];
		})
		.join('');
};

const setOAuthState = (state: string) => {
	window.sessionStorage.setItem('state', state);
};

export const getOAuthState = (): string | null => {
	return window.sessionStorage.getItem('state');
};

export const requestOAuthLogin = (language: string) => {
	const state = generateRandomString();
	setOAuthState(state);

	// @ts-ignore
	window.location = buildOAuthLoginUrl(language, state);
};
