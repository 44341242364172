import React from 'react';
import TradingSideBadge from './TradingSideBadge';
import { formatCurrency, isSet } from '../helpers';
import PositionStatusBadge from './PositionStatusBadge';
import { IPosition } from '../pages/positions/position';
import { useHistory } from 'react-router-dom';

interface Props {
	position: IPosition;
}

const OpenPositionRow: React.FC<Props> = ({ position }) => {
	const { closedPLInAccountCurrency, profitLoss } = position;
	const pL = profitLoss ?? closedPLInAccountCurrency;
	const history = useHistory();

	const handleClick = () => {
		history.push(`/positions/${position.id}`);
	};

	return (
		<tr key={position.id} onClick={handleClick}>
			<td>{position.id}</td>
			<td>
				<TradingSideBadge side={position.side} />
			</td>
			<td>{position.symbol}</td>
			<td>{position.accountId}</td>
			<td className="text-nowrap">{new Date(position.openedAt).toLocaleString()}</td>
			<td>{position.openingOrderExternalId || '-'}</td>
			<td>{position.size}</td>
			<td className="text-nowrap">{isSet(pL) ? formatCurrency(pL) : '-'}</td>
			<td>{formatCurrency(position?.swap === null ? 0 : position?.swap)}</td>
			<td>
				<PositionStatusBadge position={position} />
			</td>
		</tr>
	);
};

export default React.memo(OpenPositionRow);
