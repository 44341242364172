import React, { FormEvent, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import _t from 'counterpart';
import { GetAdapterFromUrl } from './hooks';
import { CCard, CCardBody, CCol, CForm, CFormGroup, CInput, CInputGroup, CLabel, CRow, CSwitch } from '@coreui/react';
import { AdapterType, defaultConfig, IAdapter } from './types';
import { useMutation, useQuery } from 'react-query';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { createAdapter, fetchAdapter, updateAdapter } from '../../services/BackendService';
import CustomSelect from '../../components/CustomSelect';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { showErrorToast, showSuccessToast } from '../../actions';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';

const CreateUpdateAdapter = () => {
	const [state, setState] = useState<IAdapter>({
		name: '',
		enabled: false,
		type: AdapterType.Fixquoter,
		configuration: defaultConfig,
	});

	const dispatch = useDispatch();
	const history = useHistory();

	const adapterFromUrl = GetAdapterFromUrl();
	const title = adapterFromUrl ? _t('adapters.update-adapter') : _t('adapters.create-adapter');

	const onError = (e: any) => {
		if (e.response?.status !== 422) {
			dispatch(showErrorToast(extractErrorMessage(e)));
		}
	};

	const adapterQuery = useQuery(['adapter', adapterFromUrl], () => fetchAdapter(adapterFromUrl!), {
		enabled: adapterFromUrl !== null,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (adapter: IAdapter) => {
			setState(adapter);
		},
	});

	const updateAdapterMutation = useMutation(
		({ name, enabled, type, configuration }: IAdapter) => updateAdapter(name, enabled, type, configuration),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('adapters.adapter-updated-successfully')));
				history.push('/adapters');
			},
			onError,
		}
	);

	const createAdapterMutation = useMutation(
		({ name, enabled, type, configuration }: IAdapter) => createAdapter(name, enabled, type, configuration),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('adapters.adapter-created-successfully')));
				history.push('/adapters');
			},
			onError,
		}
	);

	const onNameChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		setState({ ...state, name: target.value });
	};

	const toggleReverse = () => {
		setState({ ...state, enabled: !state.enabled });
	};

	const onSelectChange = ({ value }: any) => {
		setState({ ...state, type: value });
	};

	const toggleReverseSSL = () => {
		setState({ ...state, configuration: { ...state.configuration!, ssl: !state.configuration!.ssl } });
	};

	const toggleReverseResetOnLogon = () => {
		setState({
			...state,
			configuration: { ...state.configuration!, resetOnLogon: !state.configuration!.resetOnLogon },
		});
	};

	const setConfigFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const type: string = target.getAttribute('type')!;
		let value = target.value! as string | number;
		if (type === 'number') {
			value = Number(value);
		}

		setState({ ...state, configuration: { ...state.configuration!, [name]: value } });
	};

	const types = Object.values(AdapterType).map((a: string) => {
		return {
			value: a,
			label: a,
		};
	});

	const invokeMutation = (e: React.FormEvent) => {
		e.preventDefault();
		if (adapterFromUrl !== null) {
			updateAdapterMutation.mutate(state);
		} else {
			createAdapterMutation.mutate(state);
		}
	};

	if (adapterFromUrl !== null) {
		if (adapterQuery.isLoading || adapterQuery.isIdle) {
			return <Loading />;
		}

		if (adapterQuery.isError) {
			return <Error onRetry={adapterQuery.refetch} />;
		}
	}

	const error = updateAdapterMutation.error ?? createAdapterMutation.error;

	const findError = (paramName: string) => {
		return findErrorFromValidation(error, paramName);
	};

	const stateType = types.find((t) => t.value === state.type);
	const hasConfig = state.type === AdapterType.Fix || state.type === AdapterType.Fixquoter;

	return (
		<PageLayout title={title}>
			<CForm onSubmit={invokeMutation}>
				<CRow>
					<CCol>
						<CCard className="card-overflow-visible">
							<CCardBody>
								<CFormGroup>
									<CLabel>{_t(`global.name`)}</CLabel>
									<CInputGroup>
										<CInput
											type="text"
											placeholder="warehouse"
											value={state.name}
											onChange={onNameChange}
											disabled={adapterFromUrl !== null}
										/>
									</CInputGroup>
									{findError('name') && <CLabel className="text-danger">{findError('name')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t(`adapters.enabled`)}</CLabel>
									<br></br>
									<CSwitch color="primary" checked={state.enabled} onChange={toggleReverse} />
									<br></br>
									{findError('enabled') && <CLabel className="text-danger">{findError('enabled')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel>{_t('global.type')}</CLabel>
									<CInputGroup>
										<CustomSelect
											className="trading-select"
											options={types}
											name="status"
											value={stateType}
											whiteBackground
											onChange={onSelectChange}
										/>
									</CInputGroup>
									{findError('type') && <CLabel className="text-danger">{findError('type')}</CLabel>}
								</CFormGroup>
								{hasConfig && (
									<>
										<CLabel htmlFor="sizes" className="instrument-section-label">
											{_t('adapters.configuration')}
										</CLabel>
										<CFormGroup>
											<CLabel>SSL</CLabel>
											<br></br>
											<CSwitch color="primary" checked={state.configuration?.ssl} onChange={toggleReverseSSL} />
											<br></br>
											{findError('ssl') && <CLabel className="text-danger">{findError('ssl')}</CLabel>}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="host">{_t('adapters.host')}</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id="host"
													name="host"
													placeholder="www.host.com"
													value={state.configuration?.host || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('host') && <CLabel className="text-danger">{findError('host')}</CLabel>}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="port">{_t('adapters.port')}</CLabel>
											<CInputGroup>
												<CInput
													type="number"
													id="port"
													name="port"
													placeholder="30100"
													value={state.configuration?.port || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('port') && <CLabel className="text-danger">{findError('port')}</CLabel>}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="session">{_t('adapters.session')}</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id="session"
													name="session"
													placeholder="Friday:00:00:00-Friday:00:00:00"
													value={state.configuration?.session || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('session') && <CLabel className="text-danger">{findError('session')}</CLabel>}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="password">{_t('login.password')}</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id="password"
													name="password"
													placeholder=""
													value={state.configuration?.password || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('password') && <CLabel className="text-danger">{findError('password')}</CLabel>}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="username">{_t('adapters.username')}</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id="username"
													name="username"
													placeholder="Username"
													value={state.configuration?.username || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('username') && <CLabel className="text-danger">{findError('username')}</CLabel>}
										</CFormGroup>
										<CFormGroup>
											<CLabel>{_t(`adapters.reset-on-logon`)}</CLabel>
											<br></br>
											<CSwitch
												color="primary"
												checked={state.configuration?.resetOnLogon}
												onChange={toggleReverseResetOnLogon}
											/>
											<br></br>
											{findError('resetOnLogon') && (
												<CLabel className="text-danger">{findError('resetOnLogon')}</CLabel>
											)}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="senderCompId">SenderCompID</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id="senderCompId"
													name="senderCompId"
													placeholder="Test"
													value={state.configuration?.senderCompId || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('senderCompId') && (
												<CLabel className="text-danger">{findError('senderCompId')}</CLabel>
											)}
										</CFormGroup>
										<CFormGroup>
											<CLabel htmlFor="targetCompId">TargetCompID</CLabel>
											<CInputGroup>
												<CInput
													type="text"
													id="targetCompId"
													name="targetCompId"
													placeholder="Test"
													value={state.configuration?.targetCompId || ''}
													onChange={setConfigFormValue}
												/>
											</CInputGroup>
											{findError('targetCompId') && (
												<CLabel className="text-danger">{findError('targetCompId')}</CLabel>
											)}
										</CFormGroup>
									</>
								)}
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<ButtonWithLoader
							isLoading={updateAdapterMutation.isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={adapterFromUrl !== null ? _t('action.update') : _t('action.save')}
							className="instrument-buttons mt-2"
						/>
					</CCol>
				</CRow>
			</CForm>
		</PageLayout>
	);
};

export default React.memo(CreateUpdateAdapter);
