import React, { useState } from 'react';
import {
	CButton,
	CInput,
	CLabel,
	CModal,
	CModalBody,
	CModalTitle,
	CModalFooter,
	CModalHeader,
	CFormGroup,
	CSelect,
	CInputCheckbox,
} from '@coreui/react';
import _t from 'counterpart';
import { OrderRoutingAdapterSelector } from '../customers/CustomerWalletPopup';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { createGroup } from '../../services/BackendService';
import { useMutation } from 'react-query';
import { useAppDispatch } from '../../helpers/customHooks';
import { showSuccessToast, showErrorToast } from '../../actions';
import { IAddNewGroup, ICreateGroupProps } from './types';
import ButtonWithLoader from '../../components/ButtonWithLoader';

const CreateGroupModal = ({ show, hideAddGroup }: ICreateGroupProps) => {
	const [newGroupValues, setNewGroupValues] = useState<IAddNewGroup>({
		name: '',
		leverage: '',
		currency: 'USD',
		adapterName: '',
		disableSwapCharges: false,
	});

	const findError = findErrorFromValidation;
	const dispatch = useAppDispatch();

	const createGroupMutation = useMutation(['create-group'], () => createGroup(newGroupValues), {
		onSuccess: () => {
			dispatch(showSuccessToast(_t('groups.group-successfully-added')));
			setNewGroupValues({ name: '', leverage: '', currency: 'USD', adapterName: '', disableSwapCharges: false });
			hideAddGroup(true);
		},
		onError: (error: any) => {
			if (error.response?.status !== 422) {
				const msg = extractErrorMessage(error);
				dispatch(showErrorToast(msg));
			}
		},
	});

	const addGroupCall = () => {
		createGroupMutation.mutate();
	};

	const handleInputChange = (e: React.FormEvent<any>, assureInputIsNumber?: boolean) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		const value = assureInputIsNumber ? Number(target.value) : target.value;

		if (name) {
			setNewGroupValues({ ...newGroupValues, [name]: value });
		}
	};

	const setRoutingAdapterName = (adapterName: string) => {
		setNewGroupValues({ ...newGroupValues, adapterName });
	};

	const toggleDisableSwapCharges = () => {
		const disableSwapCharges = !newGroupValues.disableSwapCharges;
		setNewGroupValues({ ...newGroupValues, disableSwapCharges });
	};

	const hideSelf = () => {
		createGroupMutation.reset();
		setNewGroupValues({ name: '', leverage: '', currency: 'USD', adapterName: '', disableSwapCharges: false });
		hideAddGroup();
	};

	const addGroupErrors = createGroupMutation.error;

	const setCurrency = (currency: string) => {
		setNewGroupValues({ ...newGroupValues, currency });
	};

	return (
		<CModal show={show} onClose={hideSelf} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>{_t('groups.add-group')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="leverage">{_t('groups.name')}</CLabel>
					<CInput
						type="text"
						name="name"
						placeholder="Group name"
						value={newGroupValues.name}
						onChange={(e: any) => handleInputChange(e)}
					/>
					{findError(addGroupErrors, 'name') && (
						<CLabel className="text-danger">{findError(addGroupErrors, 'name')}</CLabel>
					)}
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="leverage">{_t('risk-management.leverage')}</CLabel>
					<CInput
						type="number"
						name="leverage"
						placeholder="100"
						value={newGroupValues.leverage}
						onChange={(e: any) => handleInputChange(e)}
					/>
					{findError(addGroupErrors, 'leverage') && (
						<CLabel className="text-danger">{findError(addGroupErrors, 'leverage')}</CLabel>
					)}
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="currency">{_t('global.currency')}</CLabel>
					<CSelect
						id="nf-currency"
						name="nf-currency"
						onChange={(event: any) => setCurrency(event.target.value)}
						value={newGroupValues.currency}
					>
						<option value="USD">USD</option>
					</CSelect>
					{findError(addGroupErrors, 'currency') && (
						<CLabel className="text-danger">{findError(addGroupErrors, 'currency')}</CLabel>
					)}
				</CFormGroup>
				<CFormGroup>
					<CLabel htmlFor="nf-type">{_t('wallet-modal.order-routing-adapter')}</CLabel>
					<OrderRoutingAdapterSelector onChange={setRoutingAdapterName} value={newGroupValues.adapterName} />
				</CFormGroup>
				<CFormGroup>
					<div className="form-check form-check-inline">
						<CInputCheckbox
							className="form-check-input"
							type="checkbox"
							id="is-demo"
							onChange={toggleDisableSwapCharges}
							checked={newGroupValues.disableSwapCharges}
						/>
						<span>{_t('groups.disable-swap-charges')}</span>
					</div>
				</CFormGroup>
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={createGroupMutation.isLoading}
					onClick={addGroupCall}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.add')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={hideSelf} disabled={createGroupMutation.isLoading}>
					{_t('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default CreateGroupModal;
