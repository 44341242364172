import React, { useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CButton, CBadge, CDataTable, CLink } from '@coreui/react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { showErrorToast } from '../../actions';
import { IEducationsCategory } from './types';
import { formatDateTime, extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import { useQuery } from 'react-query';
import { loadEducationCategories, deleteEducationCategory } from '../../services/BackendService';
import CreateUpdateCategory from './CreateUpdateCategory';
import Loading from '../../components/Loading';
import PageLayout from '../../components/PageLayout';
import ConfirmationModal from '../../components/ConfirmationModal';
import Error from '../../components/Error';

const EducationsPage = () => {
	const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
	const [currentEditCategory, setCurrentEditCategory] = useState<IEducationsCategory | null>(null);
	const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
	const dispatch = useAppDispatch();

	const educationCategoriesQuery = useQuery(['education-categories'], () => loadEducationCategories(), {
		onError: (error: any) => {
			dispatch(showErrorToast(extractErrorMessage(error)));
		},
	});

	const fields = [
		{ key: 'thumbnail', label: _t('education.thumbnail') },
		{ key: 'title', label: _t('education.title') },
		{ key: 'createdAt', label: _t('education.created-at') },
		{ key: 'languages', label: _t('education.languages') },
		{ key: 'edit', label: _t('action.edit') },
	];

	const setCategoryEditMode = async (category: IEducationsCategory) => {
		setCurrentEditCategory(category);
		setShowCategoryModal(true);
	};

	const showAddCategoryWrapper = () => {
		setShowCategoryModal(true);
	};

	const onClose = (refetch: boolean) => {
		if (refetch) {
			educationCategoriesQuery.refetch();
		}
		setCurrentEditCategory(null);
		setShowCategoryModal(false);
	};

	const hideDeleteModal = (refetch?: boolean) => {
		setCurrentDeleteId(null);
		if (refetch) {
			educationCategoriesQuery.refetch();
		}
	};

	const onErrorRetry = () => {
		educationCategoriesQuery.refetch();
	};

	if (educationCategoriesQuery.isLoading || educationCategoriesQuery.isIdle) {
		return <Loading />;
	}

	if (educationCategoriesQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const categories = educationCategoriesQuery.data;

	return (
		<PageLayout title={_t.translate('sidebar.education')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody className="simulated-overflow-y">
							<div className="my-2 float-sm-right d-flex justify-content-end">
								<CButton onClick={showAddCategoryWrapper} className="header-button-add" />
							</div>
							<CDataTable
								items={categories || []}
								fields={fields}
								sorter
								striped
								tableFilter={{ placeholder: _t('global.search-something') }}
								scopedSlots={{
									thumbnail: (category: IEducationsCategory) => (
										<td>
											<CLink href={`/educations/${category.categoryId}`}>
												<img
													src={`${process.env.REACT_APP_API_ROOT}/api/education/file/${category.token}`}
													className="rounded"
													style={{ objectFit: 'contain', width: 30, height: 30 }}
													alt="Thumbnail"
												/>
											</CLink>
										</td>
									),
									title: (category: IEducationsCategory) => (
										<td className="text-nowrap">
											<CLink href={`/educations/${category.categoryId}`}>{category.title}</CLink>
										</td>
									),
									createdAt: (category: IEducationsCategory) => (
										<td className="text-nowrap">{formatDateTime(category.createdAt)}</td>
									),
									languages: (category: IEducationsCategory) => (
										<td>{category.languages.length > 0 ? category.languages.length : _t('global.all')}</td>
									),
									edit: (category: IEducationsCategory) => (
										<td>
											<CButton color="primary" size="sm" onClick={() => setCategoryEditMode(category)}>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											<CButton
												className="ml-2"
												color="danger"
												size="sm"
												onClick={() => setCurrentDeleteId(category.categoryId.toString())}
											>
												<CIcon name="cil-trash" size="sm" />
											</CButton>
										</td>
									),
								}}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			{showCategoryModal && (
				<CreateUpdateCategory onClose={onClose} show={showCategoryModal} currentCategory={currentEditCategory} />
			)}

			<ConfirmationModal
				id={currentDeleteId!}
				show={Boolean(currentDeleteId)}
				hidePanel={hideDeleteModal}
				onConfirm={deleteEducationCategory}
				title={_t('education.delete-category')}
				error={null}
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default EducationsPage;
