import React, { useMemo, useState } from 'react';
import { CRow, CCol, CCard, CCardBody, CButton, CDataTable, CBadge, CCardHeader } from '@coreui/react';
import { useQuery } from 'react-query';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { getOnboardingStepConfigurations } from '../../services/BackendService';
import { IOnboardingStepConfiguration } from './types';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import EditOnboardingStepConfiguration from './EditOnboardingStepConfiguration';
import { showErrorToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';

const StepConfiguration = () => {
	const [currentStepConfig, setCurrentStepConfig] = useState<IOnboardingStepConfiguration | null>(null);

	const dispatch = useAppDispatch();

	const onboardingStepsConfigurationsQuery = useQuery(
		['onboarding-steps-configurations'],
		() => getOnboardingStepConfigurations(),
		{
			onError: (e: any) => {
				const errMsg = extractErrorMessage(e);
				dispatch(showErrorToast(errMsg));
			},
		}
	);

	const fields = useMemo(
		() => [
			{ key: 'step', label: _t.translate('onboarding.step') },
			{ key: 'stage', label: _t.translate('onboarding.stage') },
			{ key: 'actions', label: '' },
		],
		[]
	);

	const slots = useMemo(
		() => ({
			stage: (stepConfig: IOnboardingStepConfiguration) => (
				<td>
					{!stepConfig.stage && <CBadge color="secondary">{_t.translate('global.none')}</CBadge>}
					{stepConfig && stepConfig.stage}
				</td>
			),
			actions: (stepConfig: IOnboardingStepConfiguration) => (
				<td className="table-small_cta">
					<CButton onClick={() => setCurrentStepConfig(stepConfig)} size="sm" className="mr-1" color="primary">
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
				</td>
			),
		}),
		[]
	);

	const hideOnboardingStepEditModal = () => {
		setCurrentStepConfig(null);
	};

	const onErrorRetry = () => {
		onboardingStepsConfigurationsQuery.refetch();
	};

	const { isLoading, isIdle } = onboardingStepsConfigurationsQuery;
	const loadingOnboardingSteps = isLoading || isIdle;
	const errorOnboardingSteps = onboardingStepsConfigurationsQuery.isError;

	return (
		<>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>{_t('onboarding.onboarding-step-configurations')}</CCardHeader>
						<CCardBody className="simulated-overflow-y">
							{loadingOnboardingSteps && <Loading />}
							{errorOnboardingSteps && <Error onRetry={onErrorRetry} />}
							{!errorOnboardingSteps && !loadingOnboardingSteps && (
								<CDataTable
									items={onboardingStepsConfigurationsQuery.data || []}
									fields={fields}
									scopedSlots={slots}
									loading={onboardingStepsConfigurationsQuery.isLoading}
									striped
								/>
							)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{currentStepConfig && (
				<EditOnboardingStepConfiguration
					hideModal={hideOnboardingStepEditModal}
					onboardingStepConfig={currentStepConfig}
				/>
			)}
		</>
	);
};

export default StepConfiguration;
