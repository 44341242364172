import React, { useState, FormEvent } from 'react';
import {
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CForm,
	CModalFooter,
	CButton,
	CFormGroup,
	CInput,
	CInputGroup,
	CLabel,
	CSelect,
} from '@coreui/react';
import _t from 'counterpart';
import { useQuery, useMutation } from 'react-query';
import { useAppDispatch } from '../../helpers/customHooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { loadCountries, createCustomer } from '../../services/BackendService';
import { showErrorToast } from '../../actions';
import ButtonWithLoader from '../../components/ButtonWithLoader';

interface IProps {
	show: boolean;
	onClose: () => void;
}

interface IState {
	name: string;
	mobilenumber?: string;
	email: string;
	country: string;
	currency: string;
}

const initialState = {
	name: '',
	mobilenumber: undefined,
	email: '',
	country: '',
	currency: 'USD',
};

const AddCustomerModal = ({ show, onClose }: IProps) => {
	const [state, setState] = useState<IState>(initialState);
	const { name, mobilenumber, email, country, currency } = state;

	const dispatch = useAppDispatch();

	const { data: countries } = useQuery(['add-customer-countries'], () => loadCountries());
	const addCustomerMutation = useMutation(
		['customer-created'],
		() => createCustomer(name, mobilenumber || '', email, country, currency),
		{
			onSuccess: () => {
				onClose();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					dispatch(showErrorToast(extractErrorMessage(error)));
				}
			},
			retry: false,
		}
	);

	const close = () => {
		addCustomerMutation.reset();
		onClose();
	};

	const loading = addCustomerMutation.isLoading;

	const resetState = () => {
		setState({ ...initialState });
	};

	const setFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setState({ ...state, [name]: value });
	};

	const addCustomer = (e: React.FormEvent) => {
		e.preventDefault();
		addCustomerMutation.mutate();
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(addCustomerMutation.error, paramName);
	};

	return (
		<>
			<CModal show={show} onClose={onClose} onClosed={resetState} closeOnBackdrop={false}>
				<CModalHeader closeButton>
					<CModalTitle>{_t('add-customer-modal.add-customer')}</CModalTitle>
				</CModalHeader>
				<CForm onSubmit={addCustomer}>
					<CModalBody>
						<CFormGroup>
							<CLabel htmlFor="nf-name">{_t('global.name')}</CLabel>
							<CInputGroup>
								<CInput
									type="text"
									id="nf-name"
									name="name"
									placeholder="John Doe"
									value={name || ''}
									onChange={setFormValue}
								/>
							</CInputGroup>
							{findError('fullname') && <CLabel className="text-danger">{findError('fullname')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="nf-email">{_t('add-customer-modal.email')}</CLabel>
							<CInputGroup>
								<CInput
									type="text"
									id="nf-email"
									name="email"
									placeholder="johndoe@example.com"
									value={email || ''}
									onChange={setFormValue}
								/>
							</CInputGroup>
							{findError('email') && <CLabel className="text-danger">{findError('email')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="nf-mobilenumber">{_t('add-customer-modal.mobilenumber')}</CLabel>
							<CInputGroup>
								<CInput
									type="text"
									id="nf-mobilenumber"
									name="mobilenumber"
									placeholder="+357 999 134566"
									value={mobilenumber || ''}
									onChange={setFormValue}
								/>
							</CInputGroup>
							{findError('mobilenumber') && <CLabel className="text-danger">{findError('mobilenumber')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="nf-type">{_t('add-customer-modal.country')}</CLabel>
							<CInputGroup>
								{show && (
									<CSelect id="nf-country" name="country" onChange={setFormValue} value={country}>
										{country === '' && (
											<option defaultChecked value="">
												{' '}
												-- {_t('add-customer-modal.select-country')} --{' '}
											</option>
										)}
										{countries &&
											countries.map((c) => (
												<option key={c.isoCode} value={c.isoCode}>
													{c.name}
												</option>
											))}
									</CSelect>
								)}
							</CInputGroup>
							{findError('country') && <CLabel className="text-danger">{findError('country')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="nf-currency">{_t('add-customer-modal.currency')}</CLabel>
							<CInputGroup>
								<CSelect id="nf-currency" name="currency" onChange={setFormValue} value={currency}>
									<option value="USD">USD</option>
								</CSelect>
							</CInputGroup>
							{findError('currency') && <CLabel className="text-danger">{findError('currency')}</CLabel>}
						</CFormGroup>
					</CModalBody>
					<CModalFooter>
						<ButtonWithLoader
							isLoading={loading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={_t.translate('action.add')}
							className="mr-2"
						/>
						<CButton color="light" variant="outline" onClick={close} disabled={loading}>
							{_t('action.cancel')}
						</CButton>
					</CModalFooter>
				</CForm>
			</CModal>
		</>
	);
};

export default AddCustomerModal;
