import React, { FormEvent, useState } from 'react';
import { CRow, CCol, CInput, CLabel, CFormGroup, CInputGroup, CInputCheckbox } from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { showErrorToast, showSuccessToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';
import { useGetSetting } from './hooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation } from 'react-query';
import { updateSmtpProvider } from '../../services/BackendService';

interface IParams {
	sender: string;
	host: string;
	port: string;
	username: string;
	password: string;
	secure: boolean;
}

const SmtpProviderIntegration = () => {
	const [host, setHost] = useState<string>('');
	const [username, setUsername] = useState<string>('');
	const [port, setPort] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [emailSender, setEmailSender] = useState<string>('');
	const [secure, setSecure] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const hostQuery = useGetSetting('SMTP_HOST', 'smtp-host', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setHost(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const emailSenderQuery = useGetSetting('EMAIL_TRANSACTIONAL_SENDER', 'email-sender', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setEmailSender(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const portQuery = useGetSetting('SMTP_PORT', 'smtp-port', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setPort(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const usernameQuery = useGetSetting('SMTP_USERNAME', 'smtp-username', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setUsername(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const passwordQuery = useGetSetting('SMTP_PASSWORD', 'smtp-password', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setPassword(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const secureQuery = useGetSetting('SMTP_SECURE', 'smtp-secure', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: boolean) => {
			setSecure(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const updateSmtpProviderMutation = useMutation(
		({ sender, host, port, username, password, secure }: IParams) =>
			updateSmtpProvider(sender, host, username, port, password, secure),
		{
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
			onSuccess: () => {
				dispatch(showSuccessToast(_t('integrations.smtp-provider-configured')));
				hostQuery.refetch();
				emailSenderQuery.refetch();
				portQuery.refetch();
				usernameQuery.refetch();
				passwordQuery.refetch();
				secureQuery.refetch();
			},
		}
	);

	const setHostFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setHost(value);
	};

	const setUsernameFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setUsername(value);
	};

	const setPortFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setPort(value);
	};

	const setPasswordFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setPassword(value);
	};

	const setSenderFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setEmailSender(value);
	};

	const toggleSecure = () => {
		const secureConnectionEnabled = !secure;
		setSecure(secureConnectionEnabled);
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(updateSmtpProviderMutation.error, paramName);
		return error;
	};

	const callUpdateSmtpProviderMutation = () => {
		updateSmtpProviderMutation.mutate({ host, port, username, password, sender: emailSender, secure });
	};

	return (
		<CRow>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="host">{_t(`integrations.smtp-provider-host`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="host"
							name="host"
							value={host}
							onChange={setHostFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{hostQuery.error && <CLabel className="text-danger">{_t('integrations.smtp-provider-host-failed')}</CLabel>}
					{findError('host') && <CLabel className="text-danger">{findError('host')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="port">{_t(`integrations.smtp-provider-port`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="port"
							name="port"
							value={port}
							onChange={setPortFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{portQuery.error && <CLabel className="text-danger">{_t('integrations.smtp-provider-port-failed')}</CLabel>}
					{findError('port') && <CLabel className="text-danger">{findError('port')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="username">{_t(`integrations.smtp-provider-username`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="username"
							name="username"
							value={username}
							onChange={setUsernameFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{usernameQuery.error && (
						<CLabel className="text-danger">{_t('integrations.smtp-provider-username-failed')}</CLabel>
					)}
					{findError('username') && <CLabel className="text-danger">{findError('username')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="password">{_t(`integrations.smtp-provider-password`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="password"
							name="password"
							value={password}
							onChange={setPasswordFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{passwordQuery.error && (
						<CLabel className="text-danger">{_t('integrations.smtp-provider-password-failed')}</CLabel>
					)}
					{findError('password') && <CLabel className="text-danger">{findError('password')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="sender">{_t(`integrations.email-sender`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="sender"
							name="sender"
							value={emailSender}
							onChange={setSenderFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{emailSenderQuery.error && <CLabel className="text-danger">{_t('integrations.email-sender-failed')}</CLabel>}
					{findError('sender') && <CLabel className="text-danger">{findError('sender')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<div className="form-check form-check-inline">
						<CInputCheckbox
							className="form-check-input"
							type="checkbox"
							id="secure"
							onChange={toggleSecure}
							checked={secure}
						/>
						<span>{_t('integrations.smtp-provider-secure')}</span>
					</div>
				</CFormGroup>
			</CCol>
			<CCol md={12} className="d-flex justify-content-end">
				<ButtonWithLoader
					isLoading={updateSmtpProviderMutation.isLoading}
					onClick={callUpdateSmtpProviderMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.save')}
					className="btn-md"
					showText={false}
					disabled={host === '' || emailSender === '' || port === '' || username === '' || password === ''}
				/>
			</CCol>
		</CRow>
	);
};

export default SmtpProviderIntegration;
