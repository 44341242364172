import React, { useState } from 'react';
import {
	CButton,
	CCard,
	CCardBody,
	CCol,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CLabel,
	CLink,
	CRow,
	CBadge,
} from '@coreui/react';
import { useMutation, useQuery } from 'react-query';
import _t from 'counterpart';
import { formatDate } from '../../helpers';
import {
	approveKYCStatus,
	loadCustomerDocuments,
	resetKYCStatus,
	revokeKYCStatus,
	saveKYCDocuments,
} from '../../services/BackendService';
import KYCPreview from '../../components/KYCPreview';
import FileUploader from '../../components/FileUploader';
import CenteredSpinner from '../../components/CenteredSpinner';
import InlineSpinner from '../../components/InlineSpinner';
import { useAppSelector } from '../../helpers/customHooks';
import { Permission } from '../../reducers/userReducer';

enum KycStatus {
	Approved = 'APPROVED',
	Pending = 'PENDING',
	Rejected = 'REVOKED',
}

const kycStatusColor = (status: KycStatus | null) => {
	let color;
	let term;

	switch (status) {
		case KycStatus.Approved:
			color = 'success';
			term = _t('kyc.approved');
			break;
		case KycStatus.Rejected:
			color = 'danger';
			term = _t('kyc.revoked');
			break;
		default:
			color = 'secondary';
			term = _t('kyc.pending');
	}
	return { color, term };
};

const ChangeKYCStatusButton = ({
	status,
	onChange,
	loading,
}: {
	status: KycStatus | null;
	onChange: (status: KycStatus) => void;
	loading: boolean;
}) => {
	const { color, term } = kycStatusColor(status);

	return (
		<CDropdown>
			<CDropdownToggle caret color={color} size="sm" disabled={loading}>
				{loading ? <InlineSpinner color="white" /> : term}
			</CDropdownToggle>
			<CDropdownMenu>
				<CDropdownItem disabled={status === KycStatus.Approved} onClick={() => onChange(KycStatus.Approved)}>
					{_t('kyc.approved')}
				</CDropdownItem>
				<CDropdownItem disabled={status === KycStatus.Pending} onClick={() => onChange(KycStatus.Pending)}>
					{_t('kyc.pending')}
				</CDropdownItem>
				<CDropdownItem disabled={status === KycStatus.Rejected} onClick={() => onChange(KycStatus.Rejected)}>
					{_t('kyc.revoked')}
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	);
};

export const KYCTab = ({ customer, onUpdated }: { customer: any; onUpdated: () => void }) => {
	const [fileUploadError, setFileUploadError] = useState<string | null>(null);

	const permissions = useAppSelector((state) => state.user.permissions);

	const [kycLocal, setKycLocal] = useState<Array<any>>([]);

	const { data: kyc, refetch: refetchDocuments } = useQuery(['customer-documents', customer.id], () =>
		loadCustomerDocuments(customer.id)
	);

	const approveKYCMutation = useMutation((id: string) => approveKYCStatus(id), {
		onSuccess: () => onUpdated(),
	});

	const revokeKYCMutation = useMutation((id: string) => revokeKYCStatus(id), {
		onSuccess: () => onUpdated(),
	});

	const resetKYCMutation = useMutation((id: string) => resetKYCStatus(id), {
		onSuccess: () => onUpdated(),
	});

	const onStatusSelected = (status: KycStatus) => {
		switch (status) {
			case KycStatus.Approved:
				approveKYCMutation.mutate(customer.id);
				break;
			case KycStatus.Rejected:
				revokeKYCMutation.mutate(customer.id);
				break;
			case KycStatus.Pending:
				resetKYCMutation.mutate(customer.id);
				break;
			default:
				throw new Error('invalid status');
		}
	};

	const saveDocumentsMutation = useMutation(() => saveKYCDocuments(kycLocal, customer.id), {
		onSuccess: () => {
			onUpdated();
			refetchDocuments();
		},
	});

	const trySaveDocuments = () => {
		saveDocumentsMutation.mutate();
	};

	const isMutatingKYCStatus = approveKYCMutation.isLoading || revokeKYCMutation.isLoading || resetKYCMutation.isLoading;

	const storeKYCWrapper = (documents: any) => {
		setKycLocal(documents);
	};

	const removeKYCWrapper = (documentName: string) => {
		const filteredResults = kycLocal.filter((file: any) => {
			return file.name !== documentName;
		});
		setKycLocal(filteredResults);
	};

	const kycManagePermission = permissions?.includes(Permission.MANAGE_CUSTOMER_KYC_DOCUMENTS);
	const { color, term } = kycStatusColor(customer.kycStatus);

	return (
		<CCard className="borderless-card">
			<CCardBody>
				<CRow>
					<CCol sm={6}>
						<strong>{_t('kyc.status')}</strong>
					</CCol>
					<CCol sm={6}>
						{kycManagePermission && (
							<ChangeKYCStatusButton
								status={customer.kycStatus}
								onChange={onStatusSelected}
								loading={isMutatingKYCStatus}
							/>
						)}
						{!kycManagePermission && <CBadge color={color}>{term}</CBadge>}
					</CCol>
				</CRow>
				{Boolean(customer.kycReviewedById) && Boolean(customer.kycReviewedByName) && (
					<CRow>
						<CCol sm={6}>
							<strong>{_t('kyc.reviewer')}</strong>
						</CCol>
						<CCol sm={6}>
							<CLink href={`/customers/${customer.kycReviewedById}`}>{customer.kycReviewedByName}</CLink>
						</CCol>
					</CRow>
				)}
				{customer.kycReviewedAt && (
					<CRow>
						<CCol sm={6}>
							<strong>{_t('kyc.reviewed-at')}</strong>
						</CCol>
						<CCol sm={6}>{formatDate(new Date(customer.kycReviewedAt))}</CCol>
					</CRow>
				)}
				<CRow className="mt-5">
					<CCol>
						<KYCPreview documents={kyc || []} />
						{!saveDocumentsMutation.isLoading && kycManagePermission && (
							<FileUploader
								allowMultipleFiles
								addFiles={storeKYCWrapper}
								removeFile={removeKYCWrapper}
								validFileTypes={['png', 'jpg', 'jpeg', 'gif']}
								onError={setFileUploadError}
							/>
						)}
						{saveDocumentsMutation.isLoading && <CenteredSpinner color="primary" />}
						<div className="d-flex align-items-center mb-2">
							<CLabel className="text-danger">{fileUploadError}</CLabel>
						</div>
						{kycManagePermission && (
							<CButton color="primary" disabled={kycLocal.length === 0} onClick={trySaveDocuments}>
								{_t('action.upload')}
							</CButton>
						)}
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	);
};
