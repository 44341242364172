import React, { useState } from 'react';
import {
	CButton,
	CModal,
	CModalHeader,
	CModalBody,
	CModalTitle,
	CModalFooter,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CSelect,
	CBadge,
} from '@coreui/react';
import _t from 'counterpart';
import { showErrorToast } from '../../actions';
import { supportedLocales } from '../../locales';
import { extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import { useMutation } from 'react-query';
import { createEducationCategoy, updateEducationCategoy } from '../../services/BackendService';
import FileUploader from '../../components/FileUploader';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IEducationsCategory } from './types';

interface IProps {
	onClose: (refetch: boolean) => void;
	show: boolean;
	currentCategory: IEducationsCategory | null;
}

interface IState {
	categoryName: string;
	languages: Array<string>;
	unusedLanguages: Array<string>;
	addCategoryTitleError: string | null;
	addCategoryThumbnailError: string | null;
	categoryEditModeId: number | null;
	categoryEditLink: string | null;
	categoryEditFilename: string | null;
}

const CreateUpdateCategory = ({ onClose, show, currentCategory }: IProps) => {
	const [thumbnail, setThumbnail] = useState<any | null>(null);

	const dispatch = useAppDispatch();

	const fileLink = `${process.env.REACT_APP_API_ROOT}/api/education/file/${currentCategory?.token}`;

	let unusedLocales = supportedLocales.sort();
	if (currentCategory) {
		const { languages } = currentCategory;
		unusedLocales = supportedLocales.filter((locale: string) => !languages.includes(locale));
	}

	const [state, setState] = useState<IState>({
		categoryName: currentCategory?.title || '',
		languages: currentCategory?.languages || [],
		unusedLanguages: unusedLocales,
		addCategoryTitleError: null,
		addCategoryThumbnailError: null,
		categoryEditModeId: currentCategory?.categoryId || null,
		categoryEditLink: currentCategory ? fileLink : null,
		categoryEditFilename: currentCategory?.thumbnailLink || null,
	});

	const {
		categoryName,
		languages,
		unusedLanguages,
		addCategoryThumbnailError,
		addCategoryTitleError,
		categoryEditModeId,
		categoryEditLink,
		categoryEditFilename,
	} = state;

	const addCategoryMutation = useMutation(
		['create-category'],
		() => createEducationCategoy(categoryName, languages, thumbnail),
		{
			onSuccess: () => {
				onClose(true);
			},
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const updateCategoryMutation = useMutation(
		['update-category'],
		() => updateEducationCategoy(categoryEditModeId!, categoryName!, languages || [], thumbnail!),
		{
			onSuccess: () => {
				onClose(true);
			},
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const isUpdating = addCategoryMutation.isLoading || updateCategoryMutation.isLoading;

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');

		if (name) {
			setState({ ...state, categoryName: target.value });
		}
	};

	const handleAddCountry = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const { languages, unusedLanguages } = state;

		const currentLanguages = [...languages];
		if (target.value) {
			currentLanguages?.push(target.value);
			const availableLanguages = unusedLanguages?.filter((locale) => {
				return locale !== target.value;
			});

			setState({ ...state, languages: currentLanguages, unusedLanguages: availableLanguages });
		}
	};

	const removeCountry = (countryCode: string) => {
		const { languages, unusedLanguages } = state;
		const currentUnused = [...unusedLanguages];
		currentUnused?.push(countryCode);
		const usedLanguages = languages?.filter((locale) => {
			return locale !== countryCode;
		});

		setState({
			...state,
			languages: usedLanguages,
			unusedLanguages: currentUnused?.sort(),
		});
	};

	const tryCreateCategory = () => {
		const { categoryName, categoryEditModeId } = state;

		setState({
			...state,
			addCategoryTitleError: null,
			addCategoryThumbnailError: null,
		});
		let error = false;
		if (!categoryName || categoryName.trim().length === 0) {
			setState({ ...state, addCategoryTitleError: _t('education.error-empty-title') });
			error = true;
		}

		if (!thumbnail) {
			setState({ ...state, addCategoryThumbnailError: _t('education.error-empty-thumbnail') });
			error = true;
		}

		if (!error) {
			if (categoryEditModeId) {
				updateCategoryMutation.mutate();
			} else {
				addCategoryMutation.mutate();
			}
		}
	};

	const handleDropzoneError = (addCategoryThumbnailError: string) => {
		setState({ ...state, addCategoryThumbnailError });
	};

	const tryAddFile = (files: any) => {
		setState({ ...state, addCategoryThumbnailError: null });
		setThumbnail(files[0]);
	};

	const removeFile = () => {
		setThumbnail(null);
	};

	const close = () => {
		onClose(false);
	};

	return (
		<CModal show={show} onClose={close}>
			<CModalHeader closeButton>
				<CModalTitle>{categoryEditModeId ? _t('education.edit-category') : _t('education.add-category')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CForm onSubmit={tryCreateCategory}>
					<CFormGroup>
						<CLabel htmlFor="categoryName">{_t('global.title')}</CLabel>
						<CInput
							type="text"
							id="categoryName"
							name="categoryName"
							value={categoryName}
							onChange={handleInputChange}
						/>
						{addCategoryTitleError && <div className="text-danger">{addCategoryTitleError}</div>}
					</CFormGroup>
					<CFormGroup>
						<CLabel htmlFor="languages">{_t('education.add-language-translation')}</CLabel>
						<CSelect name="languages" onChange={handleAddCountry} value="">
							{(languages?.length ?? 0) === 0 && (
								<option defaultChecked value="">
									{' '}
									-- {_t('education.all-languages')} --{' '}
								</option>
							)}
							{(languages?.length ?? 0) > 0 && (
								<option defaultChecked value="">
									{' '}
									-- {_t('education.select-more-languages')} --{' '}
								</option>
							)}
							{unusedLanguages!.map((option) => {
								return (
									<option key={option} value={option}>
										{_t(`languages.${option}`)}
									</option>
								);
							})}
						</CSelect>
					</CFormGroup>
					<CFormGroup>
						<div className="d-flex flex-wrap">
							{languages?.map((key) => {
								return (
									<CBadge color="info" className="badgeListItem d-flex align-items-center" key={`lang-${key}`}>
										{_t(`languages.${key}`)}
										<button
											style={{ marginLeft: '5px' }}
											type="button"
											className="dark close no-outline"
											aria-label="Close"
											onClick={() => removeCountry(key)}
										>
											×
										</button>
									</CBadge>
								);
							})}
						</div>
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('education.add-thumbnail')}</CLabel>
						<FileUploader
							allowMultipleFiles={false}
							addFiles={tryAddFile}
							removeFile={removeFile}
							maxFilesSizeInBytes={150000}
							validFileTypes={['png', 'jpg', 'jpeg', 'gif']}
							onError={handleDropzoneError}
							externalFile={categoryEditLink}
							externalFileName={categoryEditFilename}
							generateFileName
						/>
						{addCategoryThumbnailError && <div className="text-danger">{addCategoryThumbnailError}</div>}
					</CFormGroup>
				</CForm>
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={isUpdating}
					onClick={tryCreateCategory}
					buttonColor="primary"
					spinnerColor="secondary"
					title={categoryEditModeId ? _t('action.save') : _t('action.create')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={close} disabled={isUpdating}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default CreateUpdateCategory;
