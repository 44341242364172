import React, { useState } from 'react';
import {
	CModal,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CForm,
	CFormGroup,
	CLabel,
	CInput,
	CModalTitle,
	CButton,
} from '@coreui/react';
import _t from 'counterpart';
import { extractErrorMessage, findErrorFromValidation } from '../../../helpers';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import Textarea from 'react-expanding-textarea';
import { useAppDispatch } from '../../../helpers/customHooks';
import { useMutation } from 'react-query';
import { sendEmailToCustomer } from '../../../services/BackendService';
import { showSuccessToast, showErrorToast } from '../../../actions';

interface IProps {
	show: boolean;
	onClose: () => void;
	onCloseDiscardChanges: () => void;
	customer: any;
}

interface SendEmailParameters {
	id: string;
	subject: string;
	body: string;
}

const EmailCustomerModal = ({ show, onClose, onCloseDiscardChanges, customer }: IProps) => {
	const [subject, setSubject] = useState<string>('');
	const [body, setBody] = useState<string>('');
	const [discardChangesModalShow, setDiscardChangesModalShow] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const sendEmailMutation = useMutation(
		({ id, subject, body }: SendEmailParameters) => sendEmailToCustomer(id, subject, body),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t.translate('customer.email-sent-to-customer')));
				setDiscardChangesModalShow(false);
				setSubject('');
				setBody('');
				onClose();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const close = () => {
		setDiscardChangesModalShow(false);
		setSubject('');
		setBody('');
		sendEmailMutation.reset();
	};

	const handleClose = () => {
		if (subject !== '' || body !== '') {
			setDiscardChangesModalShow(true);
		}
		if (subject === '' && body === '') {
			sendEmailMutation.reset();
		}
		onClose();
	};

	const handleCloseDiscardChanges = () => {
		setDiscardChangesModalShow(false);
		onCloseDiscardChanges();
	};

	const handleSubjectChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		setSubject(target.value);
	};

	const handleBodyChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		setBody(target.value);
	};

	const sendEmail = (e: React.FormEvent) => {
		e.preventDefault();
		sendEmailMutation.mutate({ id: customer.id, subject, body });
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(sendEmailMutation.error, paramName);
	};

	return (
		<>
			<CModal show={show} onClose={handleClose}>
				<CModalHeader closeButton>
					<CModalTitle>{_t('customer.send-email-to', { customer: customer.name })}</CModalTitle>
				</CModalHeader>
				<CForm onSubmit={sendEmail}>
					<CModalBody>
						<CFormGroup>
							<CLabel htmlFor="subject">{_t('emails.subject')}</CLabel>
							<CInput type="text" id="subject" name="subject" value={subject} onChange={handleSubjectChange} />
						</CFormGroup>
						{findError('subject') && <CLabel className="text-danger">{findError('subject')}</CLabel>}
						<CFormGroup>
							<CLabel htmlFor="body">{_t('emails.body')}</CLabel>
							<Textarea
								className="w-100 mt-2 comments-input"
								onChange={handleBodyChange}
								value={body}
								placeholder={_t('customer.email-placeholder')}
								rows={3}
							/>
						</CFormGroup>
						{findError('body') && <CLabel className="text-danger">{findError('body')}</CLabel>}
					</CModalBody>
					<CModalFooter>
						<ButtonWithLoader
							isLoading={sendEmailMutation.isLoading}
							type="submit"
							buttonColor="primary"
							spinnerColor="secondary"
							title={_t.translate('action.send')}
							className="mr-2"
						/>
						<CButton color="light" variant="outline" onClick={handleClose} disabled={sendEmailMutation.isLoading}>
							{_t('global.cancel')}
						</CButton>
					</CModalFooter>
				</CForm>
			</CModal>
			<CModal show={discardChangesModalShow} size="sm" closeOnBackdrop={false}>
				<CModalHeader className="d-flex flex-column">
					<CModalTitle>{_t('customer.discard-changes')}</CModalTitle>
				</CModalHeader>
				<CModalBody />
				<CModalFooter className="modal-footer--alternative">
					<CButton color="primary" onClick={close}>
						{_t('global.confirm')}
					</CButton>
					<CButton color="light" variant="outline" onClick={handleCloseDiscardChanges}>
						{_t('global.cancel')}
					</CButton>
				</CModalFooter>
			</CModal>
		</>
	);
};

export default React.memo(EmailCustomerModal);
