import React, { useState, useEffect } from 'react';
import _t from 'counterpart';
import { CForm, CRow, CCol, CFormGroup, CButton } from '@coreui/react';
import { useQuery } from 'react-query';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { IDateRange, ILeadsFilters } from '../../reducers/filtersReducer';
import { setLeadsFilters, showErrorToast } from '../../actions';
import { fetchSources, getSalesStatuses, listSalesPeople, loadCountries } from '../../services/BackendService';
import { UserRole } from '../../reducers/userReducer';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import ToggleSelect, { ISelectOption as ToggleSelectOption } from '../../components/ToggleSelect';
import AssigneFilter from './AssigneeFilter';
import { shallowEqual } from 'react-redux';
import InputHeader from '../../../src/components/InputHeader';
import { extractErrorMessage } from '../../../src/helpers';
import DateRangePicker from '../../../src/components/DateRangePicker';

interface IProps {
	show: boolean;
	onFilterChanged: () => void;
	roles: Array<UserRole>;
}

const LeadsFilter = ({ show, onFilterChanged, roles }: IProps) => {
	const [salesPeopleInput, setSalesPeopleInput] = useState<string>('');
	const [sourcesInput, setSourcesInput] = useState<string>('');
	const [selectAllSalesPeople, setSelectAllSalesPeople] = useState<boolean>(false);
	const [selectAllSources, setSelectAllSources] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const activeFilters = useAppSelector((state) => state.filters.leadsFilters, shallowEqual);

	const isAdmin = roles.includes(UserRole.Admin);
	const salesPeopleQuery = useQuery(['filter-sales-people'], () => listSalesPeople(), {
		enabled: false,
	});

	const { data: countries }: { data: any } = useQuery<Array<ISelectOption>, Error>(
		['countries'],
		async () => {
			const data = await loadCountries();
			return data.map((country) => ({
				value: country.isoCode,
				label: country.name,
			}));
		},
		{
			onError: (e) => {
				const error = extractErrorMessage(e);
				dispatch(showErrorToast(error));
			},
			retry: false,
		}
	);

	const sourcesQuery = useQuery(['filter-sources'], () => fetchSources());
	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses());
	const setFilters = (filters: Partial<ILeadsFilters>) => {
		dispatch(setLeadsFilters(filters));
		onFilterChanged();
	};

	useEffect(() => {
		if (salesPeopleQuery.isSuccess && salesPeopleQuery.data) {
			const unassignedValue = {
				label: _t('leads.unassigned'),
				value: 'null',
				enabled: false,
			};

			const salesPeople = salesPeopleQuery.data;

			// -1 because activeFilters.salesPeople will always +1 because of unassignedValue
			if (activeFilters.salesPeople.length - 1 !== salesPeople.length && isAdmin) {
				const mappedValues = salesPeople.map((s: any) => {
					const found = activeFilters.salesPeople.find((c) => c.value === s.id);
					return {
						label: `${s.firstname} ${s.lastname}`,
						value: s.id,
						enabled: found?.enabled || false,
					};
				});
				setFilters({
					salesPeople: [unassignedValue, ...mappedValues],
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salesPeopleQuery.data]);

	useEffect(() => {
		if (sourcesQuery.isSuccess && sourcesQuery.data) {
			const sources = sourcesQuery.data;
			if (activeFilters.sources.length !== sources.length) {
				const mappedValues = sources.map((s: any) => {
					const found = activeFilters.salesPeople.find((c) => c.value === s.id);
					return {
						label: s,
						value: s,
						enabled: found?.enabled || false,
					};
				});
				setFilters({
					sources: mappedValues,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourcesQuery.data]);

	const statuses = salesStatusesQuery.data?.map((status) => {
		return {
			value: status.status,
			label: status.status,
		};
	});

	statuses?.push({ value: 'unassigned', label: 'Unassigned' });

	const handleStatusChanged = (statuses: any) => {
		setFilters({
			statuses,
		});
	};

	const resetSelectedStatuses = () => {
		setFilters({
			statuses: [],
		});
	};

	const onSalesPeopleChanged = (options: Array<ToggleSelectOption>) => {
		setFilters({
			salesPeople: options,
		});
	};

	const onSourcesChanged = (options: Array<ToggleSelectOption>) => {
		setFilters({
			sources: options,
		});
	};

	const handleCountryChanged = (country: any) => {
		setFilters({
			country,
		});
	};

	const resetSalesPeople = () => {
		setSelectAllSalesPeople(false);
		setFilters({
			salesPeople: activeFilters.salesPeople.map((s) => {
				return {
					...s,
					enabled: false,
				};
			}),
			onlyAssignedToMe: false,
		});
	};

	const resetCountry = () => {
		setFilters({
			country: undefined,
		});
	};

	const resetSources = () => {
		setSelectAllSources(false);
		setFilters({
			sources: activeFilters.sources.map((s) => {
				return {
					...s,
					enabled: false,
				};
			}),
		});
	};

	const resetLastModifiedAtRange = () => {
		setFilters({
			modifiedAfter: null,
			modifiedBefore: null,
		});
	};

	const handleLastModifiedAtChanged = (range: IDateRange) => {
		const { startDate, endDate } = range;
		if (startDate && endDate) {
			setFilters({
				modifiedBefore: endDate,
				modifiedAfter: startDate,
			});
		}
	};

	const setOnlyAssignedToMe = (assigned: boolean) => {
		setFilters({
			onlyAssignedToMe: assigned,
		});
	};
	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSelectedStatuses}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={activeFilters.statuses ?? null}
							options={statuses}
							onChange={handleStatusChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>

				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('leads.sources')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSources}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<ToggleSelect
							options={activeFilters.sources ?? null}
							onOptionsChanged={onSourcesChanged}
							inputValue={sourcesInput}
							onInputValueChanged={setSourcesInput}
							selectAll={selectAllSources}
							onSelectChanged={setSelectAllSources}
							className="customer-toggle-select"
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('customers.filters.country')} resetFilter={resetCountry} />
						<CustomSelect
							value={activeFilters.country ?? null}
							options={countries}
							onChange={handleCountryChanged}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<InputHeader labelText={_t('leads.last-modified-date')} resetFilter={resetLastModifiedAtRange} />
						<DateRangePicker
							initialStartDate={activeFilters.modifiedAfter ?? null}
							initialEndDate={activeFilters.modifiedBefore ?? null}
							onRangeChanged={handleLastModifiedAtChanged}
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('leads.assignee')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSalesPeople}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<AssigneFilter
							options={activeFilters.salesPeople ?? null}
							onOptionsChanged={onSalesPeopleChanged}
							inputValue={salesPeopleInput}
							onInputValueChanged={setSalesPeopleInput}
							selectAll={selectAllSalesPeople}
							onSelectAllChanged={setSelectAllSalesPeople}
							onlyAssignedToMe={activeFilters.onlyAssignedToMe}
							onOnlyAssignedToMeChanged={setOnlyAssignedToMe}
							className="customer-toggle-select"
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default React.memo(LeadsFilter);
