import React, { useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CDataTable, CButton, CCardHeader } from '@coreui/react';
import _t from 'counterpart';
import { loadBonusConfigs, deleteBonusConfig } from '../../services/BackendService';
import { useQuery, useMutation } from 'react-query';
import { IBonus } from './types';
import CIcon from '@coreui/icons-react';
import CreateUpdateBonusModal from './CreateUpdateBonusModal';
import { formatDateTime, formatCurrency, extractErrorMessage } from '../../helpers';
import ConfirmationModal from '../../components/ConfirmationModal';
import PageLayout from '../../components/PageLayout';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast } from '../../actions';

const BonusesPage = () => {
	const [addBonusShow, setAddBonus] = useState<boolean>(false);
	const [deleteBonusId, setDeleteBonusId] = useState<string | null>(null);
	const [currentBonus, setCurrentBonus] = useState<IBonus | null>(null);
	const bonusConfigQuery = useQuery<Array<IBonus>>(['bonuses'], () => loadBonusConfigs());

	const dispatch = useAppDispatch();

	const deleteBonusMutation = useMutation(() => deleteBonusConfig(deleteBonusId!), {
		onSuccess: () => {
			bonusConfigQuery.refetch();
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const hideModal = (refetchOnClose?: boolean) => {
		setAddBonus(false);
		setCurrentBonus(null);
		if (refetchOnClose) {
			bonusConfigQuery.refetch();
		}
	};

	const hideDeleteModal = (fetchAgain: boolean) => {
		if (fetchAgain) {
			bonusConfigQuery.refetch();
		}
		setDeleteBonusId(null);
	};

	const onErrorRetry = () => {
		bonusConfigQuery.refetch();
	};

	if (bonusConfigQuery.isLoading || bonusConfigQuery.isIdle) {
		return <Loading />;
	}

	if (bonusConfigQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('bonuses.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={() => setAddBonus(true)} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								fields={[
									{ key: 'id', label: _t.translate('bonuses.name'), sorter: true },
									{ key: 'type', label: _t.translate('global.type'), sorter: false },
									{ key: 'start', label: _t.translate('bonuses.start'), sorter: true },
									{ key: 'end', label: _t.translate('bonuses.end'), sorter: true },
									{ key: 'amount', label: _t.translate('bonuses.amount'), sorter: true },
									{ key: 'minimumDepositUSD', label: _t.translate('bonuses.min-deposit'), sorter: false },
									{ key: 'actions', label: _t.translate('global.actions'), sorter: false },
								]}
								scopedSlots={{
									name: (bonus: IBonus) => <td>{bonus.id}</td>,
									start: (bonus: IBonus) => <td className="text-nowrap">{formatDateTime(bonus.start)}</td>,
									end: (bonus: IBonus) => <td className="text-nowrap">{formatDateTime(bonus.end)}</td>,
									amount: (bonus: IBonus) => <td className="text-nowrap">{formatCurrency(bonus.amount)}</td>,
									minimumDepositUSD: (bonus: IBonus) => (
										<td className="text-nowrap">
											{bonus.minimumDepositUSD ? formatCurrency(bonus.minimumDepositUSD!) : '-'}
										</td>
									),
									actions: (bonus: IBonus) => (
										<td>
											<CButton className="mr-2" size="sm" color="primary" onClick={() => setCurrentBonus(bonus)}>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											<CButton className="mr-2" size="sm" color="danger" onClick={() => setDeleteBonusId(bonus.id)}>
												<CIcon name="cil-trash" size="sm" />
											</CButton>
										</td>
									),
								}}
								items={bonusConfigQuery.data || []}
								loading={bonusConfigQuery.isLoading}
								sorterValue={{ column: 'name', asc: false }}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			{(addBonusShow || Boolean(currentBonus)) && (
				<CreateUpdateBonusModal
					show={addBonusShow || Boolean(currentBonus)}
					hideModal={hideModal}
					currentBonus={currentBonus}
				/>
			)}

			<ConfirmationModal
				id={deleteBonusId || ''}
				show={Boolean(deleteBonusId)}
				hidePanel={hideDeleteModal}
				onConfirm={deleteBonusMutation.mutate}
				title={_t('bonuses.delete-bonus')}
				error={null}
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default BonusesPage;
