import * as Sentry from '@sentry/react';
import { setSocketConnected } from '../actions';
import { store } from '../store';

type ListenerFunction = (event: any) => void;

const RECONNECTION_LIMIT = 3;

class WebsocketService {
	client: WebSocket | null;

	connectionOpen = false;

	positionListeners: Array<ListenerFunction> = [];

	accountListeners: Array<ListenerFunction> = [];

	reconnectionAttempts = 0;

	receivedHeartbeatTime: Date | null = null;

	constructor() {
		this.client = null;
	}

	connect = () => {
		if (!process.env.REACT_APP_REPORTING_WS_ROOT) {
			throw Error('REACT_APP_REPORTING_WS_ROOT ENV var is required');
		}
		this.client = new WebSocket(process.env.REACT_APP_REPORTING_WS_ROOT);
		this.client.onopen = this.onOpen;
		this.client.onclose = this.onClose;
		this.client.onerror = this.onError;
		this.client.onmessage = this.onMessage;
	};

	waitForConnection = (callback: Function, interval: number) => {
		if (this.client?.readyState === 1) {
			callback();
		} else {
			setTimeout(() => {
				this.waitForConnection(callback, interval);
			}, interval);
		}
	};

	send = (data: any) => {
		this.waitForConnection(() => {
			this.client?.send(data);
		}, 1000);
	};

	sendJSON = (data: Object) => {
		this.send(JSON.stringify(data));
	};

	closeConnection = () => {
		this.client?.close();
	};

	onOpen = () => {
		this.connectionOpen = true;
		store.dispatch(setSocketConnected(true));
		this.checkHeartbeatDiff();
	};

	checkHeartbeatDiff = () => {
		setInterval(() => {
			if (this.receivedHeartbeatTime !== null) {
				const seconds = (new Date().getTime() - this.receivedHeartbeatTime.getTime()) / 1000;
				if (seconds > 9) {
					this.connectionOpen = false;
					store.dispatch(setSocketConnected(false));
				} else {
					store.dispatch(setSocketConnected(true));
				}
			}
		}, 1000);
	};

	onClose = (event: CloseEvent) => {
		this.connectionOpen = false;
		if (!event.wasClean && this.reconnectionAttempts < RECONNECTION_LIMIT) {
			setTimeout(() => {
				this.reconnectionAttempts += 1;
				this.connect();
			}, 200);
		}
		store.dispatch(setSocketConnected(false));
	};

	onError = () => {
		this.closeConnection();
	};

	onMessage = (event: MessageEvent) => {
		if (event.data === 'Pong') {
			this.receivedHeartbeatTime = new Date();
		}

		try {
			const message = JSON.parse(event.data);
			if (['PositionStatus', 'PositionOpen', 'PositionClose'].includes(message.messageType)) {
				this.positionListeners.forEach((callback) => callback(message));
			}
			if (['AccountStatus'].includes(message.messageType)) {
				this.accountListeners.forEach((callback) => callback(message));
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	};

	subscribeToPositionUpdates = (token: string) => {
		this.sendJSON({
			messageType: 'Subscribe',
			topic: 'positions',
			auth: token,
		});
	};

	subscribeToAccountsUpdates = (token: string) => {
		this.sendJSON({
			messageType: 'Subscribe',
			topic: 'accounts',
			auth: token,
		});
	};

	unsubscribeFromAccountsUpdates = () => {
		this.sendJSON({
			messageType: 'Unsubscribe',
			topic: 'accounts',
		});
	};

	sendPing = (token: string) => {
		this.sendJSON({
			messageType: 'Ping',
			topic: 'heartbeat',
			auth: token,
		});
	};

	unsubscribeFromPositionUpdates = () => {
		this.sendJSON({
			messageType: 'Unsubscribe',
			topic: 'positions',
		});
	};

	addPositionsListener = (callback: ListenerFunction) => {
		this.positionListeners.push(callback);
	};

	removePositionsListener = (callback: ListenerFunction) => {
		const index = this.positionListeners.indexOf(callback);
		if (index >= 0) {
			this.positionListeners.splice(index, 1);
		}
	};

	addAccountsListener = (callback: ListenerFunction) => {
		this.accountListeners.push(callback);
	};

	removeAccountsListener = (callback: ListenerFunction) => {
		const index = this.accountListeners.indexOf(callback);
		if (index >= 0) {
			this.accountListeners.splice(index, 1);
		}
	};
}

const wsService = new WebsocketService();
export default wsService;
