import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CAlert } from '@coreui/react';
import _t from 'counterpart';
import { useMutation, useQueryClient } from 'react-query';
import { useAppDispatch } from '../helpers/customHooks';
import { showErrorToast } from '../actions';
import { extractErrorMessage } from '../helpers';
import ButtonWithLoader from './ButtonWithLoader';

interface IProps {
	id: string;
	show: boolean;
	hidePanel: Function;
	onConfirm: Function;
	title: string;
	error: string | null;
	buttonColor: string;
	withMutation: boolean;
	queryCacheKeyToInvalidate?: string;
	isLoading?: boolean;
}

const ConfirmationModal = ({
	id,
	show,
	hidePanel,
	onConfirm,
	title,
	error,
	buttonColor,
	withMutation,
	queryCacheKeyToInvalidate,
	isLoading = false
}: IProps) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const mutation = useMutation(['mutation'], () => onConfirm(id), {
		onSuccess: () => {
			hidePanel(true);
			if (queryCacheKeyToInvalidate) {
				queryClient.invalidateQueries(queryCacheKeyToInvalidate);
			}
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const confirm = () => {
		if (withMutation) {
			mutation.mutate();
		} else {
			onConfirm();
		}
	};

	const loading = withMutation ? mutation.isLoading : isLoading;

	return (
		<CModal show={show} onClose={hidePanel} size="sm">
			<CModalHeader className="d-flex flex-column">
				{error && <CAlert color="danger">{error}</CAlert>}
				<CModalTitle> {title}</CModalTitle>
			</CModalHeader>
			<CModalBody />
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={loading}
					onClick={confirm}
					buttonColor={buttonColor}
					spinnerColor="secondary"
					title={_t.translate('global.confirm')}
				/>
				<CButton color="light" variant="outline" onClick={hidePanel} disabled={loading}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

ConfirmationModal.defaultProps = {
	queryCacheKeyToInvalidate: undefined,
};

export default ConfirmationModal;
