import React, { useState } from 'react';
import { ICustomerFieldConfigurations } from './types';
import {
	CButton,
	CForm,
	CFormGroup,
	CInputGroup,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import CustomSelect from '../../components/CustomSelect';
import _t from 'counterpart';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { updateFieldConfiguration } from '../../services/BackendService';
import { useMutation } from 'react-query';
import { useAppDispatch } from '../../helpers/customHooks';
import { extractErrorMessage } from '../../helpers';
import { showSuccessToast, showErrorToast } from '../../actions';

interface IProps {
	customerFieldConfig: ICustomerFieldConfigurations | null;
	hideModal: () => void;
}

const EditCustomerFieldConfiguration = ({ hideModal, customerFieldConfig }: IProps) => {
	const options = [
		{ value: 'SignupRealForm', label: 'SignupRealForm' },
		{ value: 'CompleteAccountBeforeDepositForm', label: 'CompleteAccountBeforeDepositForm' },
		{ value: 'CompleteAccountBeforeWithdrawalForm', label: 'CompleteAccountBeforeWithdrawalForm' },
	];

	const currentStep = options.find((o) => o.value === customerFieldConfig!.step) || options[0];
	const [step, setStep] = useState<any>(currentStep);
	const [required, setRequired] = useState<boolean>(customerFieldConfig?.required || false);
	const [editable, setEditable] = useState<boolean>(customerFieldConfig?.editable || false);

	const dispatch = useAppDispatch();

	const updateFieldMutation = useMutation(
		['onboardin-steps-update'],
		() => updateFieldConfiguration(customerFieldConfig!.field, editable, required, step?.value ?? null),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t.translate('onboarding.success-edit-config')));
				hideModal();
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const toggleRequired = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement;
		const { checked } = target;

		setRequired(checked);
	};

	const toggleEditable = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement;
		const { checked } = target;

		setEditable(checked);
	};

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();
		updateFieldMutation.mutate();
	};

	return (
		<CModal show onClose={hideModal}>
			<CModalHeader closeButton className="d-flex align-items-center flex-column">
				<CModalTitle>{_t.translate('onboarding.title')}</CModalTitle>
				{customerFieldConfig?.field}
			</CModalHeader>
			<CForm onSubmit={submitForm}>
				<CModalBody>
					<CFormGroup>
						<CLabel>{_t.translate('onboarding.step')}</CLabel>
						<CInputGroup>
							<CustomSelect
								className="w-100"
								options={options}
								value={step}
								onChange={setStep}
								whiteBackground
								isClearable
							/>
						</CInputGroup>
					</CFormGroup>
					<CFormGroup>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="checkbox"
								id="is-demo"
								onChange={toggleRequired}
								checked={required}
							/>
							<span>{_t.translate('onboarding.required')}</span>
						</div>
					</CFormGroup>
					<CFormGroup>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="checkbox"
								id="is-demo"
								onChange={toggleEditable}
								checked={editable}
							/>
							<span>{_t.translate('onboarding.editable')}</span>
						</div>
					</CFormGroup>
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={updateFieldMutation.isLoading}
						type="submit"
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t.translate('action.update')}
						className="mr-2"
					/>
					<CButton color="light" variant="outline" onClick={hideModal}>
						{_t.translate('action.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
};

export default EditCustomerFieldConfiguration;
