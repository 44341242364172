import {
	CButton,
	CForm,
	CFormGroup,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import _t from 'counterpart';
import React, { FormEvent, useState } from 'react';
import { ICommissionSchemeCreate, ICommissionSchemeFull } from './types';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { createCommissionScheme, updateCommissionScheme, loadCommissionSchemes } from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import CustomSelect from '../../components/CustomSelect';
import ButtonWithLoader from '../../components/ButtonWithLoader';

interface IProps {
	commissionScheme: ICommissionSchemeFull | null;
	hideModal: () => void;
}

interface IFormState {
	name: string;
	nameError: string | null;
	commissionLevel1: string;
	commissionLevel1Error: string | null;
	commissionLevel2: string;
	commissionLevel3: string;
	commissionLevel4: string;
	commissionLevel5: string;
	subCommissionSchemeId: number | null;
	greyLabelingEnabled: boolean;
}

export function AddEditCommissionSchemeModal({ commissionScheme, hideModal }: IProps) {
	const [formState, setFormState] = useState<IFormState>({
		name: commissionScheme?.name ?? '',
		nameError: null,
		commissionLevel1: commissionScheme?.coinRolloverCommissionsUSD[0]
			? commissionScheme?.coinRolloverCommissionsUSD[0].toString()
			: '',
		commissionLevel1Error: null,
		commissionLevel2: commissionScheme?.coinRolloverCommissionsUSD[1]
			? commissionScheme?.coinRolloverCommissionsUSD[1].toString()
			: '',
		commissionLevel3: commissionScheme?.coinRolloverCommissionsUSD[2]
			? commissionScheme?.coinRolloverCommissionsUSD[2].toString()
			: '',
		commissionLevel4: commissionScheme?.coinRolloverCommissionsUSD[3]
			? commissionScheme?.coinRolloverCommissionsUSD[3].toString()
			: '',
		commissionLevel5: commissionScheme?.coinRolloverCommissionsUSD[4]
			? commissionScheme?.coinRolloverCommissionsUSD[4].toString()
			: '',
		subCommissionSchemeId: commissionScheme?.subCommissionScheme?.id || null,
		greyLabelingEnabled: commissionScheme?.greyLabelingEnabled || false,
	});

	const queryClient = useQueryClient();

	const addCommissionSchemeMutation = useMutation(
		(commissionScheme: ICommissionSchemeCreate) => createCommissionScheme(commissionScheme),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries('commission-schemes');
				hideModal();
			},
		}
	);

	const editCommissionSchemeMutation = useMutation(
		(commissionScheme: ICommissionSchemeFull) => updateCommissionScheme(commissionScheme),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries('commission-schemes');
				hideModal();
			},
		}
	);

	const {
		isLoading,
		error,
		data: subCommissionSchemesData,
	} = useQuery<any, Error>(['commission-schemes'], loadCommissionSchemes);

	const handleSubCommissionChanged = (subCommission: any) => {
		setFormState({ ...formState, subCommissionSchemeId: subCommission.value });
	};

	const subCommissionOptions = [];
	subCommissionOptions.push({ label: 'inherit', value: null });
	subCommissionSchemesData
		?.filter((cs: any) => cs.id !== commissionScheme?.id)
		.forEach((cs: any) => {
			subCommissionOptions.push({ label: cs.name, value: cs.id });
		});

	const isEditing = Boolean(commissionScheme);

	const handleInputChange = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setFormState({
			...formState,
			[name]: value,
		});
	};

	const handleGreyLabelingEnabledChanged = () => {
		const enabled = !formState.greyLabelingEnabled;
		setFormState({
			...formState,
			greyLabelingEnabled: enabled,
		});
	};

	const commissionLevelsToArray = () => {
		const { commissionLevel1, commissionLevel2, commissionLevel3, commissionLevel4, commissionLevel5 } = formState;

		const commissionValues = [commissionLevel1, commissionLevel2, commissionLevel3, commissionLevel4, commissionLevel5];
		return commissionValues.reduce((acc: Array<number>, item: string) => {
			if (item.trim().length > 0) {
				acc.push(Number(item));
			}
			return acc;
		}, []);
	};

	const clearFormErrors = () => {
		setFormState({
			...formState,
			nameError: null,
			commissionLevel1Error: null,
		});
	};

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();
		clearFormErrors();
		const { name, commissionLevel1, subCommissionSchemeId, greyLabelingEnabled } = formState;

		let hasErrors = false;
		let nameError = null;
		let commissionLevel1Error = null;

		if (!name) {
			nameError = 'commission-schemes.errors.name-required';
			hasErrors = true;
		}

		if (!commissionLevel1) {
			commissionLevel1Error = 'commission-schemes.errors.level-1-commission-required';
			hasErrors = true;
		}

		if (hasErrors) {
			setFormState({
				...formState,
				nameError,
				commissionLevel1Error,
			});
		} else if (!isEditing) {
			addCommissionSchemeMutation.mutate({
				name,
				coinRolloverCommissionsUSD: commissionLevelsToArray(),
				subCommissionSchemeId,
				greyLabelingEnabled,
			});
		} else {
			editCommissionSchemeMutation.mutate({
				id: commissionScheme!.id,
				name,
				coinRolloverCommissionsUSD: commissionLevelsToArray(),
				subCommissionSchemeId,
				greyLabelingEnabled,
			});
		}
	};

	const submitButtonLabel = isEditing ? _t('action.update') : _t('action.create');

	const { subCommissionSchemeId } = formState;

	const currentSubCommissionScheme = subCommissionOptions?.find((cs: any) => subCommissionSchemeId === cs.value);

	const loading = addCommissionSchemeMutation.isLoading || editCommissionSchemeMutation.isLoading;

	return (
		<CModal show onClose={hideModal}>
			<CModalHeader closeButton>
				<CModalTitle>
					{isEditing ? _t('commission-schemes.edit-modal-title') : _t('commission-schemes.add-modal-title')}
					{error && (
						<span className="text-danger flex-fill">{extractErrorMessage(addCommissionSchemeMutation.error)}</span>
					)}
				</CModalTitle>
			</CModalHeader>
			<CForm onSubmit={submitForm}>
				<CModalBody>
					<CFormGroup>
						<CLabel htmlFor="name">{_t('global.name')}</CLabel>
						<CInput
							type="text"
							id="name"
							name="name"
							value={formState.name}
							onChange={handleInputChange}
							placeholder="Default"
							required
							invalid={formState.nameError !== null}
						/>
						{formState.nameError && <div className="text-danger">{_t(formState.nameError)}</div>}
					</CFormGroup>

					<CFormGroup>
						<CLabel>{_t('commission-schemes.level-x', { no: 1 })}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText className="bg-info text-white">$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="commissionLevel1"
								name="commissionLevel1"
								value={formState.commissionLevel1}
								onChange={handleInputChange}
								required
								invalid={formState.commissionLevel1Error !== null}
							/>
						</CInputGroup>
						{formState.commissionLevel1Error && (
							<div className="text-danger">{_t(formState.commissionLevel1Error)}</div>
						)}
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('commission-schemes.level-x', { no: 2 })}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText className="bg-info text-white">$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="commissionLevel2"
								name="commissionLevel2"
								value={formState.commissionLevel2}
								onChange={handleInputChange}
							/>
						</CInputGroup>
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('commission-schemes.level-x', { no: 3 })}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText className="bg-info text-white">$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="commissionLevel3"
								name="commissionLevel3"
								value={formState.commissionLevel3}
								onChange={handleInputChange}
							/>
						</CInputGroup>
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('commission-schemes.level-x', { no: 4 })}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText className="bg-info text-white">$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="commissionLevel4"
								name="commissionLevel4"
								value={formState.commissionLevel4}
								onChange={handleInputChange}
							/>
						</CInputGroup>
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('commission-schemes.level-x', { no: 5 })}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText className="bg-info text-white">$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="commissionLevel5"
								name="commissionLevel5"
								value={formState.commissionLevel5}
								onChange={handleInputChange}
							/>
						</CInputGroup>
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('commission-schemes.sub-commission-scheme')}</CLabel>
						{isLoading && <span>{_t('global.please-wait')}</span>}
						{!isLoading && (
							<CInputGroup>
								<CustomSelect
									options={subCommissionOptions}
									value={currentSubCommissionScheme}
									onChange={handleSubCommissionChanged}
									whiteBackground
									className="w-100"
								/>
							</CInputGroup>
						)}
					</CFormGroup>
					<CFormGroup className="d-flex align-items-center">
						<input
							className="mr-2"
							type="checkbox"
							id="greyLabelingEnabled"
							onChange={handleGreyLabelingEnabledChanged}
							checked={formState?.greyLabelingEnabled}
						/>
						<span>{_t.translate('commission-schemes.grey-labeling-enabled')}</span>
					</CFormGroup>
				</CModalBody>
				<CModalFooter>
					<div className="w-100">
						{addCommissionSchemeMutation.isError && (
							<span className="text-danger">{extractErrorMessage(addCommissionSchemeMutation.error)}</span>
						)}
						{editCommissionSchemeMutation.isError && (
							<span className="text-danger">{extractErrorMessage(editCommissionSchemeMutation.error)}</span>
						)}
					</div>
					<ButtonWithLoader
						isLoading={loading}
						type="submit"
						buttonColor="primary"
						spinnerColor="secondary"
						title={submitButtonLabel}
						className="mr-2"
					/>
					<CButton color="light" variant="outline" onClick={hideModal}>
						{_t('action.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
}
