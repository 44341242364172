import React, { useCallback, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { useMutation, useQuery } from 'react-query';
import { deleteAdapter, fetchAdapters } from '../../services/BackendService';
import { CRow, CCol, CCard, CCardHeader, CButton, CCardBody, CDataTable, CLink } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { IAdapter } from './types';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../actions';
import ConfirmationModal from '../../components/ConfirmationModal';
import { extractErrorMessage } from '../../helpers';
import BooleanBadge from '../../components/BooleanBadge';

const AdaptersPage = () => {
	const [toDelete, setToDelete] = useState<IAdapter | null>(null);

	const history = useHistory();
	const dispatch = useDispatch();

	const adaptersQuery = useQuery(['adapters'], () => fetchAdapters());

	const deleteAdapterMutation = useMutation((name: string) => deleteAdapter(name), {
		onSuccess: () => {
			dispatch(showSuccessToast(_t('adapters.adapter-deleted-successfully')));
			adaptersQuery.refetch();
			history.push('/adapters');
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const fields = useMemo(
		() => [
			{ key: 'name', label: _t('global.name'), sorter: true },
			{ key: 'enabled', label: _t('adapters.enabled'), sorter: true },
			{ key: 'type', label: _t('global.type'), sorter: true },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[]
	);

	const onAdapterCreateClicked = () => {
		history.push(`/adapters_form`);
	};

	const onAdapterEditClicked = useCallback(
		(adapter: IAdapter) => {
			history.push(`/adapters_form?edit=${encodeURIComponent(adapter.name)}`);
		},
		[history]
	);

	const onAdapterDeleteClicked = useCallback((adapter: IAdapter) => {
		setToDelete(adapter);
	}, []);

	const slots = useMemo(
		() => ({
			name: (adapter: IAdapter) => (
				<td>
					<CLink href={`/adapters/${adapter.name}`}>{adapter.name}</CLink>
				</td>
			),
			enabled: (adapter: IAdapter) => (
				<td>
					<BooleanBadge value={adapter.enabled} />
				</td>
			),
			actions: (adapter: IAdapter) => (
				<td>
					<CButton size="sm" className="mr-1" color="primary" onClick={() => onAdapterEditClicked(adapter)}>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
					<CButton size="sm" className="mr-1" color="danger" onClick={() => onAdapterDeleteClicked(adapter)}>
						<CIcon name="cil-trash" size="sm" />
					</CButton>
				</td>
			),
		}),
		[onAdapterEditClicked, onAdapterDeleteClicked]
	);

	const onDeleteAdapterModalClose = () => {
		setToDelete(null);
	};

	const invokeDeletMutation = (name: string) => {
		deleteAdapterMutation.mutate(name);
	};

	if (adaptersQuery.isLoading || adaptersQuery.isIdle) {
		return <Loading />;
	}

	if (adaptersQuery.isError) {
		return <Error onRetry={adaptersQuery.refetch} />;
	}

	const { data } = adaptersQuery;

	return (
		<PageLayout title={_t('adapters.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div className="float-right">
								<CButton className="mr-2 header-button-add" onClick={onAdapterCreateClicked} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CDataTable fields={fields} items={data || []} scopedSlots={slots} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<ConfirmationModal
				id={toDelete?.name || ''}
				show={Boolean(toDelete)}
				hidePanel={onDeleteAdapterModalClose}
				onConfirm={invokeDeletMutation}
				title={_t('adapters.delete-adapter')}
				error={null}
				buttonColor="danger"
				withMutation
			/>
		</PageLayout>
	);
};

export default React.memo(AdaptersPage);
