import {
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CForm,
	CFormGroup,
	CLabel,
	CInputGroup,
	CModalFooter,
	CButton,
	CFormText,
} from '@coreui/react';
import React, { useState } from 'react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { IOnboardingStepConfiguration } from './types';
import _t from 'counterpart';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOnboardingStepsStages, updateOnboardingStepStage } from '../../services/BackendService';
import { useAppDispatch } from '../../helpers/customHooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { showErrorToast, showSuccessToast } from '../../actions';
import LoadingModal from '../../components/LoadingModal';
import ErrorModal from '../../components/ErrorModal';

interface IProps {
	onboardingStepConfig: IOnboardingStepConfiguration | null;
	hideModal: () => void;
}

interface IMutationParams {
	step: string;
	stage: string;
}

const EditOnboardingStepConfiguration = ({ onboardingStepConfig, hideModal }: IProps) => {
	const [options, setOptions] = useState<Array<ISelectOption>>([]);
	const [stage, setStage] = useState<any>(null);

	const queryClient = useQueryClient();

	const dispatch = useAppDispatch();
	const onboardingStagesQuery = useQuery(['onboarding-stages'], () => getOnboardingStepsStages(), {
		onSuccess: (stages: Array<string>) => {
			const mappedOptions = stages.map((s) => {
				return {
					value: s,
					label: s,
				};
			});
			setOptions(mappedOptions);

			const initialStage = {
				value: onboardingStepConfig?.stage,
				label: onboardingStepConfig?.stage,
			};

			setStage(onboardingStepConfig?.stage ? initialStage : null);
		},
		onError: (e: any) => {
			const message = extractErrorMessage(e);
			dispatch(showErrorToast(message));
		},
		retry: false,
	});

	const updateOnboardingStepMutation = useMutation(
		({ step, stage }: IMutationParams) => updateOnboardingStepStage(step, stage),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['onboarding-steps-configurations']);
				dispatch(showSuccessToast(_t('onboarding.onboarding-step-configuration-update-successfully')));
				hideModal();
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const errMsg = extractErrorMessage(e);
					dispatch(showErrorToast(errMsg));
				}
			},
		}
	);

	if (onboardingStagesQuery.isLoading || onboardingStagesQuery.isIdle) {
		return <LoadingModal onClose={hideModal} title={_t('onboarding.title')} />;
	}

	if (onboardingStagesQuery.isError) {
		return <ErrorModal onClose={hideModal} title={_t('onboarding.title')} onRetry={onboardingStagesQuery.refetch} />;
	}

	const invokeMutation = (e: React.FormEvent) => {
		e.preventDefault();
		if (onboardingStepConfig) {
			updateOnboardingStepMutation.mutate({
				step: onboardingStepConfig?.step,
				stage: stage?.value || null,
			});
		}
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updateOnboardingStepMutation.error, paramName);
	};

	return (
		<CModal show onClose={hideModal}>
			<CModalHeader closeButton className="d-flex align-items-center flex-column">
				<CModalTitle>{_t('onboarding.title')}</CModalTitle>
				{onboardingStepConfig?.step}
			</CModalHeader>
			<CForm onSubmit={invokeMutation}>
				<CModalBody>
					<CFormGroup>
						<CLabel>{_t('onboarding.stage')}</CLabel>
						<CInputGroup>
							<CustomSelect
								className="w-100"
								options={options}
								value={stage}
								onChange={setStage}
								whiteBackground
								isClearable
							/>
						</CInputGroup>
						{findError('stage') && <CLabel className="text-danger">{findError('stage')}</CLabel>}
						<CFormText className="help-block">{_t('onboarding.leave-as-blank')}</CFormText>
					</CFormGroup>
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={updateOnboardingStepMutation.isLoading}
						type="submit"
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t('action.update')}
						className="mr-2"
					/>
					<CButton
						color="light"
						variant="outline"
						onClick={hideModal}
						disabled={updateOnboardingStepMutation.isLoading}
					>
						{_t('action.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
};

export default React.memo(EditOnboardingStepConfiguration);
