import React, { useEffect, useState } from 'react';
import _t from 'counterpart';
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CFormGroup,
	CInput,
	CInputGroup,
	CLabel,
	CRow,
} from '@coreui/react';
import { fetchMaxRisk, updateMaxRiskConfig } from '../../services/BackendService';
import { useMutation, useQuery } from 'react-query';
import CIcon from '@coreui/icons-react';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../actions';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IIndexValue } from './types';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';

interface IMutationParams {
	value: Array<string>;
	defaultValue: string;
}

const MaxRisks = () => {
	const [configs, setConfigs] = useState<Array<IIndexValue>>([]);
	const [defaultValue, setDefaultValue] = useState<ISelectOption | null>();

	const fetchMaxRiskQuery = useQuery(['max-risk'], () => fetchMaxRisk());

	const dispatch = useDispatch();

	const updateMaxRiskMutation = useMutation(
		({ value, defaultValue }: IMutationParams) => updateMaxRiskConfig(value, defaultValue),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('max-risk.max-risk-config-updated-successfully')));
			},
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				dispatch(showErrorToast(msg));
			},
			retry: false,
		}
	);

	useEffect(() => {
		if (fetchMaxRiskQuery.isSuccess && fetchMaxRiskQuery.data) {
			const { maxRisks, defaultValue } = fetchMaxRiskQuery.data;
			const configsFromQuery = maxRisks.map((r, index) => {
				return {
					index,
					value: r,
				};
			});
			setConfigs(configsFromQuery);
			setDefaultValue({ value: defaultValue, label: defaultValue });
		}
	}, [fetchMaxRiskQuery.isSuccess, fetchMaxRiskQuery.data]);

	const onRiskValueChange = (index: number, value: string) => {
		const configsArray = [...configs];
		configsArray[index] = { index, value };
		setConfigs(configsArray);
		if (index === 0 && configsArray.length === 1) {
			handleDefaultChanged({ value, label: value });
		}
	};

	const onRemoveValue = (index: number) => {
		const configsArray = [...configs];
		configsArray.splice(index, 1);
		configsArray.forEach((risk: IIndexValue, index: number) => {
			configsArray[index] = {
				index,
				value: risk.value,
			};
		});
		setConfigs(configsArray);
		const firstValue = configsArray[0];
		handleDefaultChanged({ value: firstValue.value, label: firstValue.value });
	};

	const addValue = () => {
		const configsArray = [...configs];
		configsArray.push({
			index: configsArray.length,
			value: '',
		});
		setConfigs(configsArray);
	};

	const updateMaxRisks = () => {
		const value = configs.map((c) => c.value);
		const defaultVal = defaultValue?.value || configs[0].value;
		updateMaxRiskMutation.mutate({ value, defaultValue: defaultVal });
	};

	const handleDefaultChanged = (value: any) => {
		setDefaultValue(value);
	};

	const defaultOptions = configs
		.filter((c: IIndexValue) => c.value !== '')
		.map((c: IIndexValue) => {
			return { value: c.value, label: c.value };
		});

	return (
		<CRow>
			<CCol>
				<CCard style={{ overflow: 'visible' }}>
					<CCardHeader>
						<CCardTitle>{_t('max-risk.max-risk-options')}</CCardTitle>
					</CCardHeader>
					<CCardBody>
						{(fetchMaxRiskQuery.isLoading || fetchMaxRiskQuery.isIdle) && <Loading />}
						{fetchMaxRiskQuery.isError && <Error onRetry={fetchMaxRiskQuery.refetch} />}

						{configs.map((c: IIndexValue) => (
							<div key={c.index} className="d-flex mt-2">
								<CInput
									type="number"
									placeholder={Math.max(1, c.index * 10).toString()}
									label="test"
									value={c.value}
									onChange={(e: any) => onRiskValueChange(c.index, e.target.value)}
									className="mr-2 border-fix"
									key={c.index}
								/>
								<CButton
									className="instrument-button"
									color="danger"
									onClick={() => onRemoveValue(c.index)}
									disabled={c.index == 0 && configs.length === 1}
								>
									<CIcon name="cil-trash" />
								</CButton>
							</div>
						))}
						<CFormGroup className="mt-2 mb-2">
							<CLabel htmlFor="maxRiskDefaultValue">{_t('global.default-value')}</CLabel>
							<CInputGroup>
								<CustomSelect
									name="maxRiskDefaultValue"
									value={defaultValue}
									options={defaultOptions}
									onChange={handleDefaultChanged}
									whiteBackground
									fullWidth
								/>
							</CInputGroup>
							{findErrorFromValidation(updateMaxRiskMutation.error, 'defaultValue') && (
								<CLabel className="text-danger">
									{findErrorFromValidation(updateMaxRiskMutation.error, 'defaultValue')}
								</CLabel>
							)}
						</CFormGroup>
						{!fetchMaxRiskQuery.isError && !fetchMaxRiskQuery.isLoading && (
							<>
								<CButton
									onClick={addValue}
									className="instrument-buttons mt-2 mr-2"
									color="success"
									disabled={fetchMaxRiskQuery.isLoading}
								>
									{_t('max-risk.add-new-max-risk')}
								</CButton>
								<ButtonWithLoader
									isLoading={updateMaxRiskMutation.isLoading}
									onClick={updateMaxRisks}
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t('action.update')}
									className="instrument-buttons mt-2"
									disabled={configs.length === 1 && configs[0].value === ''}
								/>
							</>
						)}
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	);
};

export default React.memo(MaxRisks);
