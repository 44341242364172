import { CRow, CCol, CCard, CCardHeader, CCardBody, CDataTable, CBadge, CButton } from '@coreui/react';
import React, { useMemo, useState } from 'react';
import Loading from '../../components/Loading';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { ICustomerFieldConfigurations } from './types';
import { useQuery } from 'react-query';
import { showErrorToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';
import { getFieldsConfigurations } from '../../services/BackendService';
import { useAppDispatch } from '../../helpers/customHooks';
import Error from '../../components/Error';
import EditCustomerFieldConfiguration from './EditCustomerFieldConfiguration';

const FieldConfiguration = () => {
	const [currentConfig, setCurrentConfig] = useState<ICustomerFieldConfigurations | null>(null);

	const dispatch = useAppDispatch();
	const fieldsConfigsQuery = useQuery<Array<ICustomerFieldConfigurations>>(
		['field-configurations'],
		() => getFieldsConfigurations(),
		{
			onError: (e: any) => {
				const errMsg = extractErrorMessage(e);
				dispatch(showErrorToast(errMsg));
			},
		}
	);

	const setConfigToEdit = (config: ICustomerFieldConfigurations) => {
		setCurrentConfig(config);
	};

	const fields = useMemo(
		() => [
			{ key: 'field', label: _t.translate('onboarding.field') },
			{ key: 'step', label: _t.translate('onboarding.step') },
			{ key: 'required', label: _t.translate('onboarding.required') },
			{ key: 'editable', label: _t.translate('onboarding.editable') },
			{ key: 'actions', label: '' },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			step: (fieldConfig: ICustomerFieldConfigurations) => (
				<td>
					{!fieldConfig.step && <CBadge color="secondary">{_t.translate('global.none')}</CBadge>}
					{fieldConfig && fieldConfig.step}
				</td>
			),
			required: (fieldConfig: ICustomerFieldConfigurations) => (
				<td>
					<CBadge color={fieldConfig.required ? 'success' : 'danger'}>
						{fieldConfig.required ? _t.translate('global.yes') : _t.translate('global.no')}
					</CBadge>
				</td>
			),
			editable: (fieldConfig: ICustomerFieldConfigurations) => (
				<td>
					<CBadge color={fieldConfig.editable ? 'success' : 'danger'}>
						{fieldConfig.editable ? _t.translate('global.yes') : _t.translate('global.no')}
					</CBadge>
				</td>
			),
			actions: (fieldConfig: ICustomerFieldConfigurations) => (
				<td className="table-small_cta">
					<CButton onClick={() => setConfigToEdit(fieldConfig)} size="sm" className="mr-1" color="primary">
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
				</td>
			),
		}),
		[]
	);

	const onErrorRetry = () => {
		fieldsConfigsQuery.refetch();
	};

	const hideEditModal = () => {
		setCurrentConfig(null);
		fieldsConfigsQuery.refetch();
	};

	const loadingFieldConfigs = fieldsConfigsQuery.isLoading || fieldsConfigsQuery.isIdle;
	const errorFieldConfigs = fieldsConfigsQuery.isError;

	return (
		<>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>{_t('onboarding.customer-field-configurations')}</CCardHeader>
						<CCardBody className="simulated-overflow-y">
							{loadingFieldConfigs && <Loading />}
							{errorFieldConfigs && <Error onRetry={onErrorRetry} />}
							{!errorFieldConfigs && !loadingFieldConfigs && (
								<CDataTable
									items={fieldsConfigsQuery.data || []}
									fields={fields}
									scopedSlots={scopedSlots}
									loading={fieldsConfigsQuery.isLoading}
									striped
								/>
							)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{currentConfig && (
				<EditCustomerFieldConfiguration hideModal={hideEditModal} customerFieldConfig={currentConfig} />
			)}
		</>
	);
};

export default FieldConfiguration;
