import React from 'react';
import { useDispatch } from 'react-redux';
import { CCard, CCardBody, CRow, CCol, CCardHeader, CDataTable, CCardTitle } from '@coreui/react';
import _t from 'counterpart';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { formatDateTime, formatCurrency, extractErrorMessage } from '../../helpers';
import CIcon from '@coreui/icons-react';
import { loadCompetition } from '../../services/BackendService';
import { useQuery } from 'react-query';
import { showErrorToast } from '../../actions';
import PageLayout from '../../components/PageLayout';

interface IQueryParams {
	id: string;
}

const CompetitionPage = () => {
	const { id } = useParams<IQueryParams>();
	const dispatch = useDispatch();

	const loadCompetitionQuery = useQuery(['competition', id], () => loadCompetition(id), {
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	if (loadCompetitionQuery.isLoading || loadCompetitionQuery.isIdle) {
		return <Loading />;
	}

	if (loadCompetitionQuery.isError) {
		return null;
	}

	const { competition, leaderboard } = loadCompetitionQuery.data;

	return (
		<PageLayout title={_t.translate('competition.title')}>
			<CRow>
				<CCol lg={3}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('global.details')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('competition.description')}:</dt>
										<dd>{competition.description}</dd>

										<dt>{_t('competition.start')}:</dt>
										<dd>{formatDateTime(competition.start)}</dd>

										<dt>{_t('competition.end')}:</dt>
										<dd>{formatDateTime(competition.end)}</dd>
										<dt>{_t('competition.participants')}:</dt>
										<dd>{leaderboard?.length || '0'}</dd>
										<dt>{_t('competitions.promoted')}:</dt>
										<dd>
											<CIcon
												className={competition.promoted ? 'text-success' : 'text-danger'}
												name={competition.promoted ? 'cil-check-circle' : 'cil-x-circle'}
											/>
										</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('competition.first-prize')}:</dt>
										<dd>{formatCurrency(competition.firstPrizeInUSD)}</dd>

										{competition.secondPrizeInUSD !== null && (
											<>
												<dt>{_t('competition.second-prize')}:</dt>
												<dd>{formatCurrency(competition.secondPrizeInUSD || 0)}</dd>
											</>
										)}

										{competition.thirdPrizeInUSD !== null && (
											<>
												<dt>{_t('competition.third-prize')}:</dt>
												<dd>{formatCurrency(competition.thirdPrizeInUSD)}</dd>
											</>
										)}

										<dt>{_t('competitions.terms-and-conditions')}:</dt>
										<dd>{competition.tcLink ?? '-'}</dd>
										<dt>{_t('global.type')}</dt>
										<dd>{_t(`competitions.${competition.type}`)}</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={9}>
					<CCard>
						<CCardBody>
							<CDataTable
								items={leaderboard || []}
								fields={[
									{ key: 'nickname', label: _t('competition.leaderboard.nickname') },
									{ key: 'points', label: _t('competition.leaderboard.points') },
									{ key: 'mtLogin', label: _t('competition.leaderboard.mtLogin'), sorter: false },
									{ key: 'volume', label: _t('competition.leaderboard.volume') },
								]}
								sorter
								itemsPerPage={20}
								striped
								tableFilter={{ placeholder: _t('global.search-something') }}
								hover
								pagination
								noItemsViewSlot={<br />}
								scopedSlots={{
									points: (rank: any) => <td>{rank.points.toFixed(2)}</td>,
								}}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default CompetitionPage;
