import React, { useEffect, useState } from 'react';
import { CButton, CForm, CImg, CSpinner } from '@coreui/react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import paperPlane from '../../icons/paper_plane.svg';
import { createCustomerComment, fetchCustomerComments } from '../../services/BackendService';
import Loading from '../../components/Loading';
import { IComment } from './types';
import { extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast } from '../../actions';
import _t from 'counterpart';
import CustomerComment from './CustomerComment';
import Textarea from 'react-expanding-textarea';

const CustomerCommentsList = () => {
	const [comment, setComment] = useState<string>('');
	const dispatch = useAppDispatch();

	const params = useParams();
	const { customerId } = params as any;

	const scrollerRef = React.useRef<HTMLDivElement>(null);

	const setCommentState = (e: any) => {
		setComment(e.target.value);
	};

	const scrollToBottom = () => {
		if (scrollerRef && scrollerRef?.current) {
			const offsetBottom = scrollerRef.current.scrollHeight;
			scrollerRef.current.scrollTop = offsetBottom;
		}
	};

	const customerCommentsQuery = useQuery(['customer-comments', customerId], () => fetchCustomerComments(customerId), {
		onSuccess: () => {
			setTimeout(() => {
				scrollToBottom();
			}, 100);
		},
	});

	useEffect(() => {
		scrollToBottom();
	});

	const createCommentMutation = useMutation(() => createCustomerComment(customerId, comment.trim()), {
		onSuccess: () => {
			customerCommentsQuery.refetch();
			setComment('');
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
			createCommentMutation.mutate();
		}
	};

	const createComment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		createCommentMutation.mutate();
	};

	if (customerCommentsQuery.isIdle || customerCommentsQuery.isLoading) {
		return <Loading />;
	}

	if (customerCommentsQuery.isError) {
		return null;
	}

	const isCreatingComment = createCommentMutation.isLoading;

	return (
		<div className="comments">
			<div className="comments-list" ref={scrollerRef}>
				{customerCommentsQuery.data.length > 0 &&
					customerCommentsQuery.data.map((c: IComment) => {
						const { id, name, avatarUrl } = c.commentingCustomer;
						return (
							<CustomerComment
								accountId={id}
								displayName={name}
								comment={c.comment}
								image={avatarUrl}
								createdAt={c.createdAt}
								key={c.id}
							/>
						);
					})}
				{customerCommentsQuery.data.length === 0 && (
					<span className="no-comments-placeholder text-muted">{_t('customer.no-comments-placeholder')}</span>
				)}
			</div>
			<div className="comments-add">
				<CForm onSubmit={createComment}>
					<Textarea
						className="w-100 mt-2 comments-input"
						onChange={setCommentState}
						value={comment}
						placeholder={_t('customer.comment-placeholder')}
						onKeyDown={handleKeydown}
						rows={3}
					/>
					<CButton type="submit" className="comments-button" disabled={isCreatingComment}>
						{!isCreatingComment && <CImg src={paperPlane} />}
						{isCreatingComment && (
							<div>
								<CSpinner size="sm" className="p-0 m-0" color="light" />
							</div>
						)}
					</CButton>
				</CForm>
			</div>
		</div>
	);
};

export default React.memo(CustomerCommentsList);
