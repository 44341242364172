import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import _t from 'counterpart';
import { stringify } from 'qs';
import { useQuery, useQueryClient } from 'react-query';
import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { clearCustomersFilters } from '../../actions';
import Error from '../../components/Error';
import ExportXLSXButton from '../../components/ExportXLSXButton';
import PageLayout from '../../components/PageLayout';
import { ISorter } from '../../components/PaginationTable';
import SearchFilter from '../../components/SearchFilter';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { customerFiltersToDTO } from '../../sagas/customersSaga';
import { loadCustomers } from '../../services/BackendService';
import AddCustomerModal from './AddCustomerModal';
import CustomersFilters from './CustomersFilters';
import CustomersTable from './CustomersTable';
import CustomersTableColumnSelect from './CustomersTableColumnSelect';

const CustomersPage = () => {
	const token = useAppSelector((state: any) => state.user.token);
	const customersFilters = useAppSelector((state) => state.filters.customersFilters, shallowEqual);

	const [showFilters, setShowFilters] = useState<boolean>(false);

	const [addCustomerVisible, setAddCustomerVisible] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter, setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);
	const [referralForId] = useQueryParam('referralForId', StringParam);

	const customersItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_customers_table') ?? 10);
	const [limit, setLimit] = useState<number>(customersItemsPerPageFromLocalStorage);

	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const history = useHistory();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const dtoFilters = customerFiltersToDTO(customersFilters);
	const { data, isLoading, refetch, isError } = useQuery<any>(
		['customers', filter, page, orderBy, customersFilters, referralForId],
		() => loadCustomers(filter || '', limit, offset, referralForId || undefined, orderBy!, dtoFilters)
	);

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_customers_table', limit.toString());
		await queryClient.invalidateQueries('customers');
		refetch();
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const showAddCustomer = () => {
		setAddCustomerVisible(true);
	};

	const hideAddCustomer = () => {
		setAddCustomerVisible(false);
		refetch();
	};

	const onRowClicked = (user: any) => {
		history.push(`/customers/${user.id}`);
	};

	const resetFilters = () => {
		dispatch(clearCustomersFilters());
	};

	const getFiltersCount = () => {
		const objectKeys = Object.keys(customersFilters);
		return Object.values(customersFilters).reduce<number>((count, filterValue, currentIndex) => {
			const filterKey = objectKeys[currentIndex];
			const notToggleSelect = filterKey === 'salesPeople' || filterKey === 'sources';
			if (
				(filterValue && !Array.isArray(filterValue)) ||
				(Array.isArray(filterValue) && filterValue.length > 0 && !notToggleSelect)
			) {
				return count + 1;
			}
			if (filterKey === 'sources') {
				const foundEnabled = customersFilters.sources.find((s: any) => s.enabled);
				if (foundEnabled) {
					return count + 1;
				}
			}
			return count;
		}, 0);
	};

	const getExportLink = (): string => {
		const filters = customerFiltersToDTO(customersFilters);
		const params: any = { ...filters, token, referralForId };

		return `${process.env.REACT_APP_API_ROOT}/api/admin/customers/export/xlsx?${stringify(params)}`;
	};

	const filtersCount = getFiltersCount();

	let recordCount = 0;
	if (data) {
		const { pages, customers } = data;
		recordCount = Number(pages) + Number(customers.length);
	}

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		refetch();
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('customers.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{getFiltersCount()}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>

											<CustomersTableColumnSelect />
										</div>
										<div className="float-right">
											<CButton className="mr-2 header-button-add" onClick={showAddCustomer} />
											{recordCount !== 0 && (
												<ExportXLSXButton downloadLink={getExportLink()} defaultFilename="customers.xlsx" />
											)}
										</div>
									</div>
								</div>
								<CustomersFilters show={showFilters} onFilterChanged={onFilterChanged} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CustomersTable
								data={data?.customers || []}
								columnsAreHideable
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								isLoading={isLoading}
								pages={data?.pages || 0}
								activePage={page || 1}
								onRowClicked={onRowClicked}
								showFilter={false}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<AddCustomerModal show={addCustomerVisible} onClose={hideAddCustomer} />
		</PageLayout>
	);
};

export default CustomersPage;
