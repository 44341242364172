import React, { useState } from 'react';
import _t from 'counterpart';
import { CCard, CCardHeader, CCardTitle, CCardBody, CFormGroup, CCol, CRow } from '@coreui/react';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import { useAppDispatch } from '../../helpers/customHooks';
import { useGetSetting, useSetSetting } from './hooks';
import CustomSelect from '../../components/CustomSelect';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import { showSuccessToast } from '../../actions';
import SendgridIntegration from './SendgridIntegration';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import MailgunIntegration from './MailgunIntegration';
import SmtpProviderIntegration from './SmtpProviderIntegration';
import BrevoIntegration from './BrevoIntegration';

const EmailIntegration = () => {
	const providersOptions = [
		{ label: 'Disabled', value: '' },
		{ label: 'Sendgrid', value: 'sendgrid' },
		{ label: 'Mailgun', value: 'mailgun' },
		{ label: 'SMTP Provider', value: 'smtpProvider' },
		{ label: 'Brevo', value: 'brevo' },
	];

	const [provider, setProvider] = useState<{ label: string; value: string }>(providersOptions[0]);
	const dispatch = useAppDispatch();

	const emailProvider = useGetSetting('EMAIL_PROVIDER', 'email-provider', {
		refetchOnWindowFocus: false,
		onSuccess: (provider: string) => {
			if (provider === '') {
				setProvider(providersOptions[0]);
			}
			if (provider === 'sendgrid') {
				setProvider(providersOptions[1]);
			}
			if (provider === 'mailgun') {
				setProvider(providersOptions[2]);
			}
			if (provider === 'smtpProvider') {
				setProvider(providersOptions[3]);
			}
			if (provider === 'brevo') {
				setProvider(providersOptions[4]);
			}
		},
	});

	const emailProviderMutation = useSetSetting();

	const onProviderSuccess = () => {
		dispatch(showSuccessToast(_t('integrations.email-provider-disabled')));
		emailProvider.refetch();
	};

	const disableEmailMutationCall = () => {
		emailProviderMutation.mutate(
			{ key: 'EMAIL_PROVIDER', value: provider.value },
			{
				onSuccess: onProviderSuccess,
			}
		);
	};

	const handleProviderChanged = (provider: any) => {
		setProvider(provider);
	};

	const isLoading = emailProviderMutation.isLoading || emailProvider.isFetching;
	const isError = Boolean(emailProvider.error) && !isLoading;

	return (
		<CCard>
			<CCardHeader>
				<CCardTitle>{_t('integrations.email-provider')}</CCardTitle>
			</CCardHeader>
			<CCardBody>
				<QueryDataRenderer isError={isError} isLoading={isLoading} onRefetch={emailProvider.refetch}>
					<CFormGroup>
						<InputHeaderNoButton labelText={_t('providers.label')} />
						<CustomSelect
							value={provider}
							onChange={handleProviderChanged}
							options={providersOptions}
							menuPortalTarget={document.body}
						/>
					</CFormGroup>
					{provider.value === 'sendgrid' && <SendgridIntegration />}
					{provider.value === 'mailgun' && <MailgunIntegration />}
					{provider.value === 'smtpProvider' && <SmtpProviderIntegration />}
					{provider.value === 'brevo' && <BrevoIntegration />}
					{provider.value === '' && (
						<CRow>
							<CCol md={12} className="d-flex justify-content-end">
								<ButtonWithLoader
									isLoading={emailProviderMutation.isLoading}
									onClick={disableEmailMutationCall}
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t('action.save')}
									className="btn-md"
									showText={false}
								/>
							</CCol>
						</CRow>
					)}
				</QueryDataRenderer>
			</CCardBody>
		</CCard>
	);
};

export default EmailIntegration;
