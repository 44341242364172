import React, { useState } from 'react';
import _t from 'counterpart';
import { CCard, CCardHeader, CCardTitle, CCardBody, CFormGroup, CCol, CRow } from '@coreui/react';
import CustomSelect from '../../components/CustomSelect';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import { useGetSetting, useSetSetting } from './hooks';
import { useAppDispatch } from '../../helpers/customHooks';
import { showSuccessToast } from '../../actions';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import RecaptchaV3Integration from './RecaptchaV3Integration';
import ButtonWithLoader from '../../components/ButtonWithLoader';

const CaptchaIntegration = () => {
	const providersOptions = [
		{ label: 'Disabled', value: '' },
		{ label: 'Recaptcha V3', value: 'recaptchav3' },
	];

	const [provider, setProvider] = useState<{ label: string; value: string }>(providersOptions[0]);

	const settingsMutation = useSetSetting();
	const dispatch = useAppDispatch();

	const captchaProvider = useGetSetting('CAPTCHA_PROVIDER', 'captcha-provider', {
		refetchOnWindowFocus: false,
		onSuccess: (provider: string) => {
			if (provider === '') {
				setProvider(providersOptions[0]);
			}
			if (provider === 'recaptchav3') {
				setProvider(providersOptions[1]);
			}
		},
	});

	const handleProviderChanged = (provider: any) => {
		setProvider(provider);
	};

	const disableCaptchaMutationCall = () => {
		settingsMutation.mutate(
			{ key: 'CAPTCHA_PROVIDER', value: '' },
			{
				onSuccess: () => {
					dispatch(showSuccessToast(_t('integrations.captcha-provider-disabled')));
					captchaProvider.refetch();
				},
			}
		);
	};

	const isLoading = settingsMutation.isLoading || captchaProvider.isFetching;
	const isError = Boolean(captchaProvider.error) && !isLoading;

	return (
		<CCard>
			<CCardHeader>
				<CCardTitle>{_t('integrations.captcha-provider')}</CCardTitle>
			</CCardHeader>
			<CCardBody>
				<QueryDataRenderer isError={isError} isLoading={isLoading} onRefetch={captchaProvider.refetch}>
					<CFormGroup>
						<InputHeaderNoButton labelText={_t('providers.label')} />
						<CustomSelect
							value={provider}
							onChange={handleProviderChanged}
							options={providersOptions}
							menuPortalTarget={document.body}
						/>
					</CFormGroup>
					{provider.value === 'recaptchav3' && <RecaptchaV3Integration />}
					{provider.value === '' && (
						<CRow>
							<CCol md={12} className="d-flex justify-content-end">
								<ButtonWithLoader
									isLoading={settingsMutation.isLoading}
									onClick={disableCaptchaMutationCall}
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t('action.save')}
									className="btn-md"
									showText={false}
								/>
							</CCol>
						</CRow>
					)}
				</QueryDataRenderer>
			</CCardBody>
		</CCard>
	);
};

export default CaptchaIntegration;
