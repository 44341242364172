import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import _t from 'counterpart';
import { ICommissionSchemeFull } from './types';
import { useMutation, useQueryClient } from 'react-query';
import { deleteCommissionScheme } from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';

interface IProps {
	commissionScheme: ICommissionSchemeFull;
	hideModal: () => void;
}

export const DeleteCommissionSchemeModal = ({ commissionScheme, hideModal }: IProps) => {
	const queryClient = useQueryClient();
	const deleteCommissionSchemeMutation = useMutation(
		(commissionSchemeId: number) => deleteCommissionScheme(commissionSchemeId),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries('commission-schemes');
				hideModal();
			},
		}
	);

	const deleteCommissionSchemeCall = () => {
		if (commissionScheme) {
			deleteCommissionSchemeMutation.mutate(commissionScheme.id);
		}
	};

	return (
		<CModal size="sm" show onClose={hideModal} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>{_t('commission-schemes.delete-modal-title', { name: commissionScheme.name })}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				{deleteCommissionSchemeMutation.isError && (
					<span className="text-danger">{extractErrorMessage(deleteCommissionSchemeMutation.error)}</span>
				)}
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={deleteCommissionSchemeMutation.isLoading}
					onClick={deleteCommissionSchemeCall}
					buttonColor="danger"
					spinnerColor="secondary"
					title={_t.translate('global.confirm')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={hideModal}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};
