import React, { useState } from 'react';
import {
	CButton,
	CModal,
	CModalBody,
	CModalHeader,
	CModalTitle,
	CModalFooter,
	CFormGroup,
	CLabel,
	CInput,
} from '@coreui/react';
import _t from 'counterpart';
import { useMutation } from 'react-query';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useAppDispatch } from '../../helpers/customHooks';
import { extractErrorMessage } from '../../helpers';
import { showErrorToast, showSuccessToast } from '../../actions';
import { configureCallAgent } from '../../services/BackendService';

interface IProps {
	customerId: string;
	initialAgentId: string;
	show: boolean;
	hidePanel: Function;
	providerType: string;
}

const ConfigureCallAgentModal = ({ customerId, show, hidePanel, initialAgentId, providerType }: IProps) => {
	const [agentId, setAgentId] = useState<string>(initialAgentId);
	const dispatch = useAppDispatch();
	const configureCoperatoAgentMutation = useMutation(
		({ customerId, agentId }: { customerId: string; agentId: string }) =>
			configureCallAgent(customerId, agentId, providerType),
		{
			onSuccess: () => {
				hidePanel(true);
				dispatch(showSuccessToast(_t.translate('customer.call-agent-id-successfully-set')));
			},
			onError: (e: any) => {
				const error = extractErrorMessage(e);
				dispatch(showErrorToast(error));
			},
		}
	);

	const confirm = () => {
		configureCoperatoAgentMutation.mutate({ customerId, agentId });
	};

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		setAgentId(target.value);
	};

	const loading = configureCoperatoAgentMutation.isLoading;

	return (
		<CModal show={show} onClose={hidePanel} size="sm">
			<CModalHeader className="d-flex flex-column">
				<CModalTitle>{_t('customer.configure-call-agent-id')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="agentId">{_t('customer.agent-id')}</CLabel>
					<CInput type="text" name="agentId" value={agentId} onChange={handleInputChange} />
				</CFormGroup>
			</CModalBody>
			<CModalFooter className="modal-footer--alternative">
				<ButtonWithLoader
					isLoading={loading}
					onClick={confirm}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t.translate('global.confirm')}
				/>
				<CButton color="light" variant="outline" onClick={hidePanel} disabled={loading}>
					{_t('global.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default ConfigureCallAgentModal;
