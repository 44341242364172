import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CAlert, CDataTable, CModalTitle } from '@coreui/react';
import _t from 'counterpart';
import { extractErrorMessage } from '../../helpers';
import { useMutation, useQuery } from 'react-query';
import { updateCustomer, loadCommissionSchemes } from '../../services/BackendService';
import { ICommissionSchemeFull } from '../commissionSchemes/types';
import InlineSpinner from '../../components/InlineSpinner';

interface IProps {
	userId: string;
	hideModal: (refetch?: boolean) => void;
}

interface MutationParams {
	customerId: string;
	newCommissionSchemeId: number;
}

const CommissionSchemeChangeModal = ({ userId, hideModal }: IProps) => {
	const { isLoading, error, data } = useQuery<any, Error>(['commission-schemes'], loadCommissionSchemes);

	const changeCustomerCommissionSchemeMutation = useMutation(
		(params: MutationParams) => updateCustomer(params.customerId, { commissionSchemeId: params.newCommissionSchemeId }),
		{
			onSuccess: async () => {
				hideModal(true);
			},
		}
	);

	return (
		<CModal
			show
			onClose={hideModal}
			size="xl"
			// @ts-ignore until https://github.com/coreui/coreui-react/pull/174 is merged
			scrollable
		>
			<CModalHeader closeButton>
				<CModalTitle>{_t('customer.change-commission-scheme')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				{error && <CAlert color="danger">{extractErrorMessage(error)}</CAlert>}
				<CDataTable
					items={data}
					fields={[
						{
							key: 'name',
							label: _t('global.name'),
						},
						{
							key: 'coinRolloverCommissionsUSD',
							label: _t('commission-schemes.coin-rollover-commissions'),
						},
						{
							key: 'actions',
							label: _t('global.actions'),
							sorter: false,
							filter: false,
						},
					]}
					scopedSlots={{
						coinRolloverCommissionsUSD: (commissionScheme: any) => (
							<td>{commissionScheme.coinRolloverCommissionsUSD.join(', ')}</td>
						),
						actions: (commissionScheme: ICommissionSchemeFull) => (
							<td>
								<CButton
									onClick={() =>
										changeCustomerCommissionSchemeMutation.mutate({
											customerId: userId,
											newCommissionSchemeId: commissionScheme.id,
										})
									}
									size="sm"
									className="mr-1"
									color="success"
								>
									{changeCustomerCommissionSchemeMutation.isLoading &&
									changeCustomerCommissionSchemeMutation.variables?.newCommissionSchemeId === commissionScheme.id ? (
										<InlineSpinner />
									) : (
										_t('customer.assign-scheme')
									)}
								</CButton>
							</td>
						),
					}}
					loading={isLoading}
				/>
			</CModalBody>
		</CModal>
	);
};

export default CommissionSchemeChangeModal;
