import React from 'react';
import _t from 'counterpart';
import UpdatePassword from '../../components/UpdatePassword';
import CommissionSchemeChangeModal from './CommissionSchemeChangeModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import { ICustomerModalsShow } from './types';
import {
	requestDisableCustomer,
	requestEnableCustomer,
	confirmEmail,
	updateCustomerSalesPerson,
	updateCustomerReferrer,
	callCustomer,
	removeRiskStatus,
} from '../../services/BackendService';
import AssignableCustomers, { SalesAgentRoles } from './AssignableCustomers';
import { useMutation } from 'react-query';
import { useAppDispatch } from '../../helpers/customHooks';
import { showSuccessToast, showErrorToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';
import EmailCustomerModal from './CustomerPage/EmailCustomerModal';
import AssignSalesStatusModal from './CustomerPage/AssignSalesStatusModal';

interface IProps {
	id: string;
	customer: any;
	modalsVisibility: ICustomerModalsShow;
	setTargetModalVisibility: (modalsVisiblity: ICustomerModalsShow, refetch?: boolean) => void;
}

const CustomerModals = ({ id, customer, modalsVisibility, setTargetModalVisibility }: IProps) => {
	const dispatch = useAppDispatch();

	const hideAffiliatesModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, affiliatesModalShow: false }, refetch);
		document.querySelector('body')?.classList.remove('modal-open');
	};

	const hideSalesPersonModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, salesPersonModalShow: false }, refetch);
		document.querySelector('body')?.classList.remove('modal-open');
	};

	const updateSalesPersonMutation = useMutation(
		({ referrerId, salesPersonRole }: { referrerId: string; salesPersonRole: SalesAgentRoles }) =>
			updateCustomerSalesPerson(id, referrerId, salesPersonRole),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('customer.sales-person-assigned')));
				hideSalesPersonModal(true);
			},
			onError: (e) => {
				dispatch(showErrorToast(extractErrorMessage(e)));
			},
		}
	);

	const updateReferrerMutation = useMutation((referrerId: string) => updateCustomerReferrer(id, referrerId), {
		onSuccess: () => {
			hideAffiliatesModal(true);
		},
		onError: (e) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const hideCommissionModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, changeCommissionSchemeShow: false }, refetch);
	};

	const hideUpdatePassword = () => {
		setTargetModalVisibility({ ...modalsVisibility, updatePasswordShow: false });
	};

	const hideConfirmEmailPanel = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, confirmEmailShow: false }, refetch);
	};

	const hideConfirmBar = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, confirmBarShow: false }, refetch);
	};

	const closeAssignSalesStatus = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, assignSalesStatusShow: false }, refetch);
	};

	const hideEmailCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, emailCustomerModalShow: false });
	};

	const showEmailCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, emailCustomerModalShow: true });
	};

	const hideSetRiskStatusModal = (refetch?: boolean) => {
		setTargetModalVisibility({ ...modalsVisibility, removeRiskStatusShow: false }, refetch);
	};

	const assignSalesPerson = (salesPersonId: string, salesPersonRole: SalesAgentRoles | undefined) => {
		if (salesPersonRole) {
			updateSalesPersonMutation.mutate({ referrerId: salesPersonId, salesPersonRole });
		}
	};

	const assignReferrer = (referrerId: string) => {
		updateReferrerMutation.mutate(referrerId);
	};

	const { status, mobilenumber } = customer;

	const hideCallCustomerModal = () => {
		setTargetModalVisibility({ ...modalsVisibility, callCustomerModalShow: false }, false);
	};

	return (
		<>
			{modalsVisibility.changeCommissionSchemeShow && (
				<CommissionSchemeChangeModal userId={id!} hideModal={hideCommissionModal} />
			)}

			<ConfirmationModal
				id={id}
				show={modalsVisibility.callCustomerModalShow}
				onConfirm={callCustomer}
				hidePanel={hideCallCustomerModal}
				title={_t('customer.call-customer-on', { number: mobilenumber })}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			<ConfirmationModal
				id={id}
				show={['ACTIVE', 'PENDING'].includes(status) && modalsVisibility.confirmBarShow}
				onConfirm={requestDisableCustomer}
				hidePanel={hideConfirmBar}
				title={_t('customer.disable-user')}
				error={null}
				buttonColor="danger"
				withMutation
			/>

			<ConfirmationModal
				id={id}
				show={status === 'DISABLED' && modalsVisibility.confirmBarShow}
				onConfirm={requestEnableCustomer}
				hidePanel={hideConfirmBar}
				title={_t('customer.enable-user')}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			<ConfirmationModal
				id={id}
				show={modalsVisibility.removeRiskStatusShow}
				onConfirm={removeRiskStatus}
				hidePanel={hideSetRiskStatusModal}
				title={_t('customer.remove-risk-status')}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			{modalsVisibility.salesPersonModalShow && (
				<AssignableCustomers
					show={modalsVisibility.salesPersonModalShow}
					onClose={hideSalesPersonModal}
					queryKey="sales-people"
					action={assignSalesPerson}
					roles={['SALES']}
					assignSalesAgent
				/>
			)}

			{modalsVisibility.affiliatesModalShow && (
				<AssignableCustomers
					show={modalsVisibility.affiliatesModalShow}
					onClose={hideAffiliatesModal}
					queryKey="affiliates-assign"
					action={assignReferrer}
					roles={null}
					assignSalesAgent={false}
				/>
			)}

			<UpdatePassword
				show={modalsVisibility.updatePasswordShow}
				customerId={customer.id}
				onClose={hideUpdatePassword}
			/>

			<ConfirmationModal
				id={id}
				show={modalsVisibility.confirmEmailShow}
				hidePanel={hideConfirmEmailPanel}
				onConfirm={confirmEmail}
				title={_t('customer.confirm-email-question')}
				error={null}
				buttonColor="primary"
				withMutation
			/>

			<AssignSalesStatusModal
				show={modalsVisibility.assignSalesStatusShow}
				onClose={closeAssignSalesStatus}
				customerId={customer.id}
			/>

			<EmailCustomerModal
				show={modalsVisibility.emailCustomerModalShow}
				onClose={hideEmailCustomerModal}
				customer={customer!}
				onCloseDiscardChanges={showEmailCustomerModal}
			/>
		</>
	);
};

export default CustomerModals;
