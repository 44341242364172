import React, { useEffect, useState } from 'react';
import {
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CFormGroup,
	CLabel,
	CInput,
	CModalFooter,
	CButton,
	CInputCheckbox,
} from '@coreui/react';
import { IPosition } from './position';
import _t from 'counterpart';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { showErrorToast, showSuccessToast } from '../../actions';
import { updatePosition } from '../../services/BackendService';

interface IProps {
	position: IPosition | null;
	onClose: (refetch?: boolean) => void;
}

interface IParams {
	id: string;
	stopLoss: string | null;
	takeProfit: string | null;
}

const UpdatePositionModal = ({ position, onClose }: IProps) => {
	const [takeProfit, setTakeProfit] = useState<string | null>(null);
	const [stopLoss, setStopLoss] = useState<string | null>(null);
	const [editTakeProfit, setEditTakeProfit] = useState<boolean>(false);
	const [editStopLoss, setEditStopLoss] = useState<boolean>(false);

	const dispatch = useDispatch();

	const updatePositionMutation = useMutation(
		({ id, stopLoss, takeProfit }: IParams) => updatePosition(id, stopLoss, takeProfit),
		{
			onSuccess: async () => {
				dispatch(showSuccessToast(_t('positions.updated-successfully')));
				onClose(true);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const error = extractErrorMessage(e);
					dispatch(showErrorToast(error));
				}
			},
			retry: false,
		}
	);

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const targetToUpdate = e.target.name;

		if (targetToUpdate === 'takeProfit') {
			const isEnabled = e.target.checked;
			setEditTakeProfit(isEnabled);
			if (!isEnabled) {
				setTakeProfit(null);
			} else {
				setTakeProfit('');
			}
		}

		if (targetToUpdate === 'stopLoss') {
			const isEnabled = e.target.checked;
			setEditStopLoss(isEnabled);
			if (!isEnabled) {
				setStopLoss(null);
			} else {
				setStopLoss('');
			}
		}
	};

	useEffect(() => {
		if (position) {
			setTakeProfit(position.takeProfit || null);
			setStopLoss(position.stopLoss || null);
			if (position.takeProfit) {
				setEditTakeProfit(true);
			}
			if (position.stopLoss) {
				setEditStopLoss(true);
			}
		}
	}, [position]);

	const findError = (paramName: string) => {
		return findErrorFromValidation(updatePositionMutation.error, paramName);
	};

	const onTakeProfitChanged = (e: any) => {
		const target = e.target as HTMLInputElement;
		setTakeProfit(target.value);
	};

	const onStopLossChanged = (e: any) => {
		setStopLoss(e.target.value);
	};

	const callMutation = () => {
		if (position) {
			updatePositionMutation.mutate({
				id: position.id.toString(),
				takeProfit: takeProfit,
				stopLoss: stopLoss,
			});
		}
	};

	const onOpen = () => {
		updatePositionMutation.reset();
	};

	return (
		<CModal show={position !== null} onClose={onClose} onOpened={onOpen} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>{_t('positions.update-position')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CInputCheckbox
						id="takeProfit"
						name="takeProfit"
						checked={editTakeProfit}
						onChange={handleCheckboxChange}
						style={{ marginLeft: '1px' }}
					/>
					<CLabel variant="checkbox" htmlFor="takeProfit" style={{ marginLeft: '5%' }}>
						{_t('orders.set-take-profit')}
					</CLabel>
					<CInput
						type="number"
						placeholder="10.0"
						value={takeProfit || ''}
						onChange={onTakeProfitChanged}
						disabled={!editTakeProfit}
					/>
					{findError('takeProfit') && <CLabel className="text-danger">{findError('takeProfit')}</CLabel>}
				</CFormGroup>
				<CFormGroup>
					<CInputCheckbox
						id="stopLoss"
						name="stopLoss"
						checked={editStopLoss}
						onChange={handleCheckboxChange}
						style={{ marginLeft: '1px' }}
					/>
					<CLabel variant="checkbox" htmlFor="stopLoss" style={{ marginLeft: '5%' }}>
						{_t('orders.set-stop-loss')}
					</CLabel>
					<CInput
						type="number"
						placeholder="10.0"
						value={stopLoss || ''}
						disabled={!editStopLoss}
						onChange={onStopLossChanged}
					/>
					{findError('stopLoss') && <CLabel className="text-danger">{findError('stopLoss')}</CLabel>}
				</CFormGroup>
			</CModalBody>
			<CModalFooter>
				<CButton color="primary" onClick={callMutation} className="mr-2">
					{_t('action.update')}
				</CButton>
				<CButton color="secondary" onClick={onClose}>
					{_t('positions.position.close')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(UpdatePositionModal);
