import React, { useState } from 'react';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import {
	CRow,
	CCol,
	CCard,
	CCardBody,
	CCardHeader,
	CDataTable,
	CBadge,
	CButton,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react';
import { capitalize, errorWithCode } from '../../helpers';
import { updateCustomer, loadCustomerWallets } from '../../services/BackendService';
import { showErrorToast } from '../../actions';
import AddWalletModal from './AddWalletModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import CustomerWalletPopup from './CustomerWalletPopup';
import Balance from './CustomerWalletBalance';
import Credit from './CustomerWalletCredit';
import { IWallet } from '../wallets/types';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../helpers/customHooks';
import { Permission } from '../../reducers/userReducer';
import WalletTransferModal from './WalletTransferModal';

function CustomerWalletsTable({ customerId }: { customerId: string }) {
	const [walletPopup, setWalletPopup] = useState<IWallet | null>(null);
	const [addWalletModalShow, toggleAddWalletVisibility] = useState<boolean>(false);
	const [wantedPrimaryWalletId, setWantedPrimaryWalletId] = useState<string | null>(null);
	const [transferModalShow, setTransferModalShow] = useState<boolean>(false);

	const permissions = useAppSelector((state) => state.user.permissions);

	const dispatch = useDispatch();

	const { data } = useQuery(['customer-wallets', customerId], async () => {
		const data = await loadCustomerWallets(customerId);
		return data;
	});

	const queryClient = useQueryClient();

	const setPrimaryWalletMutation = useMutation(
		(customerId: string) => updateCustomer(customerId, { primaryWalletId: wantedPrimaryWalletId }),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries('customer-wallets');
				setWantedPrimaryWalletId(null);
			},
		}
	);

	const trySetPrimaryWallet = () => {
		if (wantedPrimaryWalletId) {
			setPrimaryWalletMutation.mutate(customerId);
		}
	};

	const error = errorWithCode(setPrimaryWalletMutation.error);
	if (setPrimaryWalletMutation.isError) {
		const { message, code } = errorWithCode(setPrimaryWalletMutation.error);
		if (code === 500) {
			dispatch(showErrorToast(message));
		}
	}

	const selectedWalletId = () => {
		if (data && data.length > 0) {
			return data.find((w) => w.id === wantedPrimaryWalletId)?.username;
		}
		return '';
	};

	const manageWalletsPermission = permissions?.includes(Permission.MANAGE_WALLETS);

	const showTransferModal = () => {
		setTransferModalShow(true);
	};

	const hideTransferModal = () => {
		setTransferModalShow(false);
	};

	const walletsLengthLimit = data ? data.filter((w) => !w.isDemo).length : 0;

	return (
		<CRow>
			<CCol md={12}>
				<CCard className="card card--listing">
					<CCardBody className="card__holder">
						<CCardHeader className="card__header">
							<div className="card__info">
								<h4 className="card__title">{_t('customer.wallets.title')}</h4>
							</div>
							{manageWalletsPermission && (
								<div>
									{walletsLengthLimit > 1 && (
										<CButton
											color="primary"
											className="mr-2 header-button-transfer"
											onClick={showTransferModal}
											title={_t('customer.transfer.transfer')}
										/>
									)}
									<CButton
										color="primary"
										className="mr-2 header-button-add"
										onClick={() => toggleAddWalletVisibility(true)}
									/>
								</div>
							)}
						</CCardHeader>
						<CDataTable
							items={data || []}
							fields={[
								{ key: 'status', label: capitalize(_t('global.status')) },
								{ key: 'isDemo', label: _t('global.type') },
								{ key: 'info', label: capitalize(_t('customer.wallets.accountId')) },
								{ key: 'currency', label: capitalize(_t('customer.wallets.currency')) },
								{ key: 'balance', label: capitalize(_t('customer.wallets.balance')) },
								{ key: 'credit', label: capitalize(_t('customer.wallets.credit')) },
								{ key: 'group', label: capitalize(_t('wallet-modal.group')) },
								{ key: 'actions', label: null },
							]}
							scopedSlots={{
								info: (wallet: any) => (
									<td>
										{manageWalletsPermission && (
											<Link to={`/wallets/${wallet.id}`}>
												<div>{wallet.username}</div>
												<div className="small text-medium-emphasis">{wallet.type}</div>
											</Link>
										)}
										{!manageWalletsPermission && (
											<>
												<div>{wallet.username}</div>
												<div className="small text-medium-emphasis">{wallet.type}</div>
											</>
										)}
									</td>
								),
								isDemo: (wallet: any) => (
									<td>
										<CBadge color={wallet.isDemo ? 'danger' : 'success'}>
											{wallet.isDemo ? _t('global.demo') : _t('global.real')}
										</CBadge>
									</td>
								),
								balance: (wallet: any) => (
									<td style={{ width: '10%' }}>
										{wallet.status === 'ACTIVE' && <Balance walletId={wallet.id} />}
										{wallet.status !== 'ACTIVE' && 'n/a'}
									</td>
								),
								credit: (wallet: any) => (
									<td style={{ width: '10%' }}>
										{wallet.status === 'ACTIVE' && <Credit walletId={wallet.id} />}
										{wallet.status !== 'ACTIVE' && 'n/a'}
									</td>
								),
								group: (wallet: any) => <td>{wallet.group?.name}</td>,
								status: (wallet: any) => (
									<td>
										<CBadge color={wallet.status.toLowerCase()}>{wallet.status}</CBadge>
										{wallet.primary && <br />}
										{wallet.primary && <CBadge color="primary">PRIMARY</CBadge>}
									</td>
								),
								actions: (wallet: any) => (
									<td>
										{manageWalletsPermission && (
											<CDropdown>
												<CDropdownToggle size="md" color="primary" caret={false}>
													<CIcon name="cil-pencil" size="sm" />
												</CDropdownToggle>
												<CDropdownMenu className="py-0" placement="bottom-end">
													<CDropdownItem onClick={() => setWalletPopup(wallet)}>{_t('action.edit')}</CDropdownItem>

													{!wallet.primary && (
														<CDropdownItem onClick={() => setWantedPrimaryWalletId(wallet.id)}>
															{_t('customer.wallets.set-as-primary')}
														</CDropdownItem>
													)}
												</CDropdownMenu>
											</CDropdown>
										)}
									</td>
								),
							}}
							hover
							striped
						/>
					</CCardBody>
				</CCard>
			</CCol>

			{manageWalletsPermission && (
				<AddWalletModal
					show={addWalletModalShow}
					onClose={() => toggleAddWalletVisibility(false)}
					customerId={customerId}
				/>
			)}

			{walletPopup && <CustomerWalletPopup wallet={walletPopup} onClose={() => setWalletPopup(null)} />}

			{walletsLengthLimit > 1 && (
				<WalletTransferModal show={transferModalShow} onClose={hideTransferModal} customerId={customerId} />
			)}

			<ConfirmationModal
				id=""
				show={wantedPrimaryWalletId !== null}
				hidePanel={() => setWantedPrimaryWalletId(null)}
				onConfirm={trySetPrimaryWallet}
				title={_t('customer.wallets.set-wallet-as-primary', { username: selectedWalletId() })}
				error={error.code !== 500 && error.message}
				buttonColor="primary"
				withMutation={false}
			/>
		</CRow>
	);
}

export default CustomerWalletsTable;
