import React, { useState } from 'react';
import _t from 'counterpart';
import { CRow, CCol, CCard, CCardBody, CLink, CCardHeader, CButton } from '@coreui/react';
import { useQuery, useQueryClient } from 'react-query';
import { searchCoins } from '../../services/BackendService';
import { formatDateTime, getFiltersCount, extractErrorMessage } from '../../helpers';
import { showErrorToast, clearCoinsFilters } from '../../actions';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useAppSelector, useAppDispatch } from '../../helpers/customHooks';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import SearchFilter from '../../components/SearchFilter';
import CoinsFilters, { coinsFiltersToDTO } from './CoinsFilters';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

interface ICoinTransaction {
	accountId: number;
	amount: number;
	source: string;
	positionId: number | null;
	createdAt: Date;
}

interface ICoinsReturnType {
	transactions: Array<ICoinTransaction>;
	pages: number;
}

const CoinsPage = () => {
	const prefix = 'coins';

	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);
	const coinsFilters = useAppSelector((state) => state.filters.coinsFilters);

	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const filtersCount = getFiltersCount(coinsFilters);
	const toFilterDTO = coinsFiltersToDTO(coinsFilters);

	const coinsTransactionsItemsPerPageFromLocalStorage = Number(
		localStorage.getItem('items_per_page_coins_transactions_table') ?? 10
	);
	const [limit, setLimit] = useState<number>(coinsTransactionsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const { data, isLoading, refetch, isError } = useQuery<ICoinsReturnType>(
		['coins_transactions', filter, orderBy, toFilterDTO, page],
		() => searchCoins(filter || '', limit, offset, orderBy, toFilterDTO),
		{
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
			retry: false,
		}
	);

	const onErrorRetry = () => {
		refetch();
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const clearFilters = () => {
		dispatch(clearCoinsFilters());
	};

	const createTableField = (key: string, labelKey: string, sorter: boolean) => {
		const label = _t.translate(`${prefix}.${labelKey}`);
		return { key, label, sorter };
	};

	const setTableFilterValue = (search: string) => {
		if (search !== filter) {
			setPage(1, 'replaceIn');
			setFilter(search, 'replaceIn');
		}
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		setOrderBy(orderBy, 'replaceIn');
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_coins_transactions_table', limit.toString());
		await queryClient.invalidateQueries('coins_transactions');
		refetch();
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onFiltersChange = () => {
		setPage(1, 'replaceIn');
	};

	return (
		<PageLayout title={_t.translate('coins.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={setTableFilterValue} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={clearFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
									</div>
								</div>
								<CoinsFilters show={showFilters} onFiltersChange={onFiltersChange} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								data={data?.transactions || []}
								tableFields={[
									createTableField('accountId', 'account', false),
									createTableField('amount', 'amount', true),
									createTableField('source', 'reason', false),
									createTableField('positionId', 'positionId', false),
									createTableField('createdAt', 'createdAt', true),
								]}
								scopedSlots={{
									positionId: ({ positionId }: any) => (
										<td>
											{positionId && <CLink href={`/positions/${positionId}`}>{positionId}</CLink>}
											{!positionId && '-'}
										</td>
									),
									createdAt: ({ createdAt }: any) => <td>{formatDateTime(createdAt)}</td>,
								}}
								loading={isLoading}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								pagination
								onPageChanged={onPageChanged}
								pages={data?.pages || 1}
								activePage={page || 1}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default CoinsPage;
