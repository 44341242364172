import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CDataTable,
	CCardTitle,
	CInput,
	CLabel,
	CCardHeader,
	CCardFooter,
	CFormGroup,
	CInputCheckbox,
} from '@coreui/react';
import PageLayout from '../../components/PageLayout';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { loadGroupWithMarkups, updateGroupWithMarkups } from '../../services/BackendService';
import { extractErrorMessage, onlyPositiveInteger, findErrorFromValidation } from '../../helpers';
import { showErrorToast, showSuccessToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';
import { IGroupWithMarkups, IMarkup } from '../customers/types';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { OrderRoutingAdapterSelector } from '../customers/CustomerWalletPopup';

const GroupPage = () => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const [markups, setMarkups] = useState<Array<IMarkup>>([]);
	const [leverage, setLeverage] = useState<string>();
	const [marginCallLevel, setMarginCallLevel] = useState<string>();
	const [stopOutLevel, setStopOutLevel] = useState<string>();
	const [adapterName, setAdapterName] = useState<string>('');
	const [disableSwapCharges, setDisableSwapCharges] = useState<boolean>(false);

	const { name } = params as any;

	const loadGroupWithMarkupsQuery = useQuery(['load-group-with-markups', name], () => loadGroupWithMarkups(name), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const groupWithMarkups = loadGroupWithMarkupsQuery.data;
	const history = useHistory();

	useEffect(() => {
		if (groupWithMarkups && loadGroupWithMarkupsQuery.isSuccess) {
			setMarkups(groupWithMarkups.markups);
			setLeverage(groupWithMarkups.leverage.toString());
			setMarginCallLevel(groupWithMarkups.marginCallLevel.toString());
			setStopOutLevel(groupWithMarkups.stopOutLevel.toString());
			setAdapterName(groupWithMarkups.routingAdapterName);
			setDisableSwapCharges(groupWithMarkups.disableSwapCharges);
		}
	}, [groupWithMarkups, loadGroupWithMarkupsQuery.isSuccess, setLeverage]);

	const updateGroupWithMarkupsMutation = useMutation(
		(groupWithMarkups: IGroupWithMarkups) =>
			updateGroupWithMarkups(
				groupWithMarkups.name,
				Number(leverage),
				Number(marginCallLevel),
				Number(stopOutLevel),
				adapterName,
				markups,
				disableSwapCharges
			),
		{
			onSuccess: () => {
				loadGroupWithMarkupsQuery.refetch();
				dispatch(showSuccessToast(_t('groups.group-successfully-update')));
				history.push('/groups');
			},
			onError: (e: any) => {
				const error = extractErrorMessage(e);
				dispatch(showErrorToast(error));
			},
		}
	);

	const handleInputChange = useCallback(
		(symbol: string, field: string, value: string) => {
			const updatedMarkups = markups.map((item) => {
				if (item.instrumentSymbol === symbol) {
					return { ...item, [field]: Number(value) };
				}
				return item;
			});

			setMarkups(updatedMarkups);
		},
		[markups]
	);

	const setRoutingAdapterName = (adapterName: string) => {
		setAdapterName(adapterName);
	};

	const fields = useMemo(
		() => [
			{ key: 'symbol', label: 'Symbol', sorter: false },
			{
				key: 'bid',
				label: 'Markup Buy (pts)',
				sorter: false,
			},
			{ key: 'ask', label: 'Markup Sell (pts)', sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			symbol: (markup: IMarkup) => <td>{markup.instrumentSymbol}</td>,
			bid: (markup: IMarkup) => (
				<td>
					<CInput
						value={markup.bid}
						onChange={(e) => handleInputChange(markup.instrumentSymbol, 'bid', (e.target as HTMLInputElement).value)}
					></CInput>
				</td>
			),
			ask: (markup: IMarkup) => (
				<td>
					<CInput
						value={markup.ask}
						onChange={(e) => handleInputChange(markup.instrumentSymbol, 'ask', (e.target as HTMLInputElement).value)}
					></CInput>
				</td>
			),
		}),
		[handleInputChange]
	);

	const updateGroup = () => {
		if (groupWithMarkups) {
			updateGroupWithMarkupsMutation.mutate(groupWithMarkups);
			setLeverage(groupWithMarkups.leverage.toString());
		}
	};

	const onSetLeverage = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (onlyPositiveInteger(value)) {
			setLeverage(value);
		}
	};

	const onSetStopoutLevel = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (onlyPositiveInteger(value)) {
			setStopOutLevel(value);
		}
	};

	const toggleDisableSwapCharges = () => {
		setDisableSwapCharges(!disableSwapCharges);
	};

	const onMarginLevel = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (onlyPositiveInteger(value)) {
			setMarginCallLevel(value);
		}
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updateGroupWithMarkupsMutation.error, paramName);
	};

	return (
		<PageLayout title={name}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('groups.trading-and-risk')}</CCardTitle>
							<CRow>
								<CCol md={4}>
									<CFormGroup>
										<CLabel>{_t('groups.default-leverage')}</CLabel>
										<CInput value={leverage} onChange={onSetLeverage} />
									</CFormGroup>
								</CCol>
								<CCol md={4}>
									<CFormGroup>
										<CLabel>{_t('groups.stop-out-level')}</CLabel>
										<CInput value={stopOutLevel} onChange={onSetStopoutLevel} />
									</CFormGroup>
								</CCol>
								<CCol md={4}>
									<CFormGroup>
										<CLabel>{_t('groups.margin-call-level')}</CLabel>
										<CInput value={marginCallLevel} onChange={onMarginLevel} />
									</CFormGroup>
								</CCol>
							</CRow>
							<CFormGroup>
								<CLabel htmlFor="nf-type">{_t('wallet-modal.order-routing-adapter')}</CLabel>
								<OrderRoutingAdapterSelector onChange={setRoutingAdapterName} value={adapterName} />
								{findError('adapterName') && <CLabel className="text-danger">{findError('adapterName')}</CLabel>}
							</CFormGroup>
							<CFormGroup>
								<div className="form-check form-check-inline">
									<CInputCheckbox
										className="form-check-input"
										type="checkbox"
										id="is-demo"
										onChange={toggleDisableSwapCharges}
										checked={disableSwapCharges}
									/>
									<span>{_t('groups.disable-swap-charges')}</span>
								</div>
							</CFormGroup>
						</CCardHeader>
						<CCardBody>
							<CCardTitle style={{ display: 'block', clear: 'left' }}>{_t('instruments.title')}</CCardTitle>
							<div className="table-remove-border">
								{markups && <CDataTable fields={fields} scopedSlots={scopedSlots} items={markups} />}
							</div>
						</CCardBody>
						<CCardFooter>
							<CRow>
								<CCol>
									<ButtonWithLoader
										isLoading={updateGroupWithMarkupsMutation.isLoading}
										onClick={updateGroup}
										buttonColor="primary"
										spinnerColor="secondary"
										title={_t('action.save')}
										className="group-page-save-button"
									/>
								</CCol>
							</CRow>
						</CCardFooter>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default GroupPage;
