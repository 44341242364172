export enum AdapterType {
  Fixquoter = 'fixquoter',
  Demoquoter = 'demoquoter',
  Fix = 'fix',
  Warehouse = 'warehouse',
}

export type IAdapter = IFIXAdapter | IWarehouseAdapter;

interface IFIXAdapter {
  name: string;
  enabled: boolean;
  type: AdapterType.Fix | AdapterType.Fixquoter;
  configuration: IFIXAdapterConfig;
  status?: IFIXAdapterStatus;
}

interface IWarehouseAdapter {
  name: string;
  enabled: boolean;
  type: AdapterType.Warehouse | AdapterType.Demoquoter;
  configuration: any;
  status?: any;
}

export interface IFIXAdapterStatus {
  connected: boolean;
  lastMessageReceived: string;
  messagesReceived: number;
  messagesSent: number
}

export interface IFIXAdapterConfig {
  ssl: boolean;
  host: string;
  port: number;
  session: string;
  password: string;
  username: string;
  resetOnLogon: boolean;
  senderCompId: string;
  targetCompId: string;
}

export const defaultConfig: IFIXAdapterConfig = {
  ssl: false,
  host: '',
  port: 0,
  session: '',
  password: '',
  username: '',
  resetOnLogon: false,
  senderCompId: '',
  targetCompId: ''
}
