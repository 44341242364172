import React, { useState } from 'react';
import { CRow, CCol, CCard, CCardHeader, CCardBody, CCardTitle, CFormGroup, CSelect } from '@coreui/react';
import _t from 'counterpart';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useAppDispatch } from '../../helpers/customHooks';
import { useMutation, useQuery } from 'react-query';
import { extractErrorMessage } from '../../helpers';
import { showErrorToast, showSuccessToast } from '../../actions';
import { loadGroups, updateWalletConfig } from '../../services/BackendService';
import { useGetSetting } from '../integrations/hooks';

interface IParams {
	platform: string;
	group: string;
}

const WalletConfiguration = () => {
	const platformOptions = [
		{ value: 'NEO', label: 'NEO' },
		{ value: 'PRO', label: 'PRO' },
	];

	const [groupId, setGroupId] = useState<string>('');
	const [platform, setPlatform] = useState<ISelectOption>(platformOptions[0]);

	const dispatch = useAppDispatch();
	const walletSettingsMutation = useMutation(({ platform, group }: IParams) => updateWalletConfig(platform, group), {
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			dispatch(showErrorToast(msg));
		},
		onSuccess: () => {
			dispatch(showSuccessToast(_t('onboarding.wallet-settings-configured')));
		},
	});

	const loadGroupsQuery = useQuery(['config-order-groups'], () => loadGroups());

	const walletPlatfromQuery = useGetSetting('DEFAULT_WALLET_PLATFORM', 'wallet-platform', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			if (value === '') {
				setPlatform(platformOptions[0]);
				return;
			}
			const platformToSet = platformOptions.find((p) => p.value === value);
			if (!platformToSet) {
				setPlatform(platformOptions[0]);
				return;
			}
			setPlatform(platformToSet);
		},
	});

	const defaultGroupQuery = useGetSetting('DEFAULT_GROUP', 'default-group', {
		retry: false,
		refetchOnWindowFocus: false,
		enabled: loadGroupsQuery.data !== null,
		onSuccess: (value: string) => {
			if (loadGroupsQuery.data) {
				if (value === '') {
					setGroupId(loadGroupsQuery.data[0].name);
					return;
				}
				const groupToSet = loadGroupsQuery.data.find((g) => g.name === value);
				if (!groupToSet) {
					setGroupId(loadGroupsQuery.data[0].name);
					return;
				}
				setGroupId(groupToSet.name);
			}
		},
	});

	const handlePlatformChange = (platform: any) => {
		setPlatform(platform);
	};

	const callMutation = () => {
		walletSettingsMutation.mutate({ platform: platform.value, group: groupId });
	};

	const onGroupChanged = (e: any) => {
		const value = e.target.value;
		setGroupId(value);
	};

	const isLoadingGroup = loadGroupsQuery.isLoading || defaultGroupQuery.isLoading;
	const isErrorGroup = loadGroupsQuery.error || defaultGroupQuery.error;

	return (
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						<CCardTitle>{_t('onboarding.wallet-configuration')}</CCardTitle>
						<p>{_t('onboarding.wallet-config-description')}</p>
					</CCardHeader>
					<CCardBody className="simulated-overflow-y">
						<CRow>
							<CCol md={6}>
								<CFormGroup>
									<InputHeaderNoButton labelText={_t('onboarding.platform')} />
									{walletPlatfromQuery.isLoading && (
										<CSelect id="nf-group" name="nf-group" disabled>
											<option>{_t('global.loading')}</option>
										</CSelect>
									)}
									{walletPlatfromQuery.isError && <span>{walletPlatfromQuery.isError}</span>}
									{!walletPlatfromQuery.isError && !walletPlatfromQuery.isLoading && (
										<CustomSelect
											value={platform}
											onChange={handlePlatformChange}
											options={platformOptions}
											menuPortalTarget={document.body}
										/>
									)}
								</CFormGroup>
							</CCol>
							<CCol md={6}>
								<CFormGroup>
									<InputHeaderNoButton labelText={_t('onboarding.group')} />
									{isLoadingGroup && (
										<CSelect id="nf-group" name="nf-group" disabled>
											<option>{_t('global.loading')}</option>
										</CSelect>
									)}
									{isErrorGroup && <span>{loadGroupsQuery.isError}</span>}
									{!isErrorGroup && !isLoadingGroup && (
										<CSelect id="nf-type" name="nf-type" onChange={onGroupChanged} value={groupId}>
											{loadGroupsQuery.data!.map((o: any) => (
												<option value={o.name} key={`group-${o.id}`}>
													{o.name}
												</option>
											))}
										</CSelect>
									)}
								</CFormGroup>
							</CCol>
						</CRow>
						<CRow>
							<CCol md={12} className="d-flex justify-content-end">
								<ButtonWithLoader
									isLoading={walletSettingsMutation.isLoading}
									onClick={callMutation}
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t('action.save')}
									className="btn-md"
									showText={false}
								/>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	);
};

export default WalletConfiguration;
