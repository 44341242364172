import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { extractErrorMessage, formatCurrency } from '../../helpers';
import PageLayout from '../../components/PageLayout';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast } from '../../actions';
import { fetchAffiliateCustomer, loadCustomers } from '../../services/BackendService';
import _t from 'counterpart';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { CCard, CCardBody, CCardHeader, CCardTitle, CCol, CRow } from '@coreui/react';
import Detail from '../../components/micro/Detail';
import CustomersTable from './CustomersTable';
import { ISorter } from '../../components/PaginationTable';

const AffiliatePage = () => {
	const params = useParams();
	const { id } = params as any;
	const [search, setSearch] = useState<string>('');
	const [orderBy, setOrderBy] = useState<string>('createdAt|DESC');
	const [page, setPage] = useState<number>(1);

	const customersItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_affiliates_table') ?? 10);
	const [limit, setLimit] = useState<number>(customersItemsPerPageFromLocalStorage);

	const offset = limit * page - limit;

	const dispatch = useAppDispatch();
	const history = useHistory();

	const showError = (errorMessage: string) => {
		const error = extractErrorMessage(errorMessage);
		dispatch(showErrorToast(error));
	};

	const queryClient = useQueryClient();

	const loadAffiliateQuery = useQuery(['customer', id], () => fetchAffiliateCustomer(id), {
		onError: (e: any) => {
			showError(e);
		},
	});

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_customers_table', limit.toString());
		await queryClient.invalidateQueries('customers');
		loadAffiliateQuery.refetch();
	};

	const referralsQuery = useQuery(['customers', search, id, offset, orderBy], () =>
		loadCustomers(search, limit, offset, id, orderBy, null)
	);

	const onPageChanged = (value: number) => {
		setPage(value);
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		setOrderBy(sortBy);
		setPage(1);
	};

	const onSearchChange = (value: string) => {
		setSearch(value);
	};

	if (loadAffiliateQuery.isLoading || loadAffiliateQuery.isIdle) {
		return <Loading />;
	}

	if (loadAffiliateQuery.isError) {
		return <Error onRetry={loadAffiliateQuery.refetch} />;
	}

	const onRowClicked = (user: any) => {
		history.push(`/customers/${user.id}`);
	};

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const { name, affiliateBalance, totalReferrals, totalVolumeTraded, depositTotal } = loadAffiliateQuery.data;
	return (
		<PageLayout title={name === ' ' ? _t('global.user-has-no-name') : name} linkTitleTo={`/customers/${id}`}>
			<CRow>
				<CCol md={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('global.info')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<dl>
								<Detail title={_t('affiliates.affiliate-balance')}>{formatCurrency(affiliateBalance, 'USD')}</Detail>
								<Detail title={_t('referrals.total-referrals')}>{totalReferrals}</Detail>
								<Detail title={_t('customer.total-volume-traded')}>{totalVolumeTraded}</Detail>
								<Detail title={_t('referrals.total-deposit')}>{formatCurrency(depositTotal, 'USD')}</Detail>
							</dl>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CCardTitle>{_t('referrals.title')}</CCardTitle>
						</CCardBody>
						<CCardBody>
							<CustomersTable
								data={referralsQuery.data?.customers || []}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								isLoading={referralsQuery.isLoading}
								pages={referralsQuery.data?.pages || 0}
								activePage={page}
								onRowClicked={onRowClicked}
								onSearchValueChanged={onSearchChange}
								showFilter
								filterValue={search}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(AffiliatePage);
