import React from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import {
	CCard,
	CCardBody,
	CCol,
	CForm,
	CFormGroup,
	CFormText,
	CInputGroup,
	CLabel,
	CRow,
	CSwitch,
	CTextarea,
} from '@coreui/react';
import { useLegacyState } from '../../helpers/customHooks';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { sendMessageToAllCustomers, sendMessageToCustomers } from '../../services/BackendService';
import { showErrorToast, showSuccessToast } from '../../actions';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';

interface State {
	message: string;
	accounts: string;
	sendToAll: boolean;
}

const MessagingPage = () => {
	const dispatch = useDispatch();

	const initialState = {
		message: '',
		accounts: '',
		sendToAll: false,
	};

	const [state, setState] = useLegacyState<State>(initialState);

	const { accounts, message, sendToAll } = state;

	const resetState = () => {
		setState(initialState);
	};

	const sendMessageMutation = useMutation(
		['send-message'],
		({ accounts, message }: { accounts: string; message: string }) => sendMessageToCustomers(accounts, message),
		{
			onSuccess: ({ messageRecipients, oneSignalRecipients }) => {
				resetState();
				dispatch(
					showSuccessToast(
						_t('messaging.message-sent-to-customers', {
							sent: messageRecipients,
							oneSignal: oneSignalRecipients,
						})
					)
				);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const sendMessageAllMutation = useMutation(
		['send-message-all'],
		(message: string) => sendMessageToAllCustomers(message),
		{
			onSuccess: ({ messageRecipients, oneSignalRecipients }) => {
				resetState();
				dispatch(
					showSuccessToast(
						_t('messaging.message-sent-to-customers', {
							sent: messageRecipients,
							oneSignal: oneSignalRecipients,
						})
					)
				);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const handleMessageChanged = (event: any) => {
		setState({
			message: event.target.value,
		});
	};

	const handleAccountsChanged = (event: any) => {
		setState({
			accounts: event.target.value,
		});
	};

	const toggleSendToAll = () => {
		setState({
			sendToAll: !sendToAll,
		});
	};

	const sendMessage = async (e: React.FormEvent) => {
		e.preventDefault();
		if (sendToAll) {
			sendMessageAllMutation.mutate(message);
		} else {
			sendMessageMutation.mutate({ accounts, message });
		}
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(sendMessageMutation.error, paramName);
	};

	return (
		<PageLayout title={_t.translate('sidebar.messaging')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<CForm onSubmit={sendMessage}>
								<div className="d-flex align-items-center mb-3">
									<CSwitch id="send-all" color="primary" variant="3d" onChange={toggleSendToAll} checked={sendToAll} />
									<span className="ml-2">{_t.translate('messaging.send-to-all')}</span>
								</div>
								<CFormGroup>
									<CLabel htmlFor="nf-accounts">{_t.translate('messaging.user-ids')}</CLabel>
									<CInputGroup>
										<CTextarea
											id="nf-accounts"
											name="nf-accounts"
											value={accounts || ''}
											onChange={handleAccountsChanged}
											disabled={sendToAll}
										/>
									</CInputGroup>
									<CFormText className="help-block">{_t.translate('messaging.user-ids-comment')}</CFormText>
									{findError('customers') && <CLabel className="text-danger">{findError('customers')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="nf-message">{_t.translate('messaging.message')}</CLabel>
									<CInputGroup>
										<CTextarea
											id="nf-message"
											name="nf-message"
											value={message || ''}
											onChange={handleMessageChanged}
										/>
									</CInputGroup>
									{findError('message') && <CLabel className="text-danger">{findError('message')}</CLabel>}
								</CFormGroup>

								<ButtonWithLoader
									isLoading={sendMessageMutation.isLoading}
									type="submit"
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t.translate('action.send')}
									className="mr-2"
								/>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(MessagingPage);
