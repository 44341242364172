import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CForm,
	CFormGroup,
	CLabel,
	CRow,
} from '@coreui/react';
import _t from 'counterpart';
import { setRiskManagementFilters, showErrorToast } from '../actions';
import { capitalize, extractErrorMessage, formatCurrency } from '../helpers';
import TrendingInstrumentsChart from '../components/TrendingInstrumentsChart';
import ProfitLossByMonthChart from '../components/ProfitLossByMonthChart';
import LotsPerInstrumentChart from '../components/LotsPerInstrumentChart';
import ExposureChart from '../components/ExposureChart';
import '../scss/components/riskManagementPage.scss';
import { useQuery } from 'react-query';
import { loadRiskStats, loadUserExposure } from '../services/RiskService';
import Loading from '../components/Loading';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import PageLayout from '../components/PageLayout';
import Error from '../components/Error';
import CustomSelect from '../components/CustomSelect';
import InputHeader from '../components/InputHeader';
import { loadGroups } from '../services/BackendService';
import { IRiskManagement } from 'src/reducers/filtersReducer';
import { shallowEqual } from 'react-redux';

type TableFilter = 'instruments-lots' | 'exposure';

const REFETCH_INTERVAL_MS = 1000;

const RiskManagementPage = () => {
	const [tableFilter, setTableFilter] = useState<TableFilter>('instruments-lots');
	const dispatch = useAppDispatch();
	const activeFilters = useAppSelector((state) => state.filters.riskManagementFilters, shallowEqual);

	const group = activeFilters.group?.value;
	const isDemo = activeFilters.isDemo?.value;

	const groupsQuery = useQuery(['risk-groups'], () => loadGroups());

	const setFilters = useCallback(
		(filters: Partial<IRiskManagement>) => {
			dispatch(setRiskManagementFilters(filters));
		},
		[dispatch]
	);

	const liveOrDemoOptions = useMemo(
		() => [
			{ value: 'false', label: 'live' },
			{ value: 'true', label: 'demo' },
		],
		[]
	);

	useEffect(() => {
		setFilters({
			isDemo: liveOrDemoOptions[0],
		});
	}, [setFilters, liveOrDemoOptions]);

	const riskStatsQuery = useQuery(['risk-stats', activeFilters], () => loadRiskStats(group, isDemo), {
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
		refetchInterval: REFETCH_INTERVAL_MS,
	});

	const exposureStatsQuery = useQuery('exposure-stats', () => loadUserExposure(group, isDemo), {
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
		refetchInterval: REFETCH_INTERVAL_MS,
	});

	const onErrorRetry = () => {
		riskStatsQuery.refetch();
		exposureStatsQuery.refetch();
	};

	if (riskStatsQuery.isLoading || exposureStatsQuery.isLoading || groupsQuery.isLoading) {
		return <Loading />;
	}

	if (riskStatsQuery.isError || exposureStatsQuery.isError || groupsQuery.isLoading) {
		return <Error onRetry={onErrorRetry} />;
	}

	const handleGroupChanged = (group: any) => {
		setFilters({
			group,
		});
	};

	const resetGroup = () => {
		setFilters({
			group: undefined,
		});
	};

	const handleLiveOrDemoChanged = (liveOrDemo: any) => {
		setFilters({
			isDemo: liveOrDemo,
		});
	};

	const { exposures } = exposureStatsQuery.data!;
	const { baseStats, trendingInstruments, lotsPerInstrument, profitLossByMonth } = riskStatsQuery.data!;
	const { openLots, openPositions, totalProfitLoss } = baseStats;

	const groupOptions =
		groupsQuery.data?.map((g) => {
			return {
				value: g.name,
				label: g.name,
			};
		}) ?? [];

	return (
		<PageLayout title={_t('sidebar.risk-management')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardBody>
							<CForm>
								<CRow>
									<CCol md={4}>
										<CFormGroup>
											<InputHeader labelText={_t('global.group')} resetFilter={resetGroup} />
											<CustomSelect
												value={activeFilters.group ?? null}
												options={groupOptions}
												onChange={handleGroupChanged}
												whiteBackground
											/>
										</CFormGroup>
									</CCol>
									<CCol md={4}>
										<CFormGroup>
											<div className="d-flex align-items-center justify-content-between">
												<CLabel className="m-0" htmlFor="regdate">
													{_t('risk-management.account-type')}
												</CLabel>
											</div>
											<CustomSelect
												value={activeFilters.isDemo ?? null}
												options={liveOrDemoOptions}
												onChange={handleLiveOrDemoChanged}
												whiteBackground
											/>
										</CFormGroup>
									</CCol>
								</CRow>
							</CForm>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			<CRow>
				<CCol md={4}>
					<CCard className="card--stat card--blue">
						<div className="card__holder">
							<div className="card__stats">
								<span className="card__value">{`${parseFloat(openLots).toFixed(2)} ${_t('customer.lots')}`}</span>
								<span className="card__title">{_t('risk-management.total-open-lots')}</span>
							</div>
						</div>
					</CCard>
				</CCol>
				<CCol md={4}>
					<CCard className="card--stat card--yellow">
						<div className="card__holder">
							<div className="card__stats">
								<span className="card__value">{openPositions.toString()}</span>
								<span className="card__title">{_t('dashboard.open-positions')}</span>
							</div>
						</div>
					</CCard>
				</CCol>
				<CCol md={4}>
					<CCard className="card--stat card--green">
						<div className="card__holder">
							<div className="card__stats">
								<span className="card__value">{formatCurrency(totalProfitLoss)}</span>
								<span className="card__title">{capitalize(_t('risk-management.total-profit-loss'))}</span>
							</div>
						</div>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={6}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center trending-header">
							<CCardTitle>{_t('risk-management.trending-instruments')}</CCardTitle>
						</CCardHeader>
						<CCardBody className="trending-body">
							<TrendingInstrumentsChart trendingInstruments={trendingInstruments} />
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md={6}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center exposure-lots-header">
							<CCardTitle>
								{tableFilter === 'instruments-lots' && _t('risk-management.open-lots-per-instrument')}
								{tableFilter === 'exposure' && _t('risk-management.exposure-per-instrument')}
							</CCardTitle>
							<div>
								<CDropdown className="float-sm-right">
									<CDropdownToggle className="w-100 btn-filter" color="light" variant="outline" caret>
										{_t(`risk-management.${tableFilter}`)}
									</CDropdownToggle>
									<CDropdownMenu>
										<CDropdownItem onClick={() => setTableFilter('instruments-lots')}>
											{_t('risk-management.instruments-lots')}
										</CDropdownItem>
										<CDropdownItem onClick={() => setTableFilter('exposure')}>
											{_t('risk-management.exposure')}
										</CDropdownItem>
									</CDropdownMenu>
								</CDropdown>
							</div>
						</CCardHeader>
						<CCardBody className="exposure-lots-body">
							{tableFilter === 'instruments-lots' && <LotsPerInstrumentChart lotsPerInstrument={lotsPerInstrument} />}
							{tableFilter === 'exposure' && <ExposureChart exposures={exposures} />}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('risk-management.profit-loss-per-month')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<ProfitLossByMonthChart profitLossByMonth={profitLossByMonth} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default RiskManagementPage;
