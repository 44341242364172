import { useEffect, useReducer, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { AppState } from '../reducers';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import wsService from '../services/WebsocketService';
import {
	updatePositionCacheFromWSMessage,
	updatePositionsCacheFromWSMessage,
	updatePositionsFromPositionClose,
	updatePositionsFromPositionStatus,
} from './positionHelpers';
import { IWallet, IWalletWithBalance } from '../pages/wallets/types';
import { IAccountStatusMessage } from './types';
import { isSet } from '../helpers';

export const useLegacyState = <D>(initialState: D) =>
	useReducer((state: object, update: any) => ({ ...state, ...update }), initialState);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const useQueryParams = () => {
	const [queryParams, setQueryParams] = useState<URLSearchParams | null>(null);
	const location = useLocation();
	useEffect(() => {
		setQueryParams(new URLSearchParams(location.search));
	}, [location.search]);

	return queryParams;
};

export const useHeartbeat = () => {
	const token = useAppSelector((state) => state.user.token);

	useEffect(() => {
		const interval = setInterval(() => {
			wsService.sendPing(token!);
		}, 3000);
		return () => clearInterval(interval);
	}, [token]);
};

export const useLiveAccountsUpdates = (wallets: Array<IWalletWithBalance>) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();

	useEffect(() => {
		const accountsHandler = (message: IAccountStatusMessage) => {
			queryClient.setQueriesData(['wallets'], (oldData: any) => {
				if (!isSet(oldData) || !isSet(oldData.wallets)) {
					return oldData;
				}

				const foundWalletIndex = oldData.wallets.findIndex((w: any) => w.username === message.accountId);
				if (foundWalletIndex === -1) {
					return oldData;
				}

				const updatedWallets = [...oldData.wallets];
				updatedWallets[foundWalletIndex] = {
					...updatedWallets[foundWalletIndex],
					balance: message.balance,
					profitLoss: message.profitLoss,
					equity: message.equity,
					margin: message.margin,
				};

				return {
					pages: oldData.pages,
					wallets: updatedWallets,
				};
			});
		};

		wsService.subscribeToAccountsUpdates(token!);
		wsService.addAccountsListener(accountsHandler);

		return () => {
			wsService.unsubscribeFromAccountsUpdates();
			wsService.removeAccountsListener(accountsHandler);
		};
	}, [queryClient, token, wallets]);
};

export const useLiveAccountUpdates = (username: string, id: string) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();

	useEffect(() => {
		const accountHandler = (message: IAccountStatusMessage) => {
			queryClient.setQueriesData(['customer-wallet', id], (oldData: any) => {
				if (!isSet(oldData)) {
					return oldData;
				}

				if (username === message.accountId) {
					return {
						performance: oldData.performance,
						wallet: {
							...oldData.wallet,
							equity: message.equity,
							margin: message.margin,
							marginFree: message.marginFree,
							profitLoss: message.profitLoss,
							balance: message.balance,
							credit: message.credit,
						},
					}
				};
				return oldData;
			});
		};

		wsService.subscribeToAccountsUpdates(token!);
		wsService.addAccountsListener(accountHandler);

		return () => {
			wsService.unsubscribeFromAccountsUpdates();
			wsService.removeAccountsListener(accountHandler);
		};
	}, [queryClient, token, username, id]);
};

export const useLiveOpenPositionUpdates = () => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();

	useEffect(() => {
		const positionsHandler = (message: any) => {
			queryClient.setQueriesData('open-positions', (oldData: any) =>
				updatePositionsCacheFromWSMessage(oldData, message)
			);
		};

		wsService.subscribeToPositionUpdates(token!);
		wsService.addPositionsListener(positionsHandler);

		return () => {
			wsService.unsubscribeFromPositionUpdates();
			wsService.removePositionsListener(positionsHandler);
		};
	}, [queryClient, token]);
};

export const useLiveOpenCustomerPositionUpdate = (customerId: string) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();

	useEffect(() => {
		const positionsHandler = (message: any) => {
			queryClient.setQueriesData(['customer-open-positions', customerId], (oldData: any) => {
				const { messageType, ...rest } = message;
				if (messageType === 'PositionOpen') {
					const wallets = queryClient.getQueryData<Array<IWallet>>(['customer-wallets', customerId]);
					if (wallets) {
						const walletsUsernames = wallets.map((w) => w.username);
						if (walletsUsernames.indexOf(message.accountId) !== -1) {
							const { positionId } = rest;
							const positions = [...oldData.positions];
							positions.push({
								id: positionId,
								...rest,
							});
							return {
								...oldData,
								positions,
							};
						}
					}
				}
				if (messageType === 'PositionStatus') {
					const positions = updatePositionsFromPositionStatus(oldData.positions, message);
					return {
						...oldData,
						positions,
					};
				}
				if (messageType === 'PositionClose') {
					const positions = updatePositionsFromPositionClose(oldData.positions, message);
					return {
						...oldData,
						positions,
					};
				}
				return oldData;
			});
		};

		wsService.subscribeToPositionUpdates(token!);
		wsService.addPositionsListener(positionsHandler);

		return () => {
			wsService.unsubscribeFromPositionUpdates();
			wsService.removePositionsListener(positionsHandler);
		};
	}, [queryClient, token, customerId]);
};

export const useLivePositionUpdates = (positionId: number) => {
	const token = useAppSelector((state) => state.user.token);
	const queryClient = useQueryClient();

	useEffect(() => {
		const positionsHandler = (message: any) => {
			queryClient.setQueriesData(['position', positionId], (oldData: any) =>
				updatePositionCacheFromWSMessage(oldData, message)
			);
		};

		wsService.subscribeToPositionUpdates(token!);
		wsService.addPositionsListener(positionsHandler);

		return () => {
			wsService.unsubscribeFromPositionUpdates();
			wsService.removePositionsListener(positionsHandler);
		};
	}, [queryClient, token, positionId]);
};
