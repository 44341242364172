import { extractErrorMessage } from '../../helpers';
import { useQuery } from 'react-query';
import { loadCustomerWallets } from '../../services/BackendService';
import { showErrorToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';

export const useCustomerWallets = (customerId: string) => {
	const dispatch = useAppDispatch();

	const walletsQuery = useQuery(
		['customer-wallets', customerId],
		async () => {
			const data = await loadCustomerWallets(customerId);
			return data;
		},
		{
			onError: (error: any) => {
				const msg = extractErrorMessage(error);
				dispatch(showErrorToast(msg));
			},
		}
	);
	return walletsQuery;
};
