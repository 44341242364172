import React, { useState } from 'react';
import {
	CButton,
	CForm,
	CAlert,
	CFormGroup,
	CLabel,
	CInput,
	CModal,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CModalTitle,
} from '@coreui/react';
import _t from 'counterpart';
import ButtonWithLoader from './ButtonWithLoader';
import { useAppDispatch } from '../helpers/customHooks';
import { useMutation } from 'react-query';
import { updateCustomer } from '../services/BackendService';
import { extractErrorMessage, findErrorFromValidation } from '../helpers';
import { showSuccessToast, showErrorToast } from '../actions';

interface State {
	password: string | number | undefined;
	repeatPassword: string | number | undefined;
	passwordError?: string;
}

interface Props {
	show: boolean;
	customerId: string;
	onClose: (refetch?: boolean) => any;
}

const UpdatePassword = ({ show, customerId, onClose }: Props) => {
	const initalState = {
		password: '',
		repeatPassword: '',
		passwordError: undefined,
	};

	const [formState, setFormState] = useState<State>(initalState);

	const dispatch = useAppDispatch();

	const updatePasswordMutation = useMutation(
		['updated-user'],
		() =>
			updateCustomer(customerId, {
				newPassword: formState.password,
				repeatPassword: formState.repeatPassword,
			}),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t.translate('customer.customer-successful-update')));
				setFormState(initalState);
				onClose(false);
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const onUpdatePasswordWindowClosed = () => {
		setFormState(initalState);
		onClose(false);
		updatePasswordMutation.reset();
	};

	const tryUpdatePassword = async (e: React.FormEvent) => {
		e.preventDefault();
		updatePasswordMutation.mutate();
	};

	const updatePassword = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLTextAreaElement;
		setFormState({ ...formState, passwordError: undefined, password: target.value });
	};

	const updateRepeatPassword = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLTextAreaElement;
		setFormState({ ...formState, passwordError: undefined, repeatPassword: target.value });
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updatePasswordMutation.error, paramName);
	};

	return (
		<CModal show={show} onClose={onUpdatePasswordWindowClosed} closeOnBackdrop={false}>
			<CModalHeader closeButton>
				<CModalTitle>{_t('update-password.title')}</CModalTitle>
			</CModalHeader>
			<CForm onSubmit={tryUpdatePassword}>
				<CModalBody>
					<CAlert show={Boolean(formState.passwordError)} color="danger" closeButton>
						{formState.passwordError}
					</CAlert>

					<CFormGroup>
						<CLabel htmlFor="nf-password">{_t('update-password.new-password')}</CLabel>
						<CInput
							type="password"
							id="nf-password"
							name="nf-password"
							placeholder={_t('update-password.password')}
							autoComplete="password"
							value={formState.password}
							onChange={updatePassword}
						/>
						{findError('newPassword') && <CLabel className="text-danger">{findError('newPassword')}</CLabel>}
					</CFormGroup>
					<CFormGroup>
						<CLabel htmlFor="nf-repeat-password">{_t('update-password.repeat-password')}</CLabel>
						<CInput
							type="password"
							id="nf-repeat-password"
							name="nf-repeat-password"
							placeholder={_t('update-password.password')}
							autoComplete="password"
							value={formState.repeatPassword}
							onChange={updateRepeatPassword}
						/>
						{findError('repeatPassword') && <CLabel className="text-danger">{findError('repeatPassword')}</CLabel>}
					</CFormGroup>
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={updatePasswordMutation.isLoading}
						buttonColor="primary"
						type="submit"
						spinnerColor="secondary"
						title={_t.translate('global.update')}
						className="mr-2"
					/>
					<CButton color="light" variant="outline" onClick={onUpdatePasswordWindowClosed}>
						{_t('global.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
};

export default UpdatePassword;
