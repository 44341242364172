import React, { useState, useEffect } from 'react';
import _t from 'counterpart';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import {
	CModal,
	CModalHeader,
	CModalBody,
	CForm,
	CFormGroup,
	CLabel,
	CInputGroup,
	CInput,
	CModalFooter,
	CModalTitle,
	CButton,
	CSpinner,
} from '@coreui/react';
import { findErrorFromValidation, extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import { useMutation } from 'react-query';
import { createDowntime, updateDowntime } from '../../services/BackendService';
import { showSuccessToast, showErrorToast } from '../../actions';
import { IDowntime } from './types';
import ToggleSelect, { ISelectOption } from '../../components/ToggleSelect';

interface IProps {
	downtime: IDowntime | null;
	show: boolean;
	hidePanel: (refetch: boolean) => void;
	allSymbols: Array<string>;
}

interface IState {
	startDate: Date;
	endDate: Date;
	title: string;
}

const MutateDowntimeModal = ({ show, hidePanel, downtime, allSymbols }: IProps) => {
	const symbolsOptions: Array<ISelectOption> = allSymbols.map((s: string) => {
		const enabled = !allSymbols.includes(s);
		return {
			label: s,
			value: s,
			enabled,
		};
	});

	const [state, setState] = useState<IState>({
		startDate: downtime ? new Date(downtime.startDate) : new Date(),
		endDate: downtime ? new Date(downtime.endDate) : new Date(),
		title: downtime?.title || '',
	});

	const [inputValue, setInputValue] = useState<string>('');
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [usedSymbols, setUsedSymbols] = useState<Array<ISelectOption>>([]);

	useEffect(() => {
		const mappedValues = symbolsOptions.map((c) => {
			c.enabled = Boolean(downtime?.symbols.find((v) => v === c.value));
			return c;
		});

		if (downtime?.symbols.length === symbolsOptions.length) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}

		setUsedSymbols(mappedValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	const { title, startDate, endDate } = state;

	const dispatch = useAppDispatch();

	const symbolsDTO = usedSymbols.filter((s) => s.enabled).map((s) => s.value);
	const mutateDowntime = useMutation(
		['create-update-downtime'],
		() => {
			if (!downtime) {
				return createDowntime(title, startDate.toISOString(), endDate.toISOString(), symbolsDTO);
			}
			return updateDowntime(downtime.id, title, startDate.toISOString(), endDate.toISOString(), symbolsDTO);
		},
		{
			onSuccess: () => {
				hidePanel(true);
				if (!downtime) {
					dispatch(showSuccessToast(_t.translate('downtimes.downtime-created-successfully')));
				}
				dispatch(showSuccessToast(_t.translate('downtimes.downtime-updated-successfully')));
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const handleStartDateChange = (startDate: Moment | string) => {
		if (typeof startDate !== 'string') {
			setState({ ...state, startDate: startDate.set('second', 0).set('millisecond', 0).toDate() });
		}
	};

	const handleEndDateChange = (endDate: Moment | string) => {
		if (typeof endDate !== 'string') {
			setState({ ...state, endDate: endDate.set('second', 0).set('millisecond', 0).toDate() });
		}
	};

	const setTitle = (e: any) => {
		setState({ ...state, title: e.target.value });
	};

	const findError = (name: string) => {
		return findErrorFromValidation(mutateDowntime.error, name);
	};

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();
		mutateDowntime.mutate();
	};

	const onOptionsChanged = (options: Array<ISelectOption>) => {
		setUsedSymbols(options);
		const disabledIndex = options.findIndex((o) => !o.enabled);
		setSelectAll(disabledIndex === -1);
	};

	return (
		<CModal show={show} onClose={hidePanel}>
			<CModalHeader className="d-flex flex-column" closeButton>
				<CModalTitle>
					{downtime ? _t.translate('downtimes.update-downtime') : _t.translate('downtimes.create-downtime')}
				</CModalTitle>
			</CModalHeader>
			<CForm onSubmit={submitForm}>
				<CModalBody>
					<CFormGroup>
						<CLabel htmlFor="nf-name">{_t('global.name')}</CLabel>
						<CInputGroup>
							<CInput
								type="text"
								id="title"
								name="title"
								placeholder="Title"
								value={state.title || ''}
								onChange={setTitle}
							/>
						</CInputGroup>
						{findError('title') && <CLabel className="text-danger">{findError('title')}</CLabel>}
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('competitions.columns.start')}</CLabel>
						<Datetime
							value={state.startDate}
							onChange={handleStartDateChange}
							dateFormat="DD/MM/YYYY"
							timeFormat="HH:mm"
							timeConstraints={{ minutes: { min: 1, max: 60, step: 1 } }}
							inputProps={{ readOnly: true }}
						/>
						{findError('startDate') && <CLabel className="text-danger">{findError('startDate')}</CLabel>}
					</CFormGroup>
					<CFormGroup>
						<CLabel>{_t('competitions.columns.end')}</CLabel>
						<Datetime
							value={state.endDate}
							onChange={handleEndDateChange}
							dateFormat="DD/MM/YYYY"
							timeFormat="HH:mm"
							timeConstraints={{ minutes: { min: 1, max: 60, step: 1 } }}
							inputProps={{ readOnly: true }}
						/>
						{findError('endDate') && <CLabel className="text-danger">{findError('endDate')}</CLabel>}
					</CFormGroup>
					<CLabel>{_t('global.symbols')}</CLabel>
					<ToggleSelect
						options={usedSymbols}
						onOptionsChanged={onOptionsChanged}
						inputValue={inputValue}
						onInputValueChanged={setInputValue}
						selectAll={selectAll}
						onSelectChanged={setSelectAll}
					/>
					{findError('symbols') && <CLabel className="text-danger">{findError('symbols')}</CLabel>}
				</CModalBody>
				<CModalFooter>
					<CButton color="primary" disabled={mutateDowntime.isLoading} type="submit">
						{mutateDowntime.isLoading && (
							<div className="d-flex align-items-center">
								<CSpinner color="secondary" size="sm" />
								&nbsp;{_t('global.please-wait')}
							</div>
						)}
						{!mutateDowntime.isLoading && (downtime ? _t('action.update') : _t('action.create'))}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
};

export default MutateDowntimeModal;
