import React from 'react';
import Loading from '../../components/Loading';
import _t from 'counterpart';
import { loadDownlineStats } from '../../services/BackendService';
import { useQuery } from 'react-query';

export interface AnalyticsRecord {
	downloads: string;
	level: number;
	pushNotifications: string;
	registrations: string;
}

const AffiliationPerformance = ({ filters, onFetch }: { filters: any; onFetch: Function }) => {
	const analyticsQuery = useQuery(['analytics'], () => loadDownlineStats(), {
		refetchOnWindowFocus: false,
		onSuccess: (data: any) => {
			if (data) {
				onFetch(data.length);
			}
		},
		onError: () => onFetch(0),
	});

	if (analyticsQuery.isLoading || analyticsQuery.isIdle) {
		return <Loading />;
	}

	if (analyticsQuery.isError) {
		return null;
	}

	const { data } = analyticsQuery;

	return (
		<div className="table-responsive">
			<table className="table table-striped mt-4">
				<thead>
					<tr>
						<th scope="col">{_t('analytics.level')}</th>
						<th scope="col">{_t('analytics.registrations')}</th>
						<th scope="col">{_t('analytics.push-accepted')}</th>
						<th scope="col">{_t('analytics.app-downloaded')}</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.map((s: any) => (
							<tr key={`stats-${s.level}`}>
								<td>{s.level}</td>
								<td>{s.registrations}</td>
								<td>{s.pushNotifications}</td>
								<td>{s.downloads}</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

export default AffiliationPerformance;
