import React, { useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Content from '../components/Content';
import { requestLoadUser } from '../actions';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import { isImpersonatedSelector } from '../selectors';
import wsService from '../services/WebsocketService';

const MainPage = () => {
	const isImpersonated = useAppSelector(isImpersonatedSelector);
	const dispatch = useAppDispatch();

	useEffect(() => {
		wsService.connect();
	}, []);

	useEffect(() => {
		if (!isImpersonated) {
			dispatch(requestLoadUser());
		}
	}, [dispatch, isImpersonated]);

	return (
		<div className="c-app-c-default-layout">
			<Sidebar />
			<div className="c-wrapper">
				<Header />
				<div className="c-body">
					<Content />
				</div>
			</div>
		</div>
	);
};

export default MainPage;
