import { put, takeLatest, call, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import {
	login,
	impersonate,
	loadUserSelf,
	updateRefkey,
	updateUserSelf,
	getOAuthTokensWithCode,
} from '../services/BackendService';
import {
	LOGIN_REQUESTED,
	UPDATE_REFKEY_REQUEST,
	TOKEN_LOGIN_REQUESTED,
	LOAD_USER_REQUESTED,
	REQUEST_OAUTH_TOKENS,
	successLogin,
	failureLogin,
	showErrorToast,
	successUpdateRefkey,
	failureUpdateRefkey,
	successLoadUser,
	failureLoadUser,
	IMPERSONATION_REQUEST,
	IMPERSONATION_STOP,
	successImpersonation,
	failureImpersonation,
	SET_LANGUAGE,
	requestChangeLanguage,
	LOGOUT,
} from '../actions';
import { extractErrorMessage } from '../helpers';
import { oAuthClientId, oAuthClientSecret } from '../helpers/oAuthLogin';
import Analytics from '../helpers/analytics';

function* loginUser(action: AnyAction) {
	try {
		let token;
		if (action.token) {
			token = action.token;
		} else {
			const { username, password } = action;
			// @ts-ignore
			const response = yield call(login, {
				username,
				password,
			});
			token = response.token;
		}

		const { refreshToken, user } = yield call(loadUserSelf, token);
		yield put(requestChangeLanguage(user.communicationLanguage, true));
		yield put(successLogin(user, token, refreshToken));
	} catch (e) {
		yield put(failureLogin(extractErrorMessage(e)));
	}
}

function* requestUpdateRefkey(action: AnyAction) {
	try {
		yield call(updateRefkey, action.refKey);
		yield put(successUpdateRefkey());
	} catch (e) {
		yield put(failureUpdateRefkey(extractErrorMessage(e)));
		yield put(showErrorToast(extractErrorMessage(e)));
	}
}

function* impersonateUser(action: AnyAction) {
	try {
		// @ts-ignore
		const response = yield call(impersonate, action.customerId);
		const { user, token } = response;

		yield put(successImpersonation(user, token));
		yield put(push('/'));
	} catch (e) {
		const message = extractErrorMessage(e);

		yield put(failureImpersonation(message));
		yield put(showErrorToast(message));
	}
}

function* updateUserLanguage(action: AnyAction) {
	try {
		const { language, skipUserUpdate } = action;
		if (!skipUserUpdate) {
			const { user } = yield call(updateUserSelf, { communicationLanguage: language });
			yield put(successLoadUser(user));
		}
	} catch (e) {
		yield put(showErrorToast((e as any).message));
	}
}

function* stopImpersonatingUser() {
	try {
		const { token, refreshToken } = yield select((s) => s.user);
		const { user } = yield call(loadUserSelf, token);
		yield put(successLogin(user, token, refreshToken));
		yield put(push('/'));
	} catch (e) {
		yield put(failureLogin(extractErrorMessage(e)));
	}
}

function* requestLoadUser() {
	try {
		const { token } = yield select((s) => s.user);
		const { user } = yield call(loadUserSelf, token);
		yield put(requestChangeLanguage(user.communicationLanguage, true));
		yield put(successLoadUser(user));
		Analytics.identifyUser(user.id);
	} catch (e) {
		yield put(failureLoadUser(extractErrorMessage(e)));
	}
}

function* requestOAuthTokensSaga(action: AnyAction) {
	const { code } = action;
	const redirectUri = `${window.location.origin}/login`;
	const grantType = 'authorization_code';

	try {
		window.history.replaceState({}, document.title, '/');
		const { access_token: accessToken, refresh_token: refreshToken } = yield call(
			getOAuthTokensWithCode,
			code,
			oAuthClientId as string,
			oAuthClientSecret as string,
			redirectUri,
			grantType
		);
		const { user } = yield call(loadUserSelf, accessToken);
		yield put(requestChangeLanguage(user.communicationLanguage, true));
		yield put(successLogin(user, accessToken, refreshToken));
		Analytics.identifyUser(user.id);
	} catch (e) {
		yield put(failureLogin(extractErrorMessage(e)));
		yield put(showErrorToast(extractErrorMessage(e)));
	}
}

function* logoutSaga(action: AnyAction) {
	Analytics.resetUserData();
	yield;
}

function* userSaga() {
	yield takeLatest(LOGIN_REQUESTED, loginUser);
	yield takeLatest(IMPERSONATION_REQUEST, impersonateUser);
	yield takeLatest(IMPERSONATION_STOP, stopImpersonatingUser);
	yield takeLatest(TOKEN_LOGIN_REQUESTED, loginUser);
	yield takeLatest(UPDATE_REFKEY_REQUEST, requestUpdateRefkey);
	yield takeLatest(LOAD_USER_REQUESTED, requestLoadUser);
	yield takeLatest(SET_LANGUAGE, updateUserLanguage);
	yield takeLatest(REQUEST_OAUTH_TOKENS, requestOAuthTokensSaga);
	yield takeLatest(LOGOUT, logoutSaga);
}

export default userSaga;
