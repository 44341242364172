import { FormEvent } from 'react';

export interface ISize {
	left: string;
	right: string;
}

export enum InstrumentCategory {
	CURRENCIES = 'currencies',
	COMMODITIES = 'commodities',
	INDICES = 'indices',
	CRYPTOS = 'cryptos',
	EQUITIES = 'equities',
}

export const swapModes = [
	{ value: 'DISABLED', label: 'DISABLED' },
	{ value: 'POINTS', label: 'POINTS' },
];

export const swapDays = [
	{ value: 0, label: 'SUNDAY' },
	{ value: 1, label: 'MONDAY' },
	{ value: 2, label: 'TUESDAY' },
	{ value: 3, label: 'WEDNESDAY' },
	{ value: 4, label: 'THURSDAY' },
	{ value: 5, label: 'FRIDAY' },
	{ value: 6, label: 'SATURDAY' },
	{ value: null, label: 'NONE' },
];

export interface IInstrument {
	profitCalcMode: string;
	marginCalcMode: string;
	stakeMode: string;
	symbol: string;
	category: InstrumentCategory;
	decimals: string;
	contractSize: string | number;
	/** profit currency, in FOREX the quoted currency, for example USD in EURUSD */
	currency: string;
	/** margin currency, in FOREX the base currency, for example EUR in EURUSD */
	marginCurrency: string;
	sizes: Array<ISize>;
	tradingHours: Array<ITradingHoursRange>;
	status: string;
	marginPercentage: string | number;
	hedgedMarginPercentage: string | number;
	sort: string;
	swapMode: string;
	swapLong: string | number;
	swapShort: string | number;
	swap3DayWeekday: number | null;
	minOrderSize: string;
	maxOrderSize: string;
	stepOrderSize: string;
	icon: IUploadedFile | null;
	iconUrl: string | null;
}

export type InstrumentWithoutSort = Omit<IInstrument, 'sort'>;

export interface IFormGroupInputProps {
	label: string;
	value: string;
	onChange: (formEvent: FormEvent) => void;
	placeholder: string;
	type: string;
	disabled?: boolean;
	error: string | null;
	className?: string;
}

export interface IFormGroupSizesProps {
	index: number;
	onRemoveSize: (index: number) => void;
	onChange: (index: number, value: ISize) => void;
	size: ISize;
}

interface ITradingTimeUnit {
	day: string;
	hour: string;
	minute: string;
}

export interface IInstrumentSort {
	[symbol: string]: number;
}

export interface ITradingHoursRange {
	startTime: ITradingTimeUnit;
	endTime: ITradingTimeUnit;
}

export const categories = [
	{ label: 'currencies', value: 'currencies' },
	{ label: 'indices', value: 'indices' },
	{ label: 'equities', value: 'equities' },
	{ label: 'commodities', value: 'commodities' },
	{ label: 'cryptos', value: 'cryptos' },
];

export const calculationTypes = [
	{ label: 'FOREX', value: 'FOREX' },
	{ label: 'CFD', value: 'CFD' },
];

export const stakeModes = [
	{ label: 'Spread Betting', value: 'SPREAD_BETTING' },
	{ label: 'Coins', value: 'COINS' },
	{ label: 'Shares', value: 'SHARES' },
];

export const statusClassNames: any = {
	ENABLED: 'success',
	CLOSE_ONLY: 'danger',
	DISABLED: 'danger',
};

export const statuses = [
	{ label: 'ENABLED', value: 'ENABLED' },
	{ label: 'CLOSE_ONLY', value: 'CLOSE_ONLY' },
	{ label: 'DISABLED', value: 'DISABLED' },
];

export interface IUploadedFile {
	blob: Blob;
	data: any;
	name: string;
	preview: string;
}
