import React, { useState, useEffect } from 'react';
import _t from 'counterpart';
import { CCard, CCardBody, CCol, CContainer, CRow, CButton, CBadge, CLink } from '@coreui/react';
import { useQuery, useMutation } from 'react-query';
import { loadPaymentProviders, updatePaymentProvidersSort } from '../../services/BackendService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IPaymentProvider, IPaymentProviderSort } from './types';
import CIcon from '@coreui/icons-react';
import NetworkError from '../../components/NetworkError';
import { AxiosError } from 'axios';
import InlineSpinner from '../../components/InlineSpinner';
import Loading from '../../components/Loading';
import Error from '../../components/Error';

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const arrayToPspSort = (payementProviders: Array<IPaymentProvider>): IPaymentProviderSort => {
	return payementProviders.reduce((acc: IPaymentProviderSort, provider, index) => {
		acc[provider.id] = index;
		return acc;
	}, {});
};

const PaymentProvidersPage = () => {
	const [reorderedProviders, setReorderedProviders] = useState<Array<IPaymentProvider> | null>(null);
	const { data, isLoading, isError, isIdle, refetch } = useQuery(['payment-provders'], () => loadPaymentProviders());

	const updateProviderOrderMutation = useMutation(
		(providerSort: IPaymentProviderSort) => updatePaymentProvidersSort(providerSort),
		{
			onSuccess: () => {
				refetch();
			},
			onError: () => {
				setReorderedProviders(null);
				refetch();
			},
		}
	);

	const { mutate } = updateProviderOrderMutation;

	useEffect(() => {
		if (reorderedProviders !== null) {
			const providerSort = arrayToPspSort(reorderedProviders);
			mutate(providerSort);
		}
	}, [reorderedProviders, mutate]);

	const handleDragEnd = (result: any) => {
		if (result.source && result.destination && result.source.index !== result.destination.index) {
			const newReorderedProviders = reorder(reorderedProviders ?? data!, result.source.index, result.destination.index);
			setReorderedProviders(newReorderedProviders);
		}
	};

	const renderProviderRow = (provider: IPaymentProvider) => {
		const { configKeys, configurationKeyValues, id } = provider;
		const keysToArray = Object.keys(configKeys).map((k) => {
			return {
				key: k,
				required: configKeys[k],
			};
		});
		const requiredKeys = keysToArray.filter((k) => k.required).map((k) => k.key);

		const missingRequiredKeys = requiredKeys.filter((k: string) => !configurationKeyValues[k]).length > 0;
		return (
			<>
				<td className="text-nowrap" style={{ width: '100%' }}>
					<CIcon name="cis-grip-dots-vertical" className="mr-1" />
					{provider.id}
				</td>
				<td>
					{missingRequiredKeys ? (
						<CBadge color="danger">{_t('global.disabled')}</CBadge>
					) : (
						<CBadge color="success">{_t('global.enabled')}</CBadge>
					)}
				</td>
				<td>
					<CLink href={`/payment-providers/${id}`}>
						<CButton className="mr-2" color="primary" size="sm" type="button" title="Edit payment provider">
							<CIcon name="cil-pencil" size="sm" />
						</CButton>
					</CLink>
				</td>
			</>
		);
	};

	const getItemStyle = (isDragging: boolean, draggableStyle: any, gray: boolean) => ({
		background: isDragging || gray ? '#f5f5f5' : 'white',
		...draggableStyle,
	});

	const renderDraggableRow = (provider: IPaymentProvider, index: number) => {
		return (
			<Draggable key={provider.id} draggableId={provider.id} index={index}>
				{(provided, snapshot) => (
					<tr
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index % 2 === 0)}
					>
						{renderProviderRow(provider)}
					</tr>
				)}
			</Draggable>
		);
	};

	const renderTable = (paymentProviders: Array<IPaymentProvider>) => {
		return (
			<div className="position-relative table-responsive">
				<table className="table" style={{ tableLayout: 'auto' }}>
					<thead>
						<tr>
							<th style={{ width: '60%' }}>{_t('payment-providers.payment-provider-name')}</th>
							<th style={{ width: '20%' }}>{_t('global.status')}</th>
							<th style={{ width: '20%' }}>{_t('global.actions')}</th>
						</tr>
					</thead>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<tbody {...provided.droppableProps} ref={provided.innerRef}>
									{paymentProviders.map(renderDraggableRow)}
									{provided.placeholder}
								</tbody>
							)}
						</Droppable>
					</DragDropContext>
				</table>
			</div>
		);
	};

	const onErrorRetry = () => {
		refetch();
	};

	if (isLoading || isIdle) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<CContainer fluid className="c-main main-holder">
			<CRow>
				<CCol>
					<h2 className="c-main__title">{_t.translate('sidebar.payment-providers')}</h2>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CCard className="card--with-top-tabs rounded-top-8">
						<CCardBody>
							<div className="my-2 d-flex justify-content-between align-items-center">
								<div className="my-2">
									{updateProviderOrderMutation.isError && (
										<NetworkError error={updateProviderOrderMutation.error as AxiosError} noMargin />
									)}
									{updateProviderOrderMutation.isLoading && <InlineSpinner />}
									{(updateProviderOrderMutation.isSuccess || updateProviderOrderMutation.isIdle) && (
										<span className="text-muted">{_t.translate('payment-providers.dnd-to-reorder')}</span>
									)}
								</div>
							</div>
							{!isLoading && !isError && !isIdle && renderTable(reorderedProviders ?? data!)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</CContainer>
	);
};

export default PaymentProvidersPage;
