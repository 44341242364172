export enum DashboardPeriods {
	Last7Days = 'LAST_7_DAYS',
	Last30Days = 'LAST_30_DAYS',
	Last24Hours = 'LAST_24_HOURS',
}

export interface IDashboardChartDatum {
	date: Date;
	value: number;
}

export interface IDashboardState {
	loading: boolean;

	totalRegistrations: number;
	totalDeposits: number;
	totalCoins: number;
	totalMessages: number;
	totalPushAccepted: number;
	totalDownloads: number;
	affiliateBalance: number;

	registrationsChart: Array<IDashboardChartDatum>;
	depositsChart: Array<IDashboardChartDatum>;
	loginsChart: Array<IDashboardChartDatum>;
	tradesPositionsChart: Array<IDashboardChartDatum>;
	sidebarShown: boolean | '' | 'responsive';
}
