import React, { useState } from 'react';
import {
	CButton,
	CFormGroup,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import _t from 'counterpart';
import { extractErrorMessage } from '../../helpers';
import { showErrorToast, showSuccessToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';
import { useMutation, useQuery } from 'react-query';
import { listSalesPeople, getSalesStatuses, updateLeadsBulk } from '../../services/BackendService';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import { ISalesStatus } from '../salesStatus/types';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IBulkEditParams, ILeadsFiltersDTO, ISalesPerson } from './types';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';

interface IProps {
	show: boolean;
	onClose: (refetch?: boolean) => void;
	leadsPageFilters: ILeadsFiltersDTO;
	leadsPageSearch: string | null | undefined;
	count: number;
}

const BulkEditModal = ({ show, onClose, count, leadsPageFilters, leadsPageSearch }: IProps) => {
	const [selectedStatus, setSelectedStatus] = useState<ISelectOption | null>();
	const [selectedSalesPerson, setSelectedSalesPerson] = useState<ISelectOption | null>();
	const dispatch = useAppDispatch();

	const resetStatus = () => {
		setSelectedStatus(null);
	};

	const resetSalesPerson = () => {
		setSelectedSalesPerson(null);
	};

	const updateLeadsMutation = useMutation((params: IBulkEditParams) => updateLeadsBulk(params), {
		onSuccess: () => {
			dispatch(showSuccessToast(_t('leads.leads-updated-successfully', { count })));
			resetStatus();
			resetSalesPerson();
			onClose(true);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const handleStatusChange = (status: any) => {
		setSelectedStatus(status);
	};

	const handleSalesPersonChange = (sp: any) => {
		setSelectedSalesPerson(sp);
	};

	const salesStatusesQuery = useQuery('sales-statuses', () => getSalesStatuses(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const salesPeopleQuery = useQuery(['sales-people-bulk'], () => listSalesPeople(), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			dispatch(showErrorToast(error));
		},
	});

	const isLoading = salesStatusesQuery.isLoading || salesPeopleQuery.isLoading;
	const isError = salesStatusesQuery.isError || salesPeopleQuery.isError;

	const handleOnRefetch = () => {
		salesStatusesQuery.refetch();
		salesPeopleQuery.refetch();
	};

	const statuses = salesStatusesQuery.data?.map((s: ISalesStatus) => {
		return {
			value: s.id,
			label: s.status,
		};
	});

	const salesPeople = salesPeopleQuery.data?.map((sp: ISalesPerson) => {
		return {
			value: sp.id,
			label: `${sp.firstname} ${sp.lastname}`,
		};
	});

	const closeHandler = () => {
		resetStatus();
		resetSalesPerson();
		onClose();
	};

	const invokeMutation = () => {
		updateLeadsMutation.mutate({
			...leadsPageFilters,
			selectedStatus: selectedStatus ? selectedStatus.value : null,
			selectedSalesPerson: selectedSalesPerson ? selectedSalesPerson.value : null,
			search: leadsPageSearch,
		});
	};

	return (
		<CModal show={show} onClose={closeHandler}>
			<CModalHeader>
				<CModalTitle>{_t('leads.bulk-edit-leads')}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CLabel>{_t('leads.only-selected-attr')}</CLabel>
				<QueryDataRenderer isLoading={isLoading} isError={isError} onRefetch={handleOnRefetch}>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('customers.filters.status')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetStatus}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={selectedStatus ?? null}
							options={statuses}
							onChange={handleStatusChange}
							isClearable={false}
							whiteBackground
							placeholder={_t('leads.select-a-status')}
						/>
					</CFormGroup>
					<CFormGroup>
						<div className="d-flex align-items-center justify-content-between">
							<span>{_t('leads.assignee')}</span>
							<CButton className="mb-0 p-0 text-danger reset-single" onClick={resetSalesPerson}>
								{_t('action.reset').toUpperCase()}
							</CButton>
						</div>
						<CustomSelect
							value={selectedSalesPerson ?? null}
							options={salesPeople}
							onChange={handleSalesPersonChange}
							isClearable={false}
							whiteBackground
							placeholder={_t('leads.select-an-assignee')}
						/>
					</CFormGroup>
				</QueryDataRenderer>
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={updateLeadsMutation.isLoading}
					onClick={invokeMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('leads.modify-leads', { count })}
					className="mr-2"
					disabled={count === 0}
				/>
				<CButton color="light" variant="outline" onClick={closeHandler}>
					{_t('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default React.memo(BulkEditModal);
