import React from 'react';
import PageLayout from '../../components/PageLayout';
import CoperatoPage from './CoperatoPage';
import VoisoPage from './VoisoPage';
import { CCard, CCardHeader, CCardTitle, CCol, CLabel, CLink, CRow } from '@coreui/react';
import _t from 'counterpart';
import { useGetSetting } from '../integrations/hooks';
import { showErrorToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import QueryDataRenderer from '../../components/QueryDataRenderer';

const VoipProvidersPage = () => {
	const dispatch = useAppDispatch();
	const providerQuery = useGetSetting('VOIP_PROVIDER', 'voip-page-provider', {
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			dispatch(showErrorToast(msg));
		},
	});

	const provider = providerQuery.data;
	const isError = Boolean(providerQuery.error);
	const isLoading = providerQuery.isLoading;

	return (
		<PageLayout title="VoIP providers">
			<QueryDataRenderer isError={isError} isLoading={isLoading} onRefetch={providerQuery.refetch}>
				{provider === '' && (
					<CRow>
						<CCol>
							<CCard>
								<CCardHeader>
									<CCardTitle>{_t('voip.voip-provider-disabled')}</CCardTitle>
									<CLabel>{_t('voip.enable-voip-on-page')}</CLabel>
									<CLink href="/integrations"> {_t('global.here')}</CLink>
								</CCardHeader>
							</CCard>
						</CCol>
					</CRow>
				)}
				{provider === 'coperato' && <CoperatoPage />}
				{provider === 'voiso' && <VoisoPage />}
			</QueryDataRenderer>
		</PageLayout>
	);
};

export default React.memo(VoipProvidersPage);
