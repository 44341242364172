import React from 'react';
import { CBadge } from '@coreui/react';

const AffectedTypeBadge = ({ type }: { type: string }) => {
	const colors: any = {
		LEAD: 'success',
		CUSTOMER: 'primary',
		INSTRUMENT: 'warning',
		CURRENCY_CONVERSION: 'secondary',
	};

	return <CBadge color={colors[type]}>{type}</CBadge>;
};

export default AffectedTypeBadge;
