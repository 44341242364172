import { AppState } from '../reducers';
import { UserRole } from '../reducers/userReducer';

export const isImpersonatedSelector = (state: AppState) => Boolean(state.user.impersonation);
export const rootUrlSelector = (state: AppState) => {
	if (state.user.token && !state.user.roles.includes(UserRole.Admin) && state.user.roles.includes(UserRole.Sales)) {
		return '/customers';
	}
	return '/';
};
