import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import storeRegistry from './helpers/storeRegistry';

import { store, persistor, history } from './store';
import { icons } from './icons';

// library style imports
import 'react-datetime/css/react-datetime.css';
import Analytics from './helpers/analytics';
import posthog from 'posthog-js';

const environment = process.env.REACT_APP_ENVIRONMENT;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryOrganization = process.env.REACT_APP_SENTRY_ORGANIZATION;
const sentryProjectId = process.env.REACT_APP_SENTRY_PROJECT_ID;
const posthogKey = process.env.REACT_APP_POSTHOG_KEY;
const posthogHost = process.env.REACT_APP_POSTHOG_HOST;

if (environment) {
	const sentryIntegrations = [];
	if (posthogHost && posthogKey) {
		Analytics.init(posthogKey, { api_host: posthogHost });
		if (sentryOrganization && !Number.isNaN(Number(sentryProjectId))) {
			sentryIntegrations.push(new posthog.SentryIntegration(posthog, sentryOrganization, Number(sentryProjectId)));
		} else {
			throw new Error(
				'[Posthog/Sentry] Missing REACT_APP_SENTRY_PROJECT_ID and/or REACT_APP_SENTRY_ORGANIZATION env vars and Posthog is enabled.'
			);
		}
	}
	if (sentryDsn) {
		Sentry.init({
			dsn: sentryDsn,
			environment,
			// @ts-ignore
			integrations: sentryIntegrations,
		});
	}
}

const queryClient = new QueryClient();

// @ts-ignore
React.icons = icons;

storeRegistry.register(store);

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<PersistGate loading={null} persistor={persistor}>
						<QueryParamProvider ReactRouterRoute={Route}>
							<App />
						</QueryParamProvider>
					</PersistGate>
				</ConnectedRouter>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
