import React from 'react';
import { CForm, CFormGroup, CLabel, CInputGroup, CInput, CSelect, CRow, CCol } from '@coreui/react';
import _t from 'counterpart';
import { createPosition, getAllSymbols, loadCustomerWallets } from '../../services/BackendService';
import { findErrorFromValidation, extractErrorMessage } from '../../helpers';
import { useLegacyState, useAppDispatch } from '../../helpers/customHooks';
import { useQuery, useMutation } from 'react-query';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { showErrorToast, showSuccessToast } from '../../actions';
import { IWallet } from '../wallets/types';
import PageLayout from '../../components/PageLayout';
import { useHistory, useParams } from 'react-router';
import Loading from '../../components/Loading';
import Error from '../../components/Error';

enum Platform {
	NEO = 'neo',
	PRO = 'pro',
}

enum Side {
	BUY = 'buy',
	SELL = 'sell',
}

interface IState {
	accountId: string;
	symbol: string;
	side: string;
	size: number | undefined;
	stopLoss: number | undefined;
	openPrice: number | undefined;
	takeProfit: number | undefined;
	marginRate: number | undefined;
	platform: Platform;
}

const CreateCustomerPositionPage = () => {
	const [state, setState] = useLegacyState<IState>({
		accountId: '',
		symbol: '',
		side: Side.BUY,
		size: undefined,
		platform: Platform.PRO,
		stopLoss: undefined,
		openPrice: undefined,
		takeProfit: undefined,
		marginRate: undefined,
	});

	const params = useParams();
	const { customerId } = params as any;

	const { accountId, symbol, side, size, stopLoss, openPrice, takeProfit, marginRate, platform } = state;

	const dispatch = useAppDispatch();
	const history = useHistory();

	const addPositionMutation = useMutation(
		['add-position'],
		() => createPosition(accountId, side, symbol, size, platform, openPrice, takeProfit, stopLoss, marginRate),
		{
			onSuccess: async () => {
				dispatch(showSuccessToast(_t('add-position-modal.position-created-successfully')));
				history.push(`/customers/${customerId}`);
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const addPosition = async () => {
		clearState();
		addPositionMutation.mutate();
	};

	const clearState = () => {
		addPositionMutation.reset();
		setState({
			accountId: '',
			symbol: '',
			side: Side.BUY,
			size: undefined,
			stopLoss: undefined,
			openPrice: undefined,
			takeProfit: undefined,
			marginRate: undefined,
			platform: Platform.PRO,
		});
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(addPositionMutation.error, paramName);
	};

	const handleInputChanged = (event: any) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		const value = target.value;
		if (name) {
			setState({ [name]: value });
		}
	};

	const customerWalletsQuery = useQuery(['customer-wallets-for-position', customerId], async () => {
		const data = await loadCustomerWallets(customerId);
		return data;
	});

	const { data } = customerWalletsQuery;

	const symbolsQuery = useQuery(['symbols'], async () => {
		const symbols = await getAllSymbols();
		return symbols;
	});
	const symbols = symbolsQuery.data?.symbols;

	if (!state.accountId && data && data.length > 0) {
		setState({ accountId: data[0].username });
	}

	if (!state.symbol && symbols) {
		setState({ symbol: symbols[0] });
	}

	if (addPositionMutation.isLoading || customerWalletsQuery.isLoading || symbolsQuery.isLoading) {
		return <Loading />;
	}

	if (customerWalletsQuery.isError) {
		return <Error onRetry={customerWalletsQuery.refetch} />;
	}

	if (symbolsQuery.isError) {
		return <Error onRetry={symbolsQuery.refetch} />;
	}

	return (
		<PageLayout title={_t('add-position-modal.add-position')}>
			<CRow>
				<CCol>
					<CForm action="" method="post">
						<CFormGroup>
							<CLabel htmlFor="symbol">{_t('add-position-modal.symbol')}</CLabel>
							<CSelect id="symbol" name="symbol" onChange={handleInputChanged} value={symbol}>
								{symbols?.map((symbol: string) => {
									return <option key={`symbol-${symbol}`} value={symbol}>{`${symbol}`}</option>;
								})}
							</CSelect>
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="accountId">{_t('add-position-modal.account-id')}</CLabel>
							<CSelect id="accountId" name="accountId" onChange={handleInputChanged} value={accountId}>
								{data?.map((account: IWallet) => {
									return (
										<option key={`account-${account.username}`} value={account.username}>{`${account.username} ${
											account.primary ? '(primary)' : ''
										}`}</option>
									);
								})}
							</CSelect>
							{findError('accountId') && <CLabel className="text-danger">{findError('accountId')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="side">{_t('add-position-modal.side')}</CLabel>
							<CSelect id="side" name="side" onChange={handleInputChanged} value={side}>
								<option key={`side-${Side.BUY}`} value={Side.BUY}>
									{Side.BUY}
								</option>
								<option key={`side-${Side.SELL}`} value={Side.SELL}>
									{Side.SELL}
								</option>
							</CSelect>
							{findError('side') && <CLabel className="text-danger">{findError('side')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="platform">{_t('add-position-modal.platform')}</CLabel>
							<CSelect id="platform" name="platform" onChange={handleInputChanged} value={platform}>
								<option key={`platform-${Platform.NEO}`} value={Platform.NEO}>
									{Platform.NEO}
								</option>
								<option key={`platform-${Platform.PRO}`} value={Platform.PRO}>
									{Platform.PRO}
								</option>
							</CSelect>
							{findError('platform') && <CLabel className="text-danger">{findError('platform')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="size">{_t('add-position-modal.size')}</CLabel>
							<CInputGroup>
								<CInput
									type="number"
									id="size"
									name="size"
									placeholder="0.01"
									value={size || ''}
									onChange={handleInputChanged}
								/>
							</CInputGroup>
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="takeProfit">{_t('add-position-modal.take-profit')}</CLabel>
							<CInput
								type="number"
								id="takeProfit"
								name="takeProfit"
								placeholder="0"
								value={takeProfit || ''}
								onChange={handleInputChanged}
							/>
							{findError('takeProfit') && <CLabel className="text-danger">{findError('takeProfit')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="openPrice">{_t('add-position-modal.open-price')}</CLabel>
							<CInput
								type="number"
								id="openPrice"
								name="openPrice"
								placeholder="0"
								value={openPrice || ''}
								onChange={handleInputChanged}
							/>
							{findError('openPrice') && <CLabel className="text-danger">{findError('openPrice')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="stopLoss">{_t('add-position-modal.stop-loss')}</CLabel>
							<CInput
								type="number"
								id="stopLoss"
								name="stopLoss"
								placeholder="0"
								value={stopLoss || ''}
								onChange={handleInputChanged}
							/>
							{findError('stopLoss') && <CLabel className="text-danger">{findError('stopLoss')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel htmlFor="marginRate">{_t('add-position-modal.margin-rate')}</CLabel>
							<CInput
								type="number"
								id="marginRate"
								name="marginRate"
								placeholder="0"
								value={marginRate || ''}
								onChange={handleInputChanged}
							/>
							{findError('marginRate') && <CLabel className="text-danger">{findError('marginRate')}</CLabel>}
						</CFormGroup>
					</CForm>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<ButtonWithLoader
						isLoading={addPositionMutation.isLoading}
						onClick={addPosition}
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t.translate('action.add')}
						className="mr-2"
					/>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default CreateCustomerPositionPage;
