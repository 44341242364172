import React, { useState, useEffect } from 'react';
import _t from 'counterpart';
import {
	CButton,
	CModalBody,
	CModalHeader,
	CModal,
	CModalFooter,
	CModalTitle,
	CForm,
	CLabel,
	CFormGroup,
	CSelect,
	CAlert,
	CInput,
} from '@coreui/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateWallet, loadGroups, loadWarehouseAndFixAdapters } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IGroup } from './types';
import { IWallet } from '../wallets/types';

export function OrderRoutingAdapterSelector(props: { onChange: any; value: string | undefined }) {
	const { onChange, value } = props;

	const { isLoading, error, data } = useQuery<any, Error>(['order-routing-adapters'], async () => {
		const data = await loadWarehouseAndFixAdapters();
		return data;
	});

	useEffect(() => {
		if (data && (value === '' || !value)) {
			onChange(data![0].name);
		}
	}, [data, value, onChange]);

	if (isLoading) {
		return (
			<CSelect id="nf-type" name="nf-type" disabled>
				<option>{_t('global.loading')}</option>
			</CSelect>
		);
	}

	if (error) {
		return <span>{error.message}</span>;
	}

	return (
		<CSelect id="nf-type" name="nf-type" onChange={(e: any) => onChange(e.target.value)} value={value}>
			{data.map((o: any) => (
				<option value={o.id} key={`adapter-${o.id}`}>
					{o.name}
				</option>
			))}
		</CSelect>
	);
}

export function OrderGroupsSelector(props: { onChange: any; value: number | undefined; currency: string }) {
	const { onChange, value, currency } = props;

	const { isLoading, error, data } = useQuery<Array<IGroup>, Error>(['order-groups'], () => loadGroups());

	if (isLoading) {
		return (
			<CSelect id="nf-group" name="nf-group" disabled>
				<option>{_t('global.loading')}</option>
			</CSelect>
		);
	}

	if (error) {
		return <span>{error.message}</span>;
	}

	if (!value) {
		onChange(data![0]?.id);
	}

	const handleOnChange = (e: any) => {
		onChange(Number(e.target.value));
	};

	return (
		<CSelect id="nf-type" name="nf-type" onChange={handleOnChange} value={value}>
			{data!.map((o: any) => (
				<option value={o.id} key={o.id} disabled={currency !== o.depositCurrency}>
					{o.name} ({o.depositCurrency})
				</option>
			))}
		</CSelect>
	);
}

function CustomerWalletPopup(props: { wallet: IWallet; onClose: () => void }) {
	const queryClient = useQueryClient();
	const { wallet, onClose } = props;
	const [groupId, setGroupId] = useState(wallet.group?.id);
	const [leverage, setWalletLeverage] = useState<string>('');
	const update = useMutation<any, Error, any>(
		(id: string) => updateWallet(id, { groupId, leverage: Number(leverage) }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['customer-wallets']);
				onClose();
			},
		}
	);

	useEffect(() => {
		if (!leverage) {
			setWalletLeverage(wallet.leverage.toString());
		}
	}, [leverage, wallet.leverage]);

	const setWalletLeverageWrapper = (event: any) => {
		setWalletLeverage(event.target.value);
	};

	const mutateWallet = (e: React.FormEvent) => {
		e.preventDefault();
		update.mutate(wallet.id);
	};

	return (
		<CModal show onClose={onClose} size="sm">
			<CModalHeader>
				<CModalTitle>{_t('wallet-modal.title')}</CModalTitle>
			</CModalHeader>
			<CForm onSubmit={mutateWallet}>
				<CModalBody>
					{update.error && <CAlert color="danger">{update.error.message}</CAlert>}
					<CFormGroup>
						<CLabel htmlFor="nf-group">{_t('wallet-modal.group')}</CLabel>
						<OrderGroupsSelector onChange={setGroupId} value={groupId} currency={wallet.currency} />
					</CFormGroup>
					<CFormGroup>
						<CLabel htmlFor="nf-leverage">{_t('customer.wallets.set-wallet-leverage')}</CLabel>
						<CInput
							id="nf-leverage"
							name="nf-leverage"
							placeholder="300"
							value={leverage}
							onChange={setWalletLeverageWrapper}
						/>
					</CFormGroup>
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={update.isLoading}
						type="submit"
						buttonColor="primary"
						spinnerColor="secondary"
						title={_t.translate('global.confirm')}
						className="mr-2"
					/>
					<CButton color="light" variant="outline" onClick={onClose} disabled={update.isLoading}>
						{_t('global.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
}

export default CustomerWalletPopup;
