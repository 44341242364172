import React, { useState } from 'react';
import {
	CButton,
	CInput,
	CLabel,
	CModal,
	CModalBody,
	CModalTitle,
	CModalFooter,
	CModalHeader,
	CFormGroup,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CSelect,
	CFormText,
} from '@coreui/react';
import _t from 'counterpart';
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { updateBonusConfig, createBonusConfig, loadPaymentProviders } from '../../services/BackendService';
import { useMutation, useQuery } from 'react-query';
import { useAppDispatch } from '../../helpers/customHooks';
import { showSuccessToast, showErrorToast } from '../../actions';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { ICreateUpdateModalProps, IBonus, BonusType } from './types';
import ToggleSelect, { ISelectOption } from '../../components/ToggleSelect';

const CreateUpdateBonusModal = ({ show, hideModal, currentBonus }: ICreateUpdateModalProps) => {
	const initialStartDate = moment().minutes(0).seconds(0).millisecond(0).toDate();
	const initialEndDate = moment().add(1, 'month').minutes(0).seconds(0).toDate();
	const [usedProviders, setUsedProviders] = useState<Array<ISelectOption>>([]);
	const [inputValue, setInputValue] = useState<string>('');
	const [selectAll, setSelectAll] = useState<boolean>(false);

	useQuery(['bonus-payment-providers'], () => loadPaymentProviders(), {
		onSuccess: (data) => {
			const providers = data;
			const mappedValues = providers.map((p) => {
				let enabled = false;
				if (currentBonus !== null) {
					enabled = currentBonus.methods.includes(p.id);
				}

				return {
					value: p.id,
					label: p.name,
					enabled,
				};
			});
			setUsedProviders(mappedValues);
		},
		onError: (e) => {
			const msg = extractErrorMessage(e);
			dispatch(showErrorToast(msg));
		},
	});

	const initalState = {
		id: currentBonus?.id || '',
		start: currentBonus ? new Date(currentBonus.start) : initialStartDate,
		end: currentBonus ? new Date(currentBonus.end) : initialEndDate,
		amount: currentBonus?.amount || 0,
		minimumDepositUSD: currentBonus?.minimumDepositUSD,
		maximumAmountUSD: currentBonus?.maximumAmountUSD,
		addToCredit: currentBonus?.addToCredit ?? true,
		methods: [],
	};

	const formMaps: any = {
		[BonusType.SIGNUP]: ['name', 'date', 'amount', 'addToCredit'],
		[BonusType.DEPOSIT]: ['name', 'date', 'amount', 'minDeposit', 'addToCredit', 'methods'],
		[BonusType.DEPOSIT_PROPORTIONAL]: [
			'name',
			'date',
			'amountProportional',
			'maximumAmountUSD',
			'addToCredit',
			'methods',
		],
		[BonusType.APP_INSTALLED]: ['name', 'date', 'amount', 'addToCredit'],
		[BonusType.PUSH_ACCEPTANCE]: ['name', 'date', 'amount', 'addToCredit'],
	};

	const thisBonus = currentBonus as any;

	const [bonus, setBonus] = useState<IBonus>(initalState);
	const [bonusType, setBonusType] = useState<string>(thisBonus?.type || 'SIGNUP');
	const dispatch = useAppDispatch();

	const bonusDTO = {
		id: bonus.id,
		start: new Date(bonus.start),
		end: new Date(bonus.end),
		amount: Number(bonus.amount),
		type: bonusType,
		minimumDepositUSD: bonus.minimumDepositUSD ? Number(bonus.minimumDepositUSD) : undefined,
		maximumAmountUSD: bonus.maximumAmountUSD ? Number(bonus.maximumAmountUSD) : undefined,
		addToCredit: bonus.addToCredit,
		methods: usedProviders.filter((p) => p.enabled).map((p) => p.value),
	};

	const createUpdateBonusMutation = useMutation(
		['create-update-bonus'],
		() => {
			if (currentBonus) {
				return updateBonusConfig(currentBonus!.id, bonusDTO);
			}
			return createBonusConfig(bonusDTO);
		},
		{
			onSuccess: () => {
				if (currentBonus) {
					dispatch(showSuccessToast(_t.translate('bonuses.successfully-updated')));
				} else {
					dispatch(showSuccessToast(_t.translate('bonuses.successfully-added')));
				}
				hideModal(true);
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	const callBonusMutation = () => {
		createUpdateBonusMutation.mutate();
	};

	const hideSelf = () => {
		createUpdateBonusMutation.reset();
		hideModal();
	};

	const setName = (e: any) => {
		setBonus({ ...bonus, id: e.target.value });
	};

	const handleAmountChanged = (event: any) => {
		setBonus({ ...bonus, amount: event.target.value });
	};

	const handleMinimumDeposit = (event: any) => {
		setBonus({ ...bonus, minimumDepositUSD: event.target.value });
	};

	const handleMaximumAmountUSD = (event: any) => {
		setBonus({ ...bonus, maximumAmountUSD: event.target.value });
	};

	const handleStartDateChange = (startDate: Moment | string) => {
		if (typeof startDate !== 'string') {
			setBonus({ ...bonus, start: startDate.toDate() });
		}
	};

	const handleEndDateChange = (endDate: Moment | string) => {
		if (typeof endDate !== 'string') {
			setBonus({ ...bonus, end: endDate.toDate() });
		}
	};

	const setBonusTypeFromSelect = (event: any) => {
		const { value } = event.target;
		setBonusType(value);
	};

	const handleAddToCredit = (event: any) => {
		const target = event.target as HTMLInputElement;
		const { checked } = target;
		setBonus({ ...bonus, addToCredit: checked });
	};

	const addUpdateBonusErrors = createUpdateBonusMutation.error;

	const findError = (name: string) => {
		return findErrorFromValidation(addUpdateBonusErrors, name);
	};

	const formExistsInMap = (formType: string) => {
		if (!formMaps[bonusType]) {
			return false;
		}
		return formMaps[bonusType].includes(formType);
	};

	const onOptionsChanged = (options: Array<ISelectOption>) => {
		setUsedProviders(options);
		const disabledIndex = options.findIndex((o) => !o.enabled);
		setSelectAll(disabledIndex === -1);
	};

	return (
		<CModal show={show} onClose={hideSelf} closeOnBackdrop={false}>
			<CModalHeader>
				<CModalTitle>
					{currentBonus ? _t.translate('bonuses.update-bonus') : _t.translate('bonuses.add-bonus')}
				</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<CFormGroup>
					<CLabel htmlFor="nf-type">{_t('bonuses.type')}</CLabel>
					<CSelect id="nf-type" name="nf-type" onChange={setBonusTypeFromSelect} value={bonusType}>
						<option value={BonusType.SIGNUP}>{_t('bonuses.types.sign-up')}</option>
						<option value={BonusType.DEPOSIT}>{_t('bonuses.types.deposit')}</option>
						<option value={BonusType.DEPOSIT_PROPORTIONAL}>{_t('bonuses.types.deposit-proportional')}</option>
						<option value={BonusType.APP_INSTALLED}>{_t('bonuses.types.app-installed')}</option>
						<option value={BonusType.PUSH_ACCEPTANCE}>{_t('bonuses.types.push-acceptance')}</option>
					</CSelect>
					{findError('type') && <CLabel className="text-danger">{findError('type')}</CLabel>}
				</CFormGroup>
				{formExistsInMap('name') && (
					<CFormGroup>
						<CLabel htmlFor="name">{_t.translate('bonuses.name')}</CLabel>
						<CInput
							type="text"
							name="name"
							placeholder="Bonus name"
							value={bonus.id}
							onChange={(e: any) => setName(e)}
							disabled={Boolean(currentBonus)}
						/>
						{findError('id') && <CLabel className="text-danger">{findError('id')}</CLabel>}
					</CFormGroup>
				)}
				{formExistsInMap('date') && (
					<>
						<CFormGroup>
							<CLabel>{_t('bonuses.start')}</CLabel>
							<Datetime
								value={bonus.start}
								onChange={handleStartDateChange}
								dateFormat="DD/MM/YYYY"
								timeFormat="HH:mm"
								timeConstraints={{ minutes: { min: 1, max: 60, step: 15 } }}
								inputProps={{ readOnly: true }}
							/>
							{findError('start') && <CLabel className="text-danger">{findError('start')}</CLabel>}
						</CFormGroup>
						<CFormGroup>
							<CLabel>{_t('bonuses.end')}</CLabel>
							<Datetime
								value={bonus.end}
								onChange={handleEndDateChange}
								dateFormat="DD/MM/YYYY"
								timeFormat="HH:mm"
								timeConstraints={{ minutes: { min: 1, max: 60, step: 15 } }}
								inputProps={{ readOnly: true }}
							/>
							{findError('end') && <CLabel className="text-danger">{findError('end')}</CLabel>}
						</CFormGroup>
					</>
				)}
				{formExistsInMap('methods') && (
					<CFormGroup>
						<CLabel htmlFor="allowedCountries">{_t('bonuses.methods')}</CLabel>
						<ToggleSelect
							options={usedProviders}
							onOptionsChanged={onOptionsChanged}
							inputValue={inputValue}
							onInputValueChanged={setInputValue}
							selectAll={selectAll}
							onSelectChanged={setSelectAll}
						/>
						<CFormText className="help-block">{_t('bonuses.methods-description')}</CFormText>
						{findError('methods') && <CLabel className="text-danger">{findError('methods')}</CLabel>}
					</CFormGroup>
				)}
				{formExistsInMap('amount') && (
					<CFormGroup>
						<CLabel htmlFor="nf-amount">{_t.translate('bonuses.amount-in-usd')}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText>$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="nf-amount"
								name="nf-amount"
								placeholder="12.64"
								value={bonus.amount}
								onChange={handleAmountChanged}
							/>
						</CInputGroup>
						{findError('amount') && <CLabel className="text-danger">{findError('amount')}</CLabel>}
					</CFormGroup>
				)}
				{formExistsInMap('amountProportional') && (
					<CFormGroup>
						<CLabel htmlFor="nf-amount">{_t.translate('bonuses.amount-proportional')}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText>%</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="nf-amount"
								name="nf-amount"
								placeholder="20"
								value={bonus.amount}
								onChange={handleAmountChanged}
							/>
						</CInputGroup>
						{findError('amount') && <CLabel className="text-danger">{findError('amount')}</CLabel>}
					</CFormGroup>
				)}
				{formExistsInMap('minDeposit') && (
					<CFormGroup>
						<CLabel htmlFor="nf-deposit">{_t.translate('bonuses.min-deposit')}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText>$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="nf-deposit"
								name="nf-deposit"
								placeholder="12.64"
								value={bonus.minimumDepositUSD}
								onChange={handleMinimumDeposit}
							/>
						</CInputGroup>
						{findError('minimumDepositUSD') && (
							<CLabel className="text-danger">{findError('minimumDepositUSD')}</CLabel>
						)}
					</CFormGroup>
				)}
				{formExistsInMap('maximumAmountUSD') && (
					<CFormGroup>
						<CLabel htmlFor="nf-max-amount">{_t.translate('bonuses.max-amount-usd')}</CLabel>
						<CInputGroup>
							<CInputGroupPrepend>
								<CInputGroupText>$</CInputGroupText>
							</CInputGroupPrepend>
							<CInput
								type="number"
								id="nf-max-amount"
								name="nf-max-amount"
								placeholder="100"
								value={bonus.maximumAmountUSD}
								onChange={handleMaximumAmountUSD}
							/>
						</CInputGroup>
						{findError('maximumAmountUSD') && <CLabel className="text-danger">{findError('maximumAmountUSD')}</CLabel>}
					</CFormGroup>
				)}
				{formExistsInMap('addToCredit') && (
					<CFormGroup className="d-flex align-items-center">
						<input
							className="mr-2"
							type="checkbox"
							id="addToCredit"
							onChange={handleAddToCredit}
							checked={bonus.addToCredit}
						/>
						<span>{_t.translate('add-transaction-modal.added-as-credit')}</span>
					</CFormGroup>
				)}
			</CModalBody>
			<CModalFooter>
				<ButtonWithLoader
					isLoading={createUpdateBonusMutation.isLoading}
					onClick={callBonusMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={currentBonus ? _t.translate('action.update') : _t.translate('action.add')}
					className="mr-2"
				/>
				<CButton color="light" variant="outline" onClick={hideSelf} disabled={createUpdateBonusMutation.isLoading}>
					{_t.translate('action.cancel')}
				</CButton>
			</CModalFooter>
		</CModal>
	);
};

export default CreateUpdateBonusModal;
