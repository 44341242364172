import { CCol, CRow } from '@coreui/react';
import _t from 'counterpart';
import React from 'react';
import PageLayout from '../../../components/PageLayout';
import CaptchaIntegration from '../CaptchaIntegration';
import EmailIntegration from '../EmailIntegration';
import VoipIntegration from '../VoipIntegration';

import './IntegrationsPage.scss';

const IntegrationsPage = () => {
	
	return (
		<PageLayout title={_t('sidebar.integrations')}>
			<CRow className={"integrations-page"}>
				<CCol md={12}>
					<VoipIntegration />
				</CCol>
				<CCol md={12}>
					<EmailIntegration />
				</CCol>
				<CCol md={12}>
					<CaptchaIntegration />
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default IntegrationsPage;
