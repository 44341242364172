import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CBadge, CButton, CCard, CCardBody, CCol, CDataTable, CRow } from '@coreui/react';
import { loadCommissionSchemes } from '../../services/BackendService';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import { ICommissionSchemeFull } from './types';
import { AddEditCommissionSchemeModal } from './AddEditCommissionSchemeModal';
import { DeleteCommissionSchemeModal } from './DeleteCommissionSchemeModal';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';

interface IAddEditModalState {
	shown: boolean;
	commissionScheme: ICommissionSchemeFull | null;
}

interface IDeleteModalState {
	shown: boolean;
	commissionScheme: ICommissionSchemeFull | null;
}

export default function CommissionsSchemes() {
	const { isLoading, isError, data, refetch } = useQuery<any, Error>(['commission-schemes'], loadCommissionSchemes);

	const [addEditModalState, setAddEditModalState] = useState<IAddEditModalState>({
		shown: false,
		commissionScheme: null,
	});

	const [deleteModalState, setDeleteModalState] = useState<IDeleteModalState>({
		shown: false,
		commissionScheme: null,
	});

	const showAddModal = () => {
		setAddEditModalState({
			shown: true,
			commissionScheme: null,
		});
	};

	const showEditModal = (commissionScheme: ICommissionSchemeFull) => {
		setAddEditModalState({
			shown: true,
			commissionScheme,
		});
	};

	const showDeleteModal = (commissionScheme: ICommissionSchemeFull) => {
		setDeleteModalState({
			shown: true,
			commissionScheme,
		});
	};

	const hideAddEditModal = () => {
		setAddEditModalState({
			shown: false,
			commissionScheme: null,
		});
	};

	const hideDeleteModal = () => {
		setDeleteModalState({
			shown: false,
			commissionScheme: null,
		});
	};

	const onErrorRetry = () => {
		refetch();
	};

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('commission-schemes.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardBody className="simulated-overflow-y">
							<div className="my-2 float-sm-right d-flex justify-content-end">
								<CButton className="header-button-add" onClick={showAddModal} />
							</div>
							<CDataTable
								items={data}
								fields={[
									{
										key: 'name',
										label: _t('global.name'),
									},
									{
										key: 'coinRolloverCommissionsUSD',
										label: _t('commission-schemes.coin-rollover-commissions'),
									},
									{
										key: 'sub',
										label: _t('commission-schemes.sub-commission-scheme'),
										sorter: false,
										filter: false,
									},
									{
										key: 'greyLabelingEnabled',
										label: _t('commission-schemes.grey-labeling-enabled'),
										sorter: false,
										filter: false,
									},
									{
										key: 'actions',
										label: _t('global.actions'),
										sorter: false,
										filter: false,
									},
								]}
								scopedSlots={{
									coinRolloverCommissionsUSD: (commissionScheme: any) => (
										<td>{commissionScheme.coinRolloverCommissionsUSD.join(', ')}</td>
									),
									sub: (commissionScheme: any) => <td>{commissionScheme?.subCommissionScheme?.name || 'Inherit'}</td>,
									greyLabelingEnabled: (commissionScheme: any) => (
										<td>
											<CBadge color={commissionScheme.greyLabelingEnabled ? 'success' : 'danger'}>
												{commissionScheme.greyLabelingEnabled ? _t.translate('global.yes') : _t.translate('global.no')}
											</CBadge>
										</td>
									),
									actions: (commissionScheme: ICommissionSchemeFull) => (
										<td className="table-small_cta">
											<CButton
												onClick={() => showEditModal(commissionScheme)}
												size="sm"
												className="mr-1"
												color="primary"
											>
												<CIcon name="cil-pencil" size="sm" />
											</CButton>
											<CButton color="danger" size="sm" onClick={() => showDeleteModal(commissionScheme)}>
												<CIcon name="cil-trash" size="sm" />
											</CButton>
										</td>
									),
								}}
								loading={isLoading}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{addEditModalState.shown && (
				<AddEditCommissionSchemeModal
					commissionScheme={addEditModalState.commissionScheme}
					hideModal={hideAddEditModal}
				/>
			)}

			{deleteModalState.shown && (
				<DeleteCommissionSchemeModal
					commissionScheme={deleteModalState.commissionScheme!}
					hideModal={hideDeleteModal}
				/>
			)}
		</PageLayout>
	);
}
