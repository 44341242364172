import React from 'react';
import _t from 'counterpart';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from './reducers';
import { hideErrorToast, hideSuccessToast } from './actions';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import moment from 'moment';

import './scss/style.scss';
import { PopupAlert } from './components/PopupAlert';
import { correctedMomentLocales, supportedLocales } from './locales';
import { useAppSelector } from './helpers/customHooks';

const queryParams = new URLSearchParams(document.location.search.replace(/^\?/, ''));
const queryLang = queryParams.get('lang');
if (queryLang && supportedLocales.includes(queryLang)) {
	localStorage.setItem('locale', queryLang);
}

const language = localStorage.getItem('locale') || 'en';

_t.setLocale(language);

if (Object.keys(correctedMomentLocales).includes(language)) {
	const locale = correctedMomentLocales[language];
	if (locale) {
		moment.locale(locale);
	}
} else {
	moment.locale(localStorage.getItem('locale') || 'en');
}

interface IAppProps {
	errorToast: string | null;
	successToast: string | null;
	hideErrorToast: () => void;
	hideSuccessToast: () => void;
	communicationLanguage: string;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }: any) => {
	const { token: isAuthenticated } = useAppSelector((state) => state.user);

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { referrer: location.pathname },
						}}
					/>
				)
			}
		/>
	);
};

const App = ({ errorToast, hideErrorToast, hideSuccessToast, communicationLanguage, successToast }: IAppProps) => {
	return (
		<div key={communicationLanguage}>
			{errorToast && <PopupAlert message={errorToast} onCloseClicked={hideErrorToast} type="error" />}
			{successToast && <PopupAlert message={successToast} onCloseClicked={hideSuccessToast} type="success" />}
			<Switch>
				<Route exact path="/login">
					<LoginPage />
				</Route>
				<PrivateRoute path="/">
					<MainPage />
				</PrivateRoute>
			</Switch>
		</div>
	);
};

export default connect(
	(state: AppState) => ({
		errorToast: state.notifications.errorToast,
		successToast: state.notifications.successToast,
		communicationLanguage: state.core.language,
	}),
	{ hideErrorToast, hideSuccessToast }
)(App);
