import React, { useMemo, useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CButton, CCardHeader } from '@coreui/react';
import _t from 'counterpart';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useQuery, useQueryClient } from 'react-query';
import { ITransaction } from './transactions/types';
import { shallowEqual } from 'react-redux';
import { loadTransactions } from '../services/BackendService';
import { formatCurrency, formatDateTime, getFiltersCount } from '../helpers';
import { TransactionStatusBadge } from './transactions/TransactionStatusBadge';
import { useAppSelector, useAppDispatch } from '../helpers/customHooks';
import { clearDepositsFilters, setDepositsFilters } from '../actions';
import PaginationTable, { ISorter } from '../components/PaginationTable';
import SearchFilter from '../components/SearchFilter';
import TransactionFilter from './transactions/TransactionFilters';
import ExportXLSXButton from '../components/ExportXLSXButton';
import { getExportLink, transactionFiltersToDTO } from '../helpers/transactionsHelpers';
import PageLayout from '../components/PageLayout';
import Error from '../components/Error';

const DepositsPage = () => {
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const history = useHistory();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const depositsItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_deposits_table') ?? 10);
	const [limit, setLimit] = useState<number>(depositsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const token = useAppSelector((state: any) => state.user.token);
	const depositsFilters = useAppSelector((state) => state.filters.depositsFilters, shallowEqual);

	const filtersDTO = transactionFiltersToDTO(depositsFilters);
	const { data, isLoading, isError, refetch } = useQuery<any>(
		['deposits', filter, page, orderBy, depositsFilters],
		() => loadTransactions('DEPOSIT', filter!, limit, offset, orderBy!, filtersDTO)
	);

	const onRowClicked = (transaction: ITransaction) => {
		history.push(`/transactions/${transaction.id}`);
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_deposits_table', limit.toString());
		await queryClient.invalidateQueries('deposits');
		refetch();
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const resetFilters = () => {
		dispatch(clearDepositsFilters());
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const filtersCount = getFiltersCount(depositsFilters);
	let recordCount = 0;

	if (data) {
		const { pages, transactions } = data;
		recordCount = Number(pages) + Number(transactions.length);
	}

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		refetch();
	};

	const tableFields = useMemo(
		() => [
			{ key: 'createdAt', label: _t('transactions.created') },
			{ key: 'type', label: _t('transactions.type'), sorter: 'deposit' },
			{ key: 'wallet', label: _t('global.wallet'), sorter: false },
			{ key: 'customer', label: _t('transactions.customer'), sorter: false },
			{ key: 'status', label: _t('transactions.status'), sorter: false },
			{ key: 'provider', label: _t('transactions.provider') },
			{ key: 'netAmountInUSD', label: _t('transactions.amount') },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			createdAt: (transaction: ITransaction) => (
				<td className="text-nowrap">{formatDateTime(transaction.createdAt)}</td>
			),
			type: (transaction: ITransaction) => <td>{transaction.type}</td>,
			customer: (transaction: ITransaction) => <td>{transaction.customer.name}</td>,
			status: (transaction: ITransaction) => (
				<td>
					<TransactionStatusBadge status={transaction.status} />
				</td>
			),
			provider: (transaction: ITransaction) => <td>{transaction.provider || 'Internal'}</td>,
			netAmountInUSD: (transaction: ITransaction) => (
				<td>{transaction.netAmountInUSD ? formatCurrency(transaction.netAmountInUSD) : ''}</td>
			),
		}),
		[]
	);

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('transactions.deposits')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
										<div className="float-right">
											<ExportXLSXButton
												downloadLink={getExportLink(filter, depositsFilters, token, 'DEPOSIT')}
												defaultFilename="deposits.xlsx"
												disabled={recordCount < 1}
											/>
										</div>
									</div>
								</div>
								<TransactionFilter
									show={showFilters}
									dispatchAction={setDepositsFilters}
									activeFilters={depositsFilters}
									onFilterChanged={onFilterChanged}
									type="deposit"
								/>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								data={data?.transactions || []}
								pages={data?.pages || 0}
								activePage={page || 1}
								loading={isLoading}
								pagination
								clickableRows
								onRowClicked={onRowClicked}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default DepositsPage;
