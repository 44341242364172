import {
	CRow,
	CCol,
	CCard,
	CCardTitle,
	CCardHeader,
	CForm,
	CFormGroup,
	CInput,
	CLabel,
	CSelect,
	CCardBody,
	CCardFooter,
} from '@coreui/react';
import React, { useEffect, useMemo, useState } from 'react';
import _t from 'counterpart';
import countries from '../../../vendor/ftt_countries.json';
import PageLayout from '../../../components/PageLayout';
import { extractErrorMessage, findErrorFromValidation } from '../../../helpers';
import { useParams } from 'react-router-dom';
import { loadCustomer, updateCustomer } from '../../../services/BackendService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showErrorToast, showSuccessToast } from '../../../actions';
import { useAppDispatch } from '../../../helpers/customHooks';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import ToggleSelect, { ISelectOption } from '../../../components/ToggleSelect';
import { UserRole } from '../../../reducers/userReducer';
import { useHistory } from 'react-router-dom';

interface IState {
	fullname: string | undefined;
	email: string | undefined;
	phone: string | undefined;
	country: string | undefined;
}

const CustomerEditPage = () => {
	const [inputValue, setInputValue] = useState<string>('');
	const [selectedRoles, setSelectedRoles] = useState<Array<ISelectOption>>([]);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [formState, setFormState] = useState<IState>({
		fullname: undefined,
		email: undefined,
		phone: undefined,
		country: undefined,
	});

	const options = useMemo(() => Object.keys(UserRole), []);

	const { id } = useParams() as any;
	const dispatch = useAppDispatch();
	const history = useHistory();

	const showError = (errorMessage: string) => {
		const error = extractErrorMessage(errorMessage);
		dispatch(showErrorToast(error));
	};

	const queryClient = useQueryClient();
	const loadCustomerQuery = useQuery(['customer', id], () => loadCustomer(id), {
		onError: (e: any) => {
			showError(e);
		},
	});

	const roles = selectedRoles.filter((r) => r.enabled).map((r) => r.value.toUpperCase());
	const editDTO = {
		...formState,
		roles,
	};

	const updateCustomerMutation = useMutation(
		['updated-user'],
		(customerId: string) => updateCustomer(customerId, editDTO),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t.translate('customer.customer-successful-update')));
				queryClient.invalidateQueries(['customer', id]);
				history.push(`/customers/${id}`);
			},
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	useEffect(() => {
		if (loadCustomerQuery.isSuccess && loadCustomerQuery.data) {
			const { name, email, mobilenumber, country, roles } = loadCustomerQuery.data;
			setFormState({
				fullname: name,
				email,
				phone: mobilenumber,
				country: country,
			});
			const foundRoles = options.map((o) => {
				return {
					value: o,
					label: o,
					enabled: roles.includes(o.toUpperCase() as UserRole),
				};
			});

			setSelectedRoles(foundRoles);
			setSelectAll(options.length === roles.length);
		}
	}, [loadCustomerQuery.isSuccess, loadCustomerQuery.data, options]);

	const tryUpdateCustomer = (e: React.FormEvent) => {
		e.preventDefault();
		updateCustomerMutation.mutate(id);
	};

	const handleInputChange = (e: React.FormEvent<any>) => {
		const target = e.target as HTMLInputElement | HTMLSelectElement;
		const name: string | null = target.getAttribute('name');
		if (name) {
			setFormState({ ...formState, [name]: target.value });
		}
	};

	const onOptionsChanged = (options: Array<ISelectOption>) => {
		setSelectedRoles(options);
		const disabledIndex = options.findIndex((o) => !o.enabled);
		setSelectAll(disabledIndex === -1);
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(updateCustomerMutation.error, paramName);
	};

	if (loadCustomerQuery.isLoading || loadCustomerQuery.isIdle) {
		return <Loading />;
	}

	if (loadCustomerQuery.isError) {
		return <Error onRetry={loadCustomerQuery.refetch} />;
	}

	const { name, mobilenumber } = loadCustomerQuery.data;

	return (
		<PageLayout title={name || mobilenumber}>
			<CRow>
				<CCol>
					<CForm onSubmit={tryUpdateCustomer}>
						<CCard style={{ overflow: 'visible' }}>
							<CCardHeader>
								<CCardTitle>{_t('edit-customer.title')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<CFormGroup>
									<CLabel htmlFor="fullname">{_t('edit-customer.edit-name')}</CLabel>
									<CInput
										type="text"
										id="fullname"
										name="fullname"
										autoComplete="name"
										value={formState?.fullname || ''}
										onChange={handleInputChange}
									/>
									{findError('fullname') && <CLabel className="text-danger">{findError('fullname')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="email">{_t('edit-customer.edit-email')}</CLabel>
									<CInput
										type="text"
										id="email"
										name="email"
										autoComplete="email"
										value={formState?.email || ''}
										onChange={handleInputChange}
									/>
									{findError('email') && <CLabel className="text-danger">{findError('email')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="mobilenumber">{_t('edit-customer.edit-mobilenumber')}</CLabel>
									<CInput
										type="text"
										id="mobilenumber"
										name="phone"
										autoComplete="tel"
										value={formState?.phone || ''}
										onChange={handleInputChange}
									/>
									{findError('phone') && <CLabel className="text-danger">{findError('phone')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="country">{_t('edit-customer.edit-country')}</CLabel>
									<CSelect name="country" value={formState?.country || ''} onChange={handleInputChange}>
										{countries.map((option) => {
											return (
												<option key={option.name} value={option['alpha-2']}>
													{option.name}
												</option>
											);
										})}
									</CSelect>
									{findError('country') && <CLabel className="text-danger">{findError('country')}</CLabel>}
								</CFormGroup>
								<CFormGroup>
									<CLabel htmlFor="roles">{_t('customer.roles')}</CLabel>
									<ToggleSelect
										options={selectedRoles}
										onOptionsChanged={onOptionsChanged}
										inputValue={inputValue}
										onInputValueChanged={setInputValue}
										selectAll={selectAll}
										onSelectChanged={setSelectAll}
									/>
									{findError('roles') && <CLabel className="text-danger">{findError('roles')}</CLabel>}
								</CFormGroup>
							</CCardBody>
							<CCardFooter>
								<ButtonWithLoader
									isLoading={updateCustomerMutation.isLoading}
									type="submit"
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t.translate('global.update')}
									className="mr-2"
								/>
							</CCardFooter>
						</CCard>
					</CForm>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(CustomerEditPage);
