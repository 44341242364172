import React, { useState } from 'react';
import _t from 'counterpart';
import { CFormGroup, CCard, CCardHeader, CCardBody, CCardTitle, CCol, CRow } from '@coreui/react';
import CustomSelect from '../../components/CustomSelect';
import InputHeaderNoButton from '../../components/InputHeaderNoButton';
import CoperatoIntegration from './CoperatoIntegration';
import VoisoIntegration from './VoisoIntegration';
import { showSuccessToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';
import QueryDataRenderer from '../../components/QueryDataRenderer';
import { useGetSetting, useSetSetting } from './hooks';
import ButtonWithLoader from '../../components/ButtonWithLoader';

const VoipIntegration = () => {
	const providersOptions = [
		{ label: 'Disabled', value: '' },
		{ label: 'Coperato', value: 'coperato' },
		{ label: 'Voiso', value: 'voiso' },
	];

	const [provider, setProvider] = useState<{ label: string; value: string }>(providersOptions[0]);

	const setSettingsMutation = useSetSetting();
	const dispatch = useAppDispatch();

	const voipProviderQuery = useGetSetting('VOIP_PROVIDER', 'voip-provider', {
		onSuccess: (provider: string) => {
			if (provider === '') {
				setProvider(providersOptions[0]);
			}
			if (provider === 'coperato') {
				setProvider(providersOptions[1]);
			}
			if (provider === 'voiso') {
				setProvider(providersOptions[2]);
			}
		},
		refetchOnWindowFocus: false,
	});

	const handleProviderChanged = (provider: any) => {
		setProvider(provider);
	};

	const isLoading = voipProviderQuery.isFetching;
	const isError = Boolean(voipProviderQuery.error) && !isLoading;

	const disableVoipMutationCall = () => {
		setSettingsMutation.mutate(
			{ key: 'VOIP_PROVIDER', value: '' },
			{
				onSuccess: () => {
					dispatch(showSuccessToast(_t('integrations.voip-disabled')));
				},
			}
		);
	};

	return (
		<CCard>
			<CCardHeader>
				<CCardTitle>{_t('integrations.voip-provider')}</CCardTitle>
			</CCardHeader>
			<CCardBody>
				<QueryDataRenderer isError={isError} isLoading={isLoading} onRefetch={voipProviderQuery.refetch}>
					<CFormGroup>
						<InputHeaderNoButton labelText={_t('providers.label')} />
						<CustomSelect
							value={provider}
							onChange={handleProviderChanged}
							options={providersOptions}
							menuPortalTarget={document.body}
						/>
					</CFormGroup>
					{provider.value === 'coperato' && <CoperatoIntegration />}
					{provider.value === 'voiso' && <VoisoIntegration />}
					{provider.value === '' && (
						<CRow>
							<CCol md={12} className="d-flex justify-content-end">
								<ButtonWithLoader
									isLoading={setSettingsMutation.isLoading}
									onClick={disableVoipMutationCall}
									buttonColor="primary"
									spinnerColor="secondary"
									title={_t('action.save')}
									className="btn-md"
									showText={false}
								/>
							</CCol>
						</CRow>
					)}
				</QueryDataRenderer>
			</CCardBody>
		</CCard>
	);
};

export default VoipIntegration;
