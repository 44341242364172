import React from 'react';
import { CLink } from '@coreui/react';
import _t from 'counterpart';

interface IProps {
	totalReferrals: number;
	customerId: string;
}

const ReferralsLink = ({ totalReferrals, customerId }: IProps) => {
	return (
		<>
			{totalReferrals}
			&nbsp;
			{totalReferrals > 0 && (
				<CLink href={`/customers?referralForId=${customerId}`}>{_t.translate('action.show')}</CLink>
			)}
		</>
	);
};

export default ReferralsLink;
