import React, { FormEvent, useEffect, useState } from 'react';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../actions';
import PageLayout from '../../components/PageLayout';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useAppDispatch } from '../../helpers/customHooks';
import { loadCountries, loadPaymentProvider, updatePaymentProviderCountries } from '../../services/BackendService';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import {
	CCard,
	CCardBody,
	CCardFooter,
	CCardHeader,
	CCardTitle,
	CCol,
	CFormGroup,
	CInput,
	CInputGroup,
	CLabel,
	CRow,
} from '@coreui/react';
import ToggleSelect, { ISelectOption } from '../../components/ToggleSelect';
import { IConfigKeyValue } from './types';
import ButtonWithLoader from '../../components/ButtonWithLoader';

interface IState {
	minimumDepositAmount: number | null;
	maximumDepositAmount: number | null;
	minimumWithdrawalAmount: number | null;
	maximumWithdrawalAmount: number | null;
	predefinedDepositAmounts: string | null;
}

interface IUpdateProviderParams {
	id: string;
	countryCodes: Array<string>;
	stateToDTO: any;
	keyValues: Array<{ key: string; value: string }>;
}

const PaymentProviderPage = () => {
	const [configKeyValues, setConfigKeyValues] = useState<Array<IConfigKeyValue>>([]);
	const [inputValue, setInputValue] = useState<string>('');
	const [usedCountries, setUsedCountries] = useState<Array<ISelectOption>>([]);
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [state, setState] = useState<IState>({
		minimumDepositAmount: null,
		maximumDepositAmount: null,
		minimumWithdrawalAmount: null,
		maximumWithdrawalAmount: null,
		predefinedDepositAmounts: null,
	});

	const params = useParams();
	const { id } = params as any;

	const dispatch = useAppDispatch();

	const countriesQuery = useQuery<Array<ISelectOption>, Error>(
		['psp-countries'],
		async () => {
			const data = await loadCountries();
			return data.map((country) => ({
				value: country.isoCode,
				label: country.name,
				enabled: false,
			}));
		},
		{ refetchOnWindowFocus: false }
	);

	const providerConfigQuery = useQuery(['payment-provider-edit', id], () => loadPaymentProvider(id), {
		onError: (e) => {
			const msg = extractErrorMessage(e);
			dispatch(showErrorToast(msg));
		},
		enabled: !countriesQuery.isLoading,
	});

	const paymentProviderMutation = useMutation(
		({ id, countryCodes, stateToDTO, keyValues }: IUpdateProviderParams) =>
			updatePaymentProviderCountries(id, countryCodes, stateToDTO, keyValues),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t.translate('payment-providers.payment-provider-updated')));
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const msg = extractErrorMessage(e);
					dispatch(showErrorToast(msg));
				}
			},
		}
	);

	useEffect(() => {
		if (providerConfigQuery.isSuccess && providerConfigQuery.data) {
			const { data } = providerConfigQuery;
			setState({
				minimumDepositAmount: data.minimumDepositAmount,
				maximumDepositAmount: data.maximumDepositAmount,
				minimumWithdrawalAmount: data.maximumWithdrawalAmount,
				maximumWithdrawalAmount: data.maximumWithdrawalAmount,
				predefinedDepositAmounts: data.predefinedDepositAmounts.join(', '),
			});

			const { configurationKeyValues, configKeys } = data;
			const allKeys = Object.keys(configKeys);
			const mappedKeyValues = allKeys.map((key: string) => {
				const value = configurationKeyValues[key];
				return {
					key,
					value,
				};
			});

			setConfigKeyValues(mappedKeyValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [providerConfigQuery.data]);

	useEffect(() => {
		if (countriesQuery.isSuccess && countriesQuery.data && providerConfigQuery.isSuccess && providerConfigQuery.data) {
			const { enabledCountryCodes } = providerConfigQuery.data;
			const mappedValues = countriesQuery.data.map((c) => {
				c.enabled = Boolean(enabledCountryCodes.find((v: any) => v === c.value));
				return c;
			});

			setSelectAll(enabledCountryCodes.length === countriesQuery.data.length);
			setUsedCountries(mappedValues);
		}
	}, [countriesQuery.isSuccess, countriesQuery.data, providerConfigQuery.isSuccess, providerConfigQuery.data]);

	const setFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const name: string = target.getAttribute('name')!;
		const value = target.value!;

		setState({ ...state, [name]: value });
	};

	const updateValue = (key: string, e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;
		const newArray = [...configKeyValues];
		const keyValue = newArray.find((c) => c.key === key);
		keyValue!.value = value;
		setConfigKeyValues(newArray);
	};

	const submitForm = () => {
		const countryCodes = usedCountries.filter((c) => c.enabled).map((c) => c.value);
		const stateToDTO = {
			minimumDepositAmount: state.minimumDepositAmount || null,
			maximumDepositAmount: state.maximumDepositAmount || null,
			minimumWithdrawalAmount: state.minimumWithdrawalAmount || null,
			maximumWithdrawalAmount: state.maximumWithdrawalAmount || null,
			predefinedDepositAmounts:
				state.predefinedDepositAmounts && state.predefinedDepositAmounts.trim().length > 0
					? state.predefinedDepositAmounts.split(',').map((amount) => Number(amount.trim()))
					: undefined,
		};
		const keyValues = configKeyValues.map((c) => {
			return {
				key: c.key,
				value: c.value,
			};
		});

		paymentProviderMutation.mutate({ id, countryCodes, stateToDTO, keyValues });
	};

	const onOptionsChanged = (options: Array<ISelectOption>) => {
		setUsedCountries(options);
		const disabledIndex = options.findIndex((o) => !o.enabled);
		setSelectAll(disabledIndex === -1);
	};

	const findError = (paramName: string) => {
		return findErrorFromValidation(paymentProviderMutation.error, paramName);
	};

	const { isError, isLoading, isIdle } = providerConfigQuery;

	if (isError) {
		return <Error onRetry={providerConfigQuery.refetch} />;
	}

	if (isLoading || isIdle) {
		return <Loading />;
	}

	return (
		<PageLayout title={_t('payment-providers.edit-payment-provider')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader>
							<CCardTitle>{id}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CFormGroup>
								<CLabel htmlFor="allowedCountries">{_t('payment-providers.add-countries')}</CLabel>
								<ToggleSelect
									options={usedCountries}
									onOptionsChanged={onOptionsChanged}
									inputValue={inputValue}
									onInputValueChanged={setInputValue}
									selectAll={selectAll}
									onSelectChanged={setSelectAll}
								/>
							</CFormGroup>
							<div className="d-flex w-100">
								<CFormGroup className="w-50 pr-1">
									<CLabel htmlFor="minimumDepositAmount">{_t('payment-providers.minimum-deposit')}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id="minimumDepositAmount"
											name="minimumDepositAmount"
											placeholder="10"
											value={state.minimumDepositAmount || ''}
											onChange={setFormValue}
										/>
									</CInputGroup>
									{findError('minimumDepositAmount') && (
										<CLabel className="text-danger">{findError('minimumDepositAmount')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup className="w-50 pl-1">
									<CLabel htmlFor="maximumDepositAmount">{_t('payment-providers.maximum-deposit')}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id="maximumDepositAmount"
											name="maximumDepositAmount"
											placeholder="10000"
											value={state.maximumDepositAmount || ''}
											onChange={setFormValue}
										/>
									</CInputGroup>
									{findError('maximumDepositAmount') && (
										<CLabel className="text-danger">{findError('maximumDepositAmount')}</CLabel>
									)}
								</CFormGroup>
							</div>
							<div className="d-flex w-100">
								<CFormGroup className="w-50 pr-1">
									<CLabel htmlFor="minimumWithdrawal">{_t('payment-providers.minimum-withdrawal')}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id="minimumWithdrawalAmount"
											name="minimumWithdrawalAmount"
											placeholder="10"
											value={state.minimumWithdrawalAmount || ''}
											onChange={setFormValue}
										/>
									</CInputGroup>
									{findError('minimumWithdrawalAmount') && (
										<CLabel className="text-danger">{findError('minimumWithdrawalAmount')}</CLabel>
									)}
								</CFormGroup>
								<CFormGroup className="w-50 pr-1">
									<CLabel htmlFor="maximumWithdrawalAmount">{_t('payment-providers.maximum-withdrawal')}</CLabel>
									<CInputGroup>
										<CInput
											type="number"
											id="maximumWithdrawalAmount"
											name="maximumWithdrawalAmount"
											placeholder="10"
											value={state.maximumWithdrawalAmount || ''}
											onChange={setFormValue}
										/>
									</CInputGroup>
									{findError('maximumWithdrawalAmount') && (
										<CLabel className="text-danger">{findError('maximumWithdrawalAmount')}</CLabel>
									)}
								</CFormGroup>
							</div>
							<CFormGroup>
								<CLabel htmlFor="predefinedDepositAmounts">{_t('payment-providers.predefined-deposit-amounts')}</CLabel>
								<CInputGroup>
									<CInput
										type="text"
										id="predefinedDepositAmounts"
										name="predefinedDepositAmounts"
										value={state.predefinedDepositAmounts || ''}
										onChange={setFormValue}
									/>
								</CInputGroup>
								{findError('predefinedDepositAmounts') && (
									<CLabel className="text-danger">{findError('predefinedDepositAmounts')}</CLabel>
								)}
							</CFormGroup>
							{configKeyValues.map(({ key, value }: IConfigKeyValue) => {
								return (
									<div className="d-flex w-100 mt-2" key={`${key}-${id}`}>
										<div className="w-100 pr-1">
											<CLabel htmlFor="key">{_t('payment-providers.configuration-key')}</CLabel>
											<CInput value={key} disabled name="key" />
										</div>
										<div className="w-100 pl-1">
											<CLabel htmlFor="value">{_t('payment-providers.configuration-value')}</CLabel>
											<CInput value={value} onChange={(e) => updateValue(key, e)} name="value" />
										</div>
									</div>
								);
							})}
						</CCardBody>
						<CCardFooter>
							<ButtonWithLoader
								isLoading={paymentProviderMutation.isLoading}
								onClick={submitForm}
								buttonColor="primary"
								spinnerColor="secondary"
								title={_t.translate('action.update')}
								className="mr-2"
							/>
						</CCardFooter>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(PaymentProviderPage);
