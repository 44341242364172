import { CButton, CForm, CFormGroup, CInput, CLabel, CModal, CModalBody, CModalFooter } from '@coreui/react';
import _t from 'counterpart';
import React, { ChangeEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateConversion, createConversion } from '../../services/BackendService';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IConversion, IConversionCreate, IConversionPath } from './types';
import { FormGroupPathInput } from './FormGroupPathInput';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast, showSuccessToast } from '../../actions';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';

interface IProps {
	conversion: IConversion | null;
	hideModal: () => void;
}

interface IFormState {
	target: string;
	source: string;
	path: Array<IConversionPath>;
}

export function AddEditConversionModal({ conversion, hideModal }: IProps) {
	const [formState, setFormState] = useState<IFormState>({
		target: conversion?.target || '',
		source: conversion?.source || '',
		path: conversion?.path || [],
	});

	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();

	const createConversionMutation = useMutation((conversion: IConversionCreate) => createConversion(conversion), {
		onSuccess: async () => {
			dispatch(showSuccessToast(_t('currencyConversions.conversion-added')));
			await queryClient.invalidateQueries('conversions');
			hideModal();
		},
		onError: (e: any) => {
			if (e.response?.status !== 422) {
				const error = extractErrorMessage(e);
				dispatch(showErrorToast(error));
			}
		},
	});

	const updateConversionMutation = useMutation(
		({ id, path }: { id: number; path: Array<IConversionPath> }) => updateConversion(id, path),
		{
			onSuccess: async () => {
				dispatch(showSuccessToast(_t('currencyConversions.conversion-updated')));
				await queryClient.invalidateQueries('conversions');
				hideModal();
			},
			onError: (e: any) => {
				if (e.response?.status !== 422) {
					const error = extractErrorMessage(e);
					dispatch(showErrorToast(error));
				}
			},
		}
	);

	const isEditing = Boolean(conversion);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target;
		const name: string | null = target.getAttribute('name');
		const value = target.value;
		if (name) {
			setFormState({
				...formState,
				[name]: value,
			});
		}
	};

	const submitForm = (e: React.FormEvent) => {
		e.preventDefault();
		const { target, source, path } = formState;

		if (!isEditing) {
			createConversionMutation.mutate({
				target,
				source,
				path,
			});
		} else {
			if (conversion) {
				updateConversionMutation.mutate({
					id: conversion.id,
					path,
				});
			}
		}
	};

	const submitButtonLabel = isEditing ? _t('action.update') : _t('action.create');

	const loading = createConversionMutation.isLoading || updateConversionMutation.isLoading;

	const findError = (paramName: string) => {
		const errors = createConversionMutation.error || updateConversionMutation.error;

		return findErrorFromValidation(errors, paramName);
	};

	const removePath = (index: number) => {
		const { path } = formState;
		const pathsArray = [...path];
		pathsArray.splice(index, 1);
		setFormState({ ...formState, path: pathsArray });
	};

	const onPathValueChange = (index: number, value: IConversionPath) => {
		const { path } = formState;
		const pathsArray = [...path];
		pathsArray[index] = value;
		setFormState({ ...formState, path: pathsArray });
	};
	const addPath = () => {
		setFormState({
			...formState,
			path: [...formState.path, { symbol: '', reverse: false }],
		});
	};

	return (
		<CModal show onClose={hideModal}>
			<CForm onSubmit={submitForm}>
				<CModalBody>
					<CFormGroup>
						<CLabel htmlFor="source">{_t('currencyConversions.source')}</CLabel>
						<CInput
							type="text"
							id="source"
							name="source"
							value={formState.source}
							onChange={handleInputChange}
							placeholder="EUR"
							disabled={conversion ? true : false}
							required
						/>
						{findError('source') && <CLabel className="text-danger">{findError('source')}</CLabel>}
					</CFormGroup>
					<CFormGroup>
						<CLabel htmlFor="target">{_t('currencyConversions.target')}</CLabel>
						<CInput
							type="text"
							id="target"
							name="target"
							value={formState.target}
							onChange={handleInputChange}
							placeholder="USD"
							disabled={isEditing}
							required
						/>
						{findError('target') && <CLabel className="text-danger">{findError('target')}</CLabel>}
					</CFormGroup>
					{formState.path.map((path, index) => {
						return (
							<FormGroupPathInput
								key={`fgp-${index}`}
								index={index}
								onRemovePath={removePath}
								onChange={onPathValueChange}
								path={formState.path[index]}
							/>
						);
					})}
					<CButton onClick={addPath} className="btn-success mt-2" color="success">
						{_t('currencyConversions.add-step')}
					</CButton>
					{findError('path') && <CLabel className="text-danger">{findError('path')}</CLabel>}
				</CModalBody>
				<CModalFooter>
					<ButtonWithLoader
						isLoading={loading}
						type="submit"
						buttonColor="primary"
						spinnerColor="secondary"
						title={submitButtonLabel}
						className="mr-2"
					/>
					<CButton disabled={loading} color="light" variant="outline" onClick={hideModal}>
						{_t('action.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
}
