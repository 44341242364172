import React, { useCallback, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import _t from 'counterpart';
import { useQuery, useQueryClient } from 'react-query';
import { fetchCoinsConversion } from '../../services/BackendService';
import { CBadge, CButton, CCard, CCardBody, CCardHeader, CCol, CLink, CRow } from '@coreui/react';
import PaginationTable from '../../components/PaginationTable';
import { ITransactionsWithExchangedCoins } from './types';
import { formatCurrency, getFiltersCount } from '../../helpers';
import Error from '../../components/Error';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import SearchFilter from '../../components/SearchFilter';
import ExportXLSXButton from '../../components/ExportXLSXButton';
import CoinsConversionsFilters from './CoinsConversionsFilters';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { clearCoinsConversionsFilters } from '../../actions';
import { stringify } from 'qs';

const CoinsConversionsPage = () => {
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const coinsConversionsItemsPerPageFromLocalStorage = Number(
		localStorage.getItem('items_per_page_coins_conversions_table') ?? 10
	);
	const [limit, setLimit] = useState<number>(coinsConversionsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	const coinsFilters = useAppSelector((state) => state.filters.coinsConversionsFilters);
	const token = useAppSelector((state: any) => state.user.token);

	const onPageChanged = useCallback(
		(page: number) => {
			setPage(page, 'replaceIn');
		},
		[setPage]
	);

	const setTableFilterValue = (search: string) => {
		setPage(1, 'replaceIn');
		setFilter(search, 'replaceIn');
	};

	const onFiltersChange = () => {
		setPage(1, 'replaceIn');
	};

	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const clearFilters = () => {
		dispatch(clearCoinsConversionsFilters());
	};

	const createdAt = coinsFilters.createdAt || null;
	const coinsConversionQuery = useQuery(['coins-conversions', offset, filter, coinsFilters], () =>
		fetchCoinsConversion(limit, offset, filter!, createdAt)
	);

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_coins_conversions_table', limit.toString());
		await queryClient.invalidateQueries('coins_conversions');
		coinsConversionQuery.refetch();
	};

	const tableFields = useMemo(
		() => [
			{
				key: 'customerName',
				label: _t('sidebar.conversions'),
				sorter: false,
			},
			{
				key: 'wallet',
				label: _t('coins.conversions.wallet'),
				sorter: false,
			},
			{
				key: 'createdAt',
				label: _t('global.created-at'),
				sorter: false,
			},
			{
				key: 'addToCredit',
				label: _t('coins.add-to-credit'),
				sorter: false,
			},
			{
				key: 'amount',
				label: _t('global.amount'),
				sorter: false,
			},
			{
				key: 'coinsExchanged',
				label: _t('coins.coins-exchanged'),
				sorter: false,
			},
		],
		[]
	);

	const scopedSlots = useMemo(() => {
		return {
			customerName: ({ customerId, customerName }: ITransactionsWithExchangedCoins) => (
				<td>
					<CLink href={`../customers/${customerId}`}>{customerName}</CLink>
				</td>
			),
			wallet: ({ wallet, walletId }: ITransactionsWithExchangedCoins) => (
				<td>
					<CLink href={`../wallets/${walletId}`}>{wallet}</CLink>
				</td>
			),
			createdAt: ({ createdAt }: ITransactionsWithExchangedCoins) => <td>{new Date(createdAt).toLocaleString()}</td>,
			addToCredit: ({ addToCredit }: ITransactionsWithExchangedCoins) => (
				<td>
					<CBadge color={addToCredit ? 'success' : 'danger'}>
						{addToCredit ? _t.translate('global.yes') : _t.translate('global.no')}
					</CBadge>
				</td>
			),
			amount: ({ amount }: ITransactionsWithExchangedCoins) => <td>{formatCurrency(amount)}</td>,
		};
	}, []);

	const count = coinsConversionQuery.data?.count || 0;
	const pages = count === 0 ? 0 : Math.ceil(count / Number(limit));

	if (coinsConversionQuery.isError) {
		return <Error onRetry={coinsConversionQuery.refetch} />;
	}

	let recordCount = 0;

	if (coinsConversionQuery.data) {
		const { count } = coinsConversionQuery.data;
		recordCount = count;
	}

	const downloadLink = `${process.env.REACT_APP_API_ROOT}/api/admin/transactions/export/coins_exchange?${stringify({
		filters: {
			createdAtRange: coinsFilters.createdAt,
			wallet: filter,
		},
		token,
	})}`;

	const filtersCount = getFiltersCount(coinsFilters);

	return (
		<PageLayout title={_t('sidebar.conversions')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={setTableFilterValue} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={clearFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
										<div className="float-right">
											<ExportXLSXButton
												downloadLink={downloadLink}
												disabled={coinsConversionQuery.isLoading || recordCount === 0}
												defaultFilename="coins-conversions.xlsx"
											/>
										</div>
									</div>
								</div>
								<CoinsConversionsFilters show={showFilters} onFiltersChange={onFiltersChange} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={coinsConversionQuery.data?.conversions ?? []}
								loading={coinsConversionQuery.isLoading}
								pages={pages}
								pagination
								onPageChanged={onPageChanged}
								activePage={page || 1}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(CoinsConversionsPage);
