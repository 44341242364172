import React, { FormEvent, useState } from 'react';
import { CRow, CCol, CInput, CLabel, CFormGroup, CInputGroup } from '@coreui/react';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import _t from 'counterpart';
import { showErrorToast, showSuccessToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';
import { useGetSetting } from './hooks';
import { extractErrorMessage, findErrorFromValidation } from '../../helpers';
import { useMutation } from 'react-query';
import { updateMailgun } from '../../services/BackendService';

interface IParams {
	sender: string;
	apiKey: string;
	domain: string;
	username: string;
}

const MailgunIntegration = () => {
	const [apiKey, setApiKey] = useState<string>('');
	const [username, setUsername] = useState<string>('');
	const [domain, setDomain] = useState<string>('');
	const [emailSender, setEmailSender] = useState<string>('');
	const dispatch = useAppDispatch();

	const apiKeyQuery = useGetSetting('MAILGUN_API_KEY', 'mailgun-api-key', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setApiKey(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const emailSenderQuery = useGetSetting('EMAIL_TRANSACTIONAL_SENDER', 'email-sender', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setEmailSender(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const domainQuery = useGetSetting('MAILGUN_DOMAIN', 'mailgun-domain', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setDomain(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const usernameQuery = useGetSetting('MAILGUN_USERNAME', 'mailgun-username', {
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (value: string) => {
			setUsername(value);
		},
		onError: (e: any) => {
			dispatch(showErrorToast(extractErrorMessage(e)));
		},
	});

	const updateMailgunMutation = useMutation(
		({ sender, apiKey, domain, username }: IParams) => updateMailgun(sender, apiKey, username, domain),
		{
			onError: (error: any) => {
				if (error.response?.status !== 422) {
					const msg = extractErrorMessage(error);
					dispatch(showErrorToast(msg));
				}
			},
			onSuccess: () => {
				dispatch(showSuccessToast(_t('integrations.mailgun-configured')));
				apiKeyQuery.refetch();
				emailSenderQuery.refetch();
				domainQuery.refetch();
				usernameQuery.refetch();
			},
		}
	);

	const setApiKeyFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setApiKey(value);
	};

	const setUsernameFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setUsername(value);
	};

	const setDomainFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setDomain(value);
	};

	const setSenderFormValue = (e: FormEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value!;

		setEmailSender(value);
	};

	const findError = (paramName: string) => {
		const error = findErrorFromValidation(updateMailgunMutation.error, paramName);
		return error;
	};

	const callUpdateMailgunMutation = () => {
		updateMailgunMutation.mutate({ apiKey, sender: emailSender, domain, username });
	};

	return (
		<CRow>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="apiKey">{_t(`integrations.mailgun-api-key`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="apiKey"
							name="apiKey"
							value={apiKey}
							onChange={setApiKeyFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{apiKeyQuery.error && <CLabel className="text-danger">{_t('integrations.mailgun-api-key-failed')}</CLabel>}
					{findError('apiKey') && <CLabel className="text-danger">{findError('apiKey')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="username">{_t(`integrations.mailgun-username`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="username"
							name="username"
							value={username}
							onChange={setUsernameFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{usernameQuery.error && <CLabel className="text-danger">{_t('integrations.mailgun-username-failed')}</CLabel>}
					{findError('username') && <CLabel className="text-danger">{findError('username')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="domain">{_t(`integrations.mailgun-domain`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="domain"
							name="domain"
							value={domain}
							onChange={setDomainFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{domainQuery.error && <CLabel className="text-danger">{_t('integrations.mailgun-domain-failed')}</CLabel>}
					{findError('domain') && <CLabel className="text-danger">{findError('domain')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12}>
				<CFormGroup>
					<CLabel htmlFor="sender">{_t(`integrations.email-sender`)}</CLabel>
					<CInputGroup>
						<CInput
							type="text"
							id="sender"
							name="sender"
							value={emailSender}
							onChange={setSenderFormValue}
							className="instrument-input-height"
						/>
					</CInputGroup>
					{emailSenderQuery.error && <CLabel className="text-danger">{_t('integrations.email-sender-failed')}</CLabel>}
					{findError('sender') && <CLabel className="text-danger">{findError('sender')}</CLabel>}
				</CFormGroup>
			</CCol>
			<CCol md={12} className="d-flex justify-content-end">
				<ButtonWithLoader
					isLoading={updateMailgunMutation.isLoading}
					onClick={callUpdateMailgunMutation}
					buttonColor="primary"
					spinnerColor="secondary"
					title={_t('action.save')}
					className="btn-md"
					showText={false}
					disabled={apiKey === '' || emailSender === '' || username === '' || domain === ''}
				/>
			</CCol>
		</CRow>
	);
};

export default MailgunIntegration;
