import React, { useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../../helpers/customHooks';
import PageLayout from '../../../components/PageLayout';
import { useMutation, useQuery } from 'react-query';
import { showErrorToast, showSuccessToast } from '../../../actions';
import { extractErrorMessage, formatDateTime } from '../../../helpers';
import { useParams } from 'react-router-dom';
import { getCustomerOnboardingSteps, updateCustomerStep } from '../../../services/BackendService';
import { CRow, CCol, CCard, CCardBody, CDataTable, CBadge } from '@coreui/react';
import { ICustomerOnboardingStep } from '../types';
import _t from 'counterpart';

interface IMutationParams {
	customerId: string;
	step: string;
}

const OnboardingStepsPage = () => {
	const dispatch = useAppDispatch();
	const params = useParams();

	const { id } = params as any;
	const onboardingStepsQuery = useQuery(
		['customer-onboarding-steps', id],
		() => {
			return getCustomerOnboardingSteps(id);
		},
		{
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const updateStepMutation = useMutation(
		({ customerId, step }: IMutationParams) => updateCustomerStep(customerId, step),
		{
			onSuccess: () => {
				onboardingStepsQuery.refetch();
				dispatch(showSuccessToast(_t('onboarding.onboarding-step-updated')));
			},
			onError: (error: any) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const invokeMutation = useCallback(
		(step: string) => {
			updateStepMutation.mutate({ customerId: id, step });
		},
		[updateStepMutation, id]
	);

	const fields = useMemo(
		() => [
			{ key: 'step', label: _t('onboarding.step') },
			{ key: 'stage', label: _t('onboarding.stage') },
			{ key: 'completionDate', label: _t('global.created-at') },
			{ key: 'actions', label: _t('onboarding.complete-step') },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			completionDate: ({ completionDate }: ICustomerOnboardingStep) => (
				<td>
					{completionDate ? (
						formatDateTime(completionDate)
					) : (
						<CBadge color="danger">{_t('onboarding.not-completed')}</CBadge>
					)}
				</td>
			),
			actions: ({ step, completionDate }: ICustomerOnboardingStep) => (
				<td className="d-flex justify-content-center">
					<input
						className="form-check-input"
						type="checkbox"
						onChange={() => invokeMutation(step)}
						checked={completionDate !== null}
					/>
				</td>
			),
		}),
		[invokeMutation]
	);

	return (
		<PageLayout title="Onboarding steps">
			<CRow>
				<CCol>
					<CCard>
						<CCardBody>
							<CDataTable
								items={onboardingStepsQuery.data || []}
								fields={fields}
								scopedSlots={scopedSlots}
								loading={onboardingStepsQuery.isLoading || updateStepMutation.isLoading}
								striped
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(OnboardingStepsPage);
